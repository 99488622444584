import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import lineIcon from 'assets/images/dashna/line.svg';

const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[5],
      fontSize: 11,
      maxHeight: '368px',
      minWidth: '586px'
    }
  })
);

export default function BidPopover({ data, hoveredRow, value }) {
  const [minMaxValues, setMinMaxValues] = useState({ min: 0, max: 0 });
  const [tableRowsData, setTableRowsData] = useState([]);

  const createData = (name, total, calculation) => {
    return { name, total, calculation };
  };

  const calculateValue = (placementMultiplier, platformMultiplier, baseValue) => {
    const totalMultiplier = (placementMultiplier + platformMultiplier) / 100;
    const totalValue = baseValue + baseValue * totalMultiplier;
    return totalValue?.toFixed(2);
  };

  const bidMultipler = () => {
    let tableRows = [];
    const baseValue = parseFloat(data?.bid_amount);
    const placements = Object.keys(data).filter((key) => key.includes('placement_bid') && data[key] !== null);
    const platforms = Object.keys(data).filter((key) => key.includes('platform_bid') && data[key] !== null);

    placements.forEach((placement) => {
      const placementName = placement.replace('placement_bid_', '').replace('_', ' ');

      platforms.forEach((platform) => {
        const platformName = platform.replace('platform_bid_', '').replace('_', ' ');
        const value = calculateValue(data[placement], data[platform], baseValue);

        const row = createData(
          `${platformName} & ${placementName}`,
          `$${value}`,
          `$${baseValue} + $${baseValue} * ( ${data[platform]}% + ${data[placement]}%)`
        );
        tableRows.push(row);
      });
    });

    platforms.forEach((platformData) => {
      const pltformName = platformData.replace('platform_bid_', '').replace('_', ' ');

      if (tableRows.findIndex((obj) => obj.name.toLowerCase() === `${pltformName}`.toLowerCase()) === -1) {
        const platformValue = calculateValue(0, data[platformData], baseValue);
        const platformrow = createData(
          `${pltformName}`,
          `$${platformValue}`,
          `$${baseValue} + $${baseValue} * ( ${data[platformData]}%)`
        );
        tableRows.push(platformrow);
      }
    });

    placements.forEach((placementData) => {
      const placeName = placementData.replace('placement_bid_', '').replace('_', ' ');

      if (tableRows.findIndex((obj) => obj.name.toLowerCase() === `${placeName}`.toLowerCase()) === -1) {
        const placementValue = calculateValue(data[placementData], 0, baseValue);
        const placementrow = createData(
          `${placeName}`,
          `$${placementValue}`,
          `$${baseValue} + $${baseValue} * ( ${data[placementData]}%)`
        );
        tableRows.push(placementrow);
      }
    });

    //calculate min and max values to display in the table
    if (tableRows.length > 0) {
      const minMax = tableRows.reduce(
        (acc, row) => {
          const value = parseFloat(row.total.replace('$', ''));

          if (acc.min === null || value < acc.min) {
            acc.min = value;
          }

          if (acc.max === null || value > acc.max) {
            acc.max = value;
          }

          return acc;
        },
        { min: null, max: null }
      );

      setMinMaxValues(minMax);
    }

    const otherRow = createData(`Other`, `$${baseValue}`, ``);
    tableRows.push(otherRow);

    setTableRowsData(tableRows);

    return true;
  };

  useEffect(() => {
    if (data?.campaign_type === 'sponsoredProducts') {
      bidMultipler();
    }
  }, [data]);

  const createTableRowsHTML = () => {
    return (
      <Box className="bid-multplier-popover">
        <Typography ml={2}>Actual Bid after Setting Bid Multipler:</Typography>
        <Box className="pnl-by-item">
          <TableContainer component={Paper} className="bid-multplier-table">
            <Table aria-label="simple table">
              <TableBody>
                {tableRowsData.map((row) => (
                  <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row" className="capitalize-text">
                      {row.name}
                    </TableCell>
                    <TableCell align="left">{row.total}</TableCell>
                    <TableCell align="left">{row.calculation}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {minMaxValues.min > 0 && minMaxValues.max > 0 && data.campaign_type === 'sponsoredProducts' ? (
        <CustomWidthTooltip
          placement="top"
          title={hoveredRow?.campaign === data?.id ? createTableRowsHTML() : 'No Data Found'}
          disabled={hoveredRow?.campaign !== data?.id}
          arrow
          className="Bid-tooltip"
        >
          <Typography className="cursor-pointer" color="primary">
            ${value}
          </Typography>
          <img src={lineIcon} alt="Icon" />
          <Typography className="placeholder-text">{`$${minMaxValues?.min} - $${minMaxValues?.max}`}</Typography>
        </CustomWidthTooltip>
      ) : (
        <Typography className="cursor-pointer" color="primary">
          ${value}
        </Typography>
      )}
    </>
  );
}
