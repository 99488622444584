import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

//====== MUI ======
import MUIDataTable from 'mui-datatables';
import MainCard from 'ui-component/cards/MainCard';
import {
  Autocomplete,
  Button,
  Grid,
  Link,
  IconButton,
  MenuItem,
  Menu,
  TableCell,
  TextField,
  Tooltip,
  Typography,
  TableFooter,
  Chip
} from '@mui/material';
import { Box } from '@mui/system';
import { NestedDropdown } from 'mui-nested-menu';
import { Add } from '@mui/icons-material';
import { TableRow } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

//====== Redux ======
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import {
  getCampaignNameList,
  getSelectedFilterList,
  getRankTrackerDetails,
  getItemKeywordChart,
  deleteItemKeywordRankTracker
} from 'store/thunk/userThunk';
// import { setCommonLoader } from 'store/slices/userDetailsSlice';

//====== Colors ======
import colors from 'assets/scss/_themes-vars.module.scss';

//====== Packages ======
import moment from 'moment';
import { Loader } from 'rsuite';
import __ from 'underscore';
import { v4 as uuidv4 } from 'uuid';

//====== Common components ======
import CustomColumnSelector from 'ui-component/CustomColumnSelector';
import TableSkeleton from 'ui-component/skeleton/TableSkeleton';
import MultilineInputPopover from 'ui-component/popover/multilineInputPopover';
import CustomFilters from 'ui-component/Filters';
import FilterPerformance from 'views/filterPerformance';
import { alignRightColumn, formateValue, getUpdatedColumns } from 'helper/commonHelper';
import { PageValue } from 'store/constant';
import DeleteModalDialog from 'ui-component/DeleteModal';

//====== Icons ======
import MultiSelectIcon from 'assets/images/dashna/multiselect.svg';
import upIcon from 'assets/images/dashna/up.svg';
import downIcon from 'assets/images/dashna/down.svg';
import SearchRankGraph from './models/searchRankGraph';
import MultipleItemAdd from 'ui-component/multipale-item-add';
import UploadItemModal from 'views/upload-item-Modal';
import chartIcon from 'assets/images/dashna/chartIcon.svg';
import groupIcon from 'assets/images/dashna/group.svg';
import viewcolumnsIcon from 'assets/images/dashna/viewcolumns.svg';
// import editIcon from 'assets/images/dashna/edit.svg';
// import viewIcon from 'assets/images/dashna/view.svg';
import deleteIcon from 'assets/images/dashna/delete.svg';
import searchIcon from 'assets/images/dashna/search.svg';
// import uploadIcon from 'assets/images/dashna/upload.svg';
// import downloadIcon from 'assets/images/dashna/download.svg';

//New Colors
const color_1 = '#BED782';
const color_2 = '#E9E587';
const color_3 = '#FDD96D';
const color_4 = '#FFB833';
const color_5 = '#E99178';

//Default Value
const defaultOrganicRank = '201+';
const defaultSponsorRank = '50+';
const NO_SEARCH_VOLUME_RANK_FOUND = 300000; //default value if not found

const RankTracker = () => {
  const dispatch = useAppDispatch();
  const [selectedRowDataForChart, setSelectedRowDataForChart] = useState({});
  const [rowPerPage, setRowPerPage] = useState(25);
  const [showDropdown, SetShowDropDown] = useState(false);
  const [appliedSortTing, setAppliedSortTing] = useState();
  const [page, setPage] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [internalTableLoading, setInternalTableLoading] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [isModalAddItem, setIsModalAddItem] = useState(false);
  const [isModalUploadItem, setIsModalUploadItem] = useState(false);
  const [isModalChart, setIsModalChart] = useState(false);
  const [totalKeywords, setTotalKeywords] = useState('');
  const [usedKeywords, setUsedKeywords] = useState('');
  const [filterValue, setFilterValue] = useState({});
  const [tableFilter, setTableFilter] = useState({
    item__in: '',
    search_term__in: ''
  });
  const [filterForSearch, setFilterForSearch] = useState({});
  const [ErrorShow, setErrorShow] = useState([]);
  const [isDisableSearch, setIsDisableSearch] = useState(false);
  const [groupBy, setGroupBy] = useState({});
  const [viewColumnsSelector, setViewColumnsSelector] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedRankType, setSelectedRankType] = useState({});
  const [selectedRankTypeAfterSearchClicked, setSelectedRankTypeAfterSearchClicked] = useState('');
  const [showSelectAdviser, setShowAdviser] = useState(false);
  const { rankTrackerDetails, filteredData, commonAdvertiser, commonDateRange } = useAppSelector(
    (state) => state?.userDetailsSlice
  );

  //Prevent Un-necessary loading when no profile changed
  const previousAdvertiser = useRef(commonAdvertiser);
  const isFirstRender = useRef(true);

  const { userData } = useAppSelector((state) => state.authorization);
  const params = useParams();
  const [filterDataList, setFilterDataList] = useState({});
  const [performanceRanges, setPerformanceRanges] = useState({});
  const [newData, setNewData] = useState();
  // const [newDataTotal, setNewDataTotal] = useState();
  const [totalSearchRank, setTotalSearchRank] = useState();
  const [totalSearchResults, setTotalSearchResults] = useState();
  const [selectedItemsId, SetSelectedItemsId] = useState([]);
  const [PerformanceFilterData, setPerformanceFilterData] = useState();
  const [
    ,
    // valueGroup
    setValueGroup
  ] = useState('adgroup');
  const [tableHeight, setTableHeight] = useState('550px');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [selectedData, setSelectedData] = useState(null); // State for selected row data when click on three dot
  // const [anchorElUp, setAnchorElUp] = useState(null);
  // const [anchorElDwn, setAnchorElDwn] = useState(null);

  console.log('setTotalSearchRank ', selectedItemsId, PerformanceFilterData);

  //Permission checking
  const items = userData?.permission?.items || {};
  const itemsUpdate = items?.update;

  const rankTracker = userData?.permission?.rank_tracker || {};
  const rankTrackerCreate = rankTracker?.create;
  const rankTrackerDelete = rankTracker?.delete;

  const open = Boolean(anchorEl);
  // const openUp = Boolean(anchorElUp);
  // const openDwn = Boolean(anchorElDwn);

  // const handleClickUp = (event) => {
  //   setAnchorElUp(event.currentTarget);
  // };
  // const handleCloseUp = () => {
  //   setAnchorElUp(null);
  // };

  // const handleClickDwn = (event) => {
  //   setAnchorElDwn(event.currentTarget);
  // };
  // const handleCloseDwn = () => {
  //   setAnchorElDwn(null);
  // };

  //Options for Rank Type Dropdowns
  const allRankTypes = [
    {
      id: 1,
      name: 'Organic',
      value: 'organic_rank'
    },
    {
      id: 2,
      name: 'Sponsored',
      value: 'sponsor_rank'
    },
    {
      id: 3,
      name: 'Both',
      value: 'both'
    }
  ];

  //Click Item Three Dot
  const handleClickThreeDot = (event, rowData) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(rowData);
  };

  // Close Item Three dot
  const handleCloseThreeDot = () => {
    setAnchorEl(null);
  };

  //for resize table based on screen size
  useEffect(() => {
    const handleResize = () => {
      const calculatedHeight = window.innerHeight * 0.8;
      setTableHeight(`${calculatedHeight}px`);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (filteredData && filteredData?.length > 0) {
      if (filteredData[0]?.rank_tracker_column) {
        let tempColumns = [...baseColumns];
        tempColumns = tempColumns.filter((column) => column?.options?.display !== false);

        filteredData[0]?.rank_tracker_column?.map((col) => {
          const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
          if (columnsIndex !== -1) {
            tempColumns[columnsIndex].options.display = col?.display;
          }
        });
        setSelectedColumns(tempColumns);
      }

      setFilterDataList(filteredData[0]?.rank_tracker_filter);
      let TemptableFilter = { ...tableFilter };
      let TempFilterValue = { ...filterValue };

      if (filteredData[0]?.rank_tracker_filter?.Common?.findIndex((item) => item?.value == 'details') === -1) {
        TemptableFilter.item__in = '';
        TempFilterValue.campaign_state = [];
      }
      if (filteredData[0]?.rank_tracker_filter?.Common?.findIndex((item) => item?.value == 'search_term') === -1) {
        TemptableFilter.search_term__in = '';
        TempFilterValue.campaign__in = [];
      }

      if (filteredData[0]?.aditem_filter?.Common?.findIndex((item) => item?.value == 'adgroup') === -1) {
        setGroupBy({});
      }

      setTableFilter(TemptableFilter);
      setFilterValue(TempFilterValue);

      if (Object.keys(performanceRanges).length > 0) {
        Object.keys(performanceRanges).map((appliedFilter) => {
          let tempPerformanceRanges = { ...performanceRanges };
          if (filteredData[0]?.campaign_filter?.Performance.findIndex((obj) => obj.value === appliedFilter) === -1) {
            delete tempPerformanceRanges[appliedFilter];
          }
          setPerformanceRanges(tempPerformanceRanges);
        });
      }
    }
  }, [filteredData]);

  // const formateData = (keyArr) => {
  //   const formattedData = {};
  //   keyArr.forEach(([key, value]) => {
  //     formattedData[key] = formateValue(key, value);
  //   });
  //   return formattedData;
  // };

  useEffect(() => {
    let totalSearchRank = 0;
    let totalSearchResults = 0;
    const finalData = rankTrackerDetails?.data?.results?.map((val) => {
      totalSearchRank += val?.search_volume_rank;
      totalSearchResults += val?.total_search_results;
      return {
        // ...formateData(Object.entries(val)),
        ...val,
        details: {
          images: val?.primary_image_url,
          item_id: val?.item_id,
          product_name: val?.product_name
        }
      };
    });
    setNewData(finalData);

    setTotalSearchRank(totalSearchRank || '');
    setTotalSearchResults(totalSearchResults || '');

    setTotalKeywords(rankTrackerDetails?.total_keywords || '');
    setUsedKeywords(rankTrackerDetails?.keywords_used || '');

    // if (filteredData[0]?.rank_tracker_column) {
    //   let tempColumns = [...baseColumns];
    //   tempColumns = tempColumns.filter((column) => column?.options?.display !== false);

    //   filteredData[0]?.rank_tracker_column?.map((col) => {
    //     const columnsIndex = tempColumns?.findIndex((column) => column?.name === col?.name);
    //     if (columnsIndex !== -1) {
    //       tempColumns[columnsIndex].options.display = col?.display;
    //     }
    //   });
    //   setSelectedColumns(tempColumns);
    // }
  }, [rankTrackerDetails]);

  //Fetch Items List
  const getRankItemListWithPagination = ({
    page,
    rowPerPage,
    changedColumn,
    direction,
    commonAdvertiserList,
    PerformanceFilter,
    tableFilter,
    is_csv
  }) => {
    !is_csv && setTableLoading(true);
    const commonDate = commonDateRange;
    if ((commonDate?.startDate && commonDate?.endDate) || params?.id) {
      if (changedColumn === 'details') {
        changedColumn = 'item_id';
      }

      const rankType = selectedRankType?.value;

      const request = {
        page,
        rowPerPage,
        changedColumn,
        direction,
        tableFilter,
        PerformanceFilter,
        rankType,
        commonAdvertiserList,
        groupBy
      };
      dispatch(getRankTrackerDetails(request)).then(() => {
        setTableLoading(false);
        setTimeout(() => {
          setInternalTableLoading(false);
        }, 2000);
      });
    }
  };

  //Fetch Filters
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSelectedFilterList());
    setSelectedRankType(allRankTypes[0]);
    setSelectedRankTypeAfterSearchClicked(allRankTypes[0]?.value);
  }, []);

  const getRankItemList = () => {
    if (commonAdvertiser.length > 0) {
      setShowAdviser(true);

      const request = {
        page,
        rowPerPage,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        tableFilter: filterForSearch
      };
      getRankItemListWithPagination(request);
    } else {
      setTotalSearchRank('');
      setTotalSearchResults('');
      setShowAdviser(false);
    }
  };

  useEffect(() => {
    if (JSON.stringify(previousAdvertiser.current) !== JSON.stringify(commonAdvertiser) || isFirstRender?.current) {
      //Fetch Advertiser Profiles
      let id = commonAdvertiser;
      dispatch(getCampaignNameList({ id }));

      getRankItemList();
      previousAdvertiser.current = commonAdvertiser;
      isFirstRender.current = false;
    }
  }, [commonAdvertiser]);

  useEffect(() => {
    getRankItemList();
  }, [commonDateRange]);

  //Clicked on Search button
  const handleSearchClick = () => {
    setFilterForSearch({ ...tableFilter });
    setSelectedRankTypeAfterSearchClicked(selectedRankType?.value);
    setInternalTableLoading(true);
    const filledPerformanceRanges = Object.entries(performanceRanges).reduce((acc, [performanceId, values]) => {
      const { min, max } = values;
      if (min?.length > 0 || max?.length > 0) {
        acc[performanceId] = values;
      }
      return acc;
    }, {});

    const comparisonValues = filterDataList?.Common?.filter(
      (item) => item?.value === 'search_volume_rank' || item?.value === 'current_rank'
    ).map((item) => item.value);

    // Filter dataRanges based on comparisonValues
    var PerformanceFilter =
      comparisonValues &&
      Object.fromEntries(Object.entries(filledPerformanceRanges).filter(([key]) => comparisonValues.includes(key)));

    setPerformanceFilterData(PerformanceFilter);
    setValueGroup(groupBy?.value);

    const request = {
      page: 0,
      rowPerPage,
      changedColumn: appliedSortTing?.changedColumnSort,
      direction: appliedSortTing?.directionSort,
      commonAdvertiserList: commonAdvertiser,
      PerformanceFilter: PerformanceFilter ? PerformanceFilter : '',
      tableFilter
    };
    getRankItemListWithPagination(request);

    SetShowDropDown(false);
  };

  const handleColumnSelectorOpen = () => {
    setViewColumnsSelector(true);
  };

  const handleColumnSelectorClose = () => {
    setViewColumnsSelector(false);
  };

  //Clicked on chart Icon
  const handleChart = (rowData, type) => {
    const request = {
      item_id: rowData?.item_id,
      keyword: rowData?.search_term,
      days: 30
    };
    dispatch(getItemKeywordChart(request)).then(() => {
      setSelectedRowDataForChart({ ...rowData, type });
      setIsModalChart(true);
    });
  };

  const menuItemsData = {
    label: 'Bulk Operation',
    items: [
      {
        label: 'Change Status',
        items: []
      }
    ]
  };

  // const optionItems = [
  //   {
  //     id: 1,
  //     name: 'Edit',
  //     value: 'edit'
  //   },
  //   {
  //     id: 2,
  //     name: 'View',
  //     value: 'view'
  //   },
  //   {
  //     id: 3,
  //     name: 'Delete',
  //     value: 'delete'
  //   }
  // ];
  const ITEM_HEIGHT = 48;

  const formatDate = (date) => {
    return {
      dayOfWeek: moment(date).format('ddd'),
      monthDay: moment(date).format('MMM DD'),
      date: moment(date).format('YYYY-MM-DD')
    };
  };

  const generateLast14Days = () => {
    const dates = [];
    for (let i = 0; i < 14; i++) {
      const date = moment().subtract(i, 'days');
      dates.push({ name: formatDate(date) });
    }
    return dates;
  };

  const last14Days = generateLast14Days();

  const toggleDeleteModal = (data) => {
    setOpenDelete(!openDelete);
    setDeleteData(data);
  };

  //Delete Item Keyword
  const confirmDelete = () => {
    const request = {
      rankTrackerID: deleteData
    };
    dispatch(deleteItemKeywordRankTracker(request)).then(() => {
      refreshListOnceUploaded();
      setOpenDelete(!openDelete);
      setAnchorEl(null);
    });
  };

  //Table columns
  const baseColumns = [
    {
      name: 'details',
      label: 'Items',
      default: true,
      options: {
        filter: true,
        sort: true,
        viewColumns: false,
        sticky: true,
        // leftTotal: 40,
        leftTotal: 0,
        FontSize: '22px',
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <TableCell
              sx={{
                // left: '40px',
                left: '0px',
                position: 'sticky',
                top: '0px',
                zIndex: 101,
                whiteSpace: 'nowrap',
                cursor: 'pointer'
              }}
              onClick={() => handleToggleColumn(columnMeta?.index)}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
                <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="flex-start">
                  <Box className="clr3 fw700 ffInter">Items</Box>
                </Box>
                {!__.isEmpty(sortOrder) && (
                  <Box>
                    {sortOrder?.direction === 'asc' && sortOrder?.name === 'details' ? (
                      <img src={upIcon} alt="multiselect icon" />
                    ) : null}
                    {sortOrder?.direction === 'desc' && sortOrder?.name === 'details' ? (
                      <img src={downIcon} alt="multiselect icon" />
                    ) : null}
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        },
        customBodyRender: (value, rowData) => {
          const rowIndex = rowData?.rowIndex;
          const rowInfo = newData[rowIndex];
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {value?.images ? (
                  <Box component={'img'} sx={{ mr: 1, height: '40px', width: '40px' }} src={value?.images} />
                ) : (
                  <InsertPhotoIcon fontSize="large" sx={{ margin: '4px', color: colors.grey500 }} />
                )}
                <Box>
                  <Typography>
                    {value?.product_name?.length > 40 ? (
                      <Tooltip
                        className="clr2 ffInter fw400"
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {value?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {value?.product_name.substring(0, 40)}...
                      </Tooltip>
                    ) : (
                      value?.product_name
                    )}
                  </Typography>

                  {value?.item_id ? (
                    <Link
                      underline="none"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => window.open(`https://www.walmart.com/ip/${value?.item_id}`, '_blank')}
                    >
                      <Typography className="ffInter" sx={{ color: colors.primaryMain }}>
                        {value?.item_id}
                      </Typography>
                    </Link>
                  ) : (
                    <>------</>
                  )}
                </Box>
              </Box>
              <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? 'long-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                  // onClick={handleClickThreeDot}
                  onClick={(e) => {
                    handleClickThreeDot(e, rowInfo);
                    // handleClick(e, data?.id, data);
                  }}
                >
                  <Tooltip sx={{ fontSize: '10px' }} title={'Action'} arrow>
                    {' '}
                    <MoreVertIcon />
                  </Tooltip>
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button'
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleCloseThreeDot}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch'
                    }
                  }}
                >
                  {rankTrackerDelete && (
                    <MenuItem key={uuidv4()} selected={''} onClick={() => toggleDeleteModal(selectedData?.id)}>
                      <Box display={'flex'} alignItems={'center'} gap={'6px'}>
                        <img src={deleteIcon} alt="Icon" />
                        <Typography
                          sx={{
                            color: colors?.grey900,
                            '&:hover': {
                              color: colors?.darkTextHover,
                              fontWeight: 700
                            }
                          }}
                        >
                          Delete
                        </Typography>
                      </Box>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            </Box>
          );
        },
        ...(showSelectAdviser && {
          setCellProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              // left: 40,
              left: 0,
              minWidth: '380px',
              zIndex: 100
            }
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: 'nowrap',
              position: 'sticky',
              left: 40,
              minWidth: '380px',
              zIndex: 101,
              color: colors?.primaryTextColor
            }
          })
        })
      }
    },
    {
      name: 'search_term',
      label: 'Search Term',
      options: {
        filter: true,
        sort: true, // Make sure this is true to enable sorting
        width: 550,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <TableCell
              sx={{ position: 'sticky', top: '0px', zIndex: 1, cursor: 'pointer' }}
              onClick={() => handleToggleColumn(columnMeta.index)} // Handle sorting by clicking
              key={columnMeta.index}
            >
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap="5px">
                <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="flex-start">
                  <Box className="clr3 fw700 ffInter">Search Term</Box>
                </Box>
                {!__.isEmpty(sortOrder) && (
                  <Box>
                    {sortOrder.direction === 'asc' && sortOrder.name === 'search_term' ? (
                      <img src={upIcon} alt="up icon" />
                    ) : null}
                    {sortOrder.direction === 'desc' && sortOrder.name === 'search_term' ? (
                      <img src={downIcon} alt="down icon" />
                    ) : null}
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        },
        setCellHeaderProps: () => ({
          style: {
            color: colors?.primaryTextColor
          }
        }),
        customBodyRender: (value, rowData) => {
          const rowIndex = rowData?.rowIndex;
          const rowInfo = newData[rowIndex];
          return (
            <Link
              underline="none"
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open(`${rowInfo?.search_term_url}`, '_blank')}
            >
              <Typography className="ffInter" sx={{ color: colors.primaryMain }}>
                {value}
              </Typography>
            </Link>
          );
        }
      }
    },

    {
      name: 'profile_name',
      label: 'Profile Name',
      options: {
        filter: true,
        sort: true,
        width: 550,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <TableCell
              sx={{ position: 'sticky', top: '0px', zIndex: 1, cursor: 'pointer' }}
              onClick={() => handleToggleColumn(columnMeta?.index)}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
                <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="flex-start">
                  <Box className="clr3 fw700 ffInter">Profile Name</Box>
                </Box>
                {!__.isEmpty(sortOrder) && (
                  <Box>
                    {sortOrder?.direction === 'asc' && sortOrder?.name === 'profile_name' ? (
                      <img src={upIcon} alt="multiselect icon" />
                    ) : null}
                    {sortOrder?.direction === 'desc' && sortOrder?.name === 'profile_name' ? (
                      <img src={downIcon} alt="multiselect icon" />
                    ) : null}
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        },
        setCellHeaderProps: () => ({
          style: {
            color: colors?.primaryTextColor
          }
        }),
        customBodyRender: (value) => {
          return <Typography className="clr3 ffInter">{value}</Typography>;
        }
      }
    },

    {
      name: 'search_volume_rank',
      label: 'Search Volume Rank',
      options: {
        filter: true,
        sort: true,
        width: 550,
        setCellHeaderProps: () => ({
          style: {
            color: colors?.primaryTextColor
          }
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <TableCell
              sx={{ position: 'sticky', top: '0px', zIndex: 1, cursor: 'pointer' }}
              onClick={() => handleToggleColumn(columnMeta?.index)}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="center" gap={'5px'}>
                <Box display="flex" alignItems="flex-end" flexDirection="column" justifyContent="center">
                  <Box className="clr3 fw700 ffInter">Search Volume</Box>
                  <Box className="clr3 fw700 ffInter">Rank</Box>
                </Box>
                {!__.isEmpty(sortOrder) && (
                  <Box>
                    {sortOrder?.direction === 'asc' && sortOrder?.name === 'search_volume_rank' ? (
                      <img src={upIcon} alt="multiselect icon" />
                    ) : null}
                    {sortOrder?.direction === 'desc' && sortOrder?.name === 'search_volume_rank' ? (
                      <img src={downIcon} alt="multiselect icon" />
                    ) : null}
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return (
            <Typography className="clr3 ffInter" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {formateValue('searchVolumeRank', value)}
              {value === NO_SEARCH_VOLUME_RANK_FOUND && ' +'}
            </Typography>
          );
        }
      }
    },

    {
      name: 'total_search_results',
      label: 'Total Search Results',
      options: {
        filter: true,
        sort: true,
        width: 550,
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <TableCell
              sx={{ position: 'sticky', top: '0px', zIndex: 1, cursor: 'pointer' }}
              onClick={() => handleToggleColumn(columnMeta?.index)}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="center" gap={'5px'}>
                <Box display="flex" alignItems="flex-end" flexDirection="column" justifyContent="center">
                  <Box className="clr3 fw700 ffInter">Total Search</Box>
                  <Box className="clr3 fw700 ffInter">Results</Box>
                </Box>
                {!__.isEmpty(sortOrder) && (
                  <Box>
                    {sortOrder?.direction === 'asc' && sortOrder?.name === 'total_search_results' ? (
                      <img src={upIcon} alt="multiselect icon" />
                    ) : null}
                    {sortOrder?.direction === 'desc' && sortOrder?.name === 'total_search_results' ? (
                      <img src={downIcon} alt="multiselect icon" />
                    ) : null}
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        },
        setCellHeaderProps: () => ({
          style: {
            color: colors?.primaryTextColor
          }
        }),
        customBodyRender: (value) => {
          return (
            <Typography className="clr3 ffInter" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {value}
            </Typography>
          );
        }
      }
    },

    {
      name: 'first_seen',
      label: 'First Seen',
      options: {
        filter: true,
        sort: true,
        width: 550,
        setCellHeaderProps: () => ({
          style: {
            color: colors?.primaryTextColor
          }
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <TableCell
              sx={{ position: 'sticky', top: '0px', zIndex: 1, cursor: 'pointer' }}
              onClick={() => handleToggleColumn(columnMeta?.index)}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
                <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="flex-start">
                  <Box className="clr3 fw700 ffInter">First</Box>
                  <Box className="clr3 fw700 ffInter">Seen</Box>
                </Box>
                {!__.isEmpty(sortOrder) && (
                  <Box>
                    {sortOrder?.direction === 'asc' && sortOrder?.name === 'first_seen' ? (
                      <img src={upIcon} alt="multiselect icon" />
                    ) : null}
                    {sortOrder?.direction === 'desc' && sortOrder?.name === 'first_seen' ? (
                      <img src={downIcon} alt="multiselect icon" />
                    ) : null}
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        },
        customBodyRender: (value) => {
          return <Typography className="clr3 ffInter">{value}</Typography>;
        }
      }
    },
    {
      name: 'checked_at',
      label: 'Checked At',
      options: {
        filter: true,
        sort: true,
        width: 550,
        setCellHeaderProps: () => ({
          style: {
            color: colors?.primaryTextColor
          }
        }),
        customHeadRender: (columnMeta, handleToggleColumn, sortOrder) => {
          return (
            <TableCell
              sx={{ position: 'sticky', top: '0px', zIndex: 1, cursor: 'pointer' }}
              onClick={() => handleToggleColumn(columnMeta?.index)}
              key={columnMeta?.index}
            >
              <Box display="flex" alignItems="center" justifyContent="flex-start" gap={'5px'}>
                <Box display="flex" alignItems="flex-start" flexDirection="column" justifyContent="flex-start">
                  <Box className="clr3 fw700 ffInter">Checked</Box>
                  <Box className="clr3 fw700 ffInter">At</Box>
                </Box>
                {!__.isEmpty(sortOrder) && (
                  <Box>
                    {sortOrder?.direction === 'asc' && sortOrder?.name === 'checked_at' ? (
                      <img src={upIcon} alt="multiselect icon" />
                    ) : null}
                    {sortOrder?.direction === 'desc' && sortOrder?.name === 'checked_at' ? (
                      <img src={downIcon} alt="multiselect icon" />
                    ) : null}
                  </Box>
                )}
              </Box>
            </TableCell>
          );
        },
        setCellProps: () => ({
          className: ''
        }),
        customBodyRender: (value) => {
          return <Typography className="clr3 ffInter">{value}</Typography>;
        }
      }
    },
    {
      name: 'graph',
      label: 'Graph',
      default: false,
      options: {
        filter: true,
        sort: true,
        width: 550,
        customHeadRender: ({ index }) => {
          return <th key={index} style={{ position: 'sticky', top: '0px', zIndex: 1 }}></th>;
        },
        setCellProps: () => ({
          className: ''
        }),
        customBodyRender: (value, rowData) => {
          const rowIndex = rowData?.rowIndex;
          const rowInfo = newData[rowIndex];
          const organicRank = rowInfo?.organic_rank || {};
          const sponsorRank = rowInfo?.sponsor_rank || {};

          if (!__.isEmpty(organicRank) && !__.isEmpty(sponsorRank)) {
            return (
              <Box my={'6px'} display={'flex'} flexDirection={'column'} gap={'13px'} alignItems={'center'}>
                {(selectedRankTypeAfterSearchClicked === 'organic_rank' || selectedRankTypeAfterSearchClicked === 'both') && (
                  <Box sx={{ height: '14px' }} display={'flex'} alignItems={'center'}>
                    <Link onClick={() => handleChart(rowInfo, 'ORG')} href="javascript:void(0)">
                      <img src={chartIcon} alt="Chart Icon" style={{ width: '40px' }} />
                    </Link>
                    <Chip sx={{ height: '24px', minWidth: '51px' }} label="ORG" variant="outlined" color="primary" />
                  </Box>
                )}
                {(selectedRankTypeAfterSearchClicked === 'sponsor_rank' || selectedRankTypeAfterSearchClicked === 'both') && (
                  <Box sx={{ height: '14px' }} display={'flex'} alignItems={'center'}>
                    <Link onClick={() => handleChart(rowInfo, 'SP')} href="javascript:void(0)">
                      <img src={chartIcon} alt="Chart Icon" style={{ width: '40px' }} />
                    </Link>
                    <Chip
                      sx={{ height: '24px', minWidth: '51px' }}
                      height={'24px'}
                      label="SP"
                      variant="outlined"
                      color="primary"
                    />
                  </Box>
                )}
              </Box>
            );
          }
        }
      }
    }
  ];

  //Organic Rank Background Color
  const getOrganicRankBGClr = (value) => {
    let bgColor = '';
    if (value >= 1 && value <= 10) {
      bgColor = colors?.metricC;
    } else if (value >= 11 && value <= 25) {
      bgColor = color_1;
    } else if (value >= 26 && value <= 40) {
      bgColor = color_2;
    } else if (value >= 41 && value <= 80) {
      bgColor = color_3;
    } else if (value >= 81 && value <= 200) {
      bgColor = color_4;
    } else {
      bgColor = color_5;
    }
    return bgColor;
  };

  //Sponsor Rank Background Color
  const getSponsorRankBGClr = (value) => {
    let bgColor = '';
    if (value >= 1 && value <= 4) {
      bgColor = colors?.metricC;
    } else if (value >= 5 && value <= 10) {
      bgColor = color_1;
    } else if (value >= 11 && value <= 20) {
      bgColor = color_2;
    } else if (value >= 21 && value <= 30) {
      bgColor = color_3;
    } else if (value >= 31 && value <= 50) {
      bgColor = color_4;
    } else {
      bgColor = color_5;
    }
    return bgColor;
  };

  //Table Columns
  const dateColumns = last14Days.map((day) => ({
    name: day?.name?.dayOfWeek,
    options: {
      filter: true,
      sort: false,
      width: 150,
      setCellProps: () => ({
        style: { padding: '2px', top: '0px', position: 'sticky' }
      }),
      setCellHeaderProps: () => ({
        style: { minWidth: '40px', maxWidth: '40px', top: '0px', position: 'sticky' }
      }),
      customHeadRender: ({ index }) => {
        return (
          <th key={index} style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
            <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
              <Box
                color={(day.name.dayOfWeek === 'Sat' || day.name.dayOfWeek === 'Sun') && `${colors?.grey500} !important`}
                className="clr3 fw700 ffInter"
              >
                {day.name.dayOfWeek}
              </Box>
              <Box className="clr3 fw700 ffInter">{day.name.monthDay}</Box>
            </Box>
          </th>
        );
      },
      customBodyRender: (value, rowData) => {
        const rowIndex = rowData?.rowIndex;
        const rowInfo = newData[rowIndex];
        const organicRank = rowInfo?.organic_rank || {};
        const sponsorRank = rowInfo?.sponsor_rank || {};

        const rowDate = day?.name?.date;
        const organicValue = organicRank?.[rowDate];
        const sponsorValue = sponsorRank?.[rowDate];

        return (
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
            {(selectedRankTypeAfterSearchClicked === 'organic_rank' || selectedRankTypeAfterSearchClicked === 'both') && (
              <Chip
                className="clr3 ffInter fs14"
                label={organicValue || defaultOrganicRank}
                sx={{
                  backgroundColor: getOrganicRankBGClr(organicValue),
                  width: '58px',
                  height: selectedRankTypeAfterSearchClicked === 'organic_rank' ? '46px' : '22px',
                  borderRadius: '3px',
                  marginBottom: selectedRankTypeAfterSearchClicked === 'organic_rank' ? '0px' : '3px'
                }}
              />
            )}

            {(selectedRankTypeAfterSearchClicked === 'sponsor_rank' || selectedRankTypeAfterSearchClicked === 'both') && (
              <Chip
                className="clr3 ffInter fs14"
                label={sponsorValue || defaultSponsorRank}
                sx={{
                  backgroundColor: getSponsorRankBGClr(sponsorValue),
                  width: '58px',
                  height: selectedRankTypeAfterSearchClicked === 'sponsor_rank' ? '46px' : '22px',
                  borderRadius: '3px'
                }}
              />
            )}
          </Box>
        );
      }
    }
  }));

  //Table Columns
  const columns = [...baseColumns, ...dateColumns];

  const initialColumnOrder = columns.map((_, index) => index);
  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //Table Options
  const options = {
    search: false,
    filter: false,
    print: false,
    viewColumns: false,
    selectableRows: false,
    selectToolbarPlacement: 'none',
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyHeight: tableHeight,
    page: page,
    count: rankTrackerDetails?.data?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: true,
    serverSide: true,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    draggableColumns: {
      enabled: true
    },
    columnOrder: columnOrder,
    onColumnOrderChange: (newColumnOrder) => {
      if (newColumnOrder[0] !== 0) {
        setColumnOrder([...columnOrder]);
      } else {
        setColumnOrder(newColumnOrder);
      }
    },
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      const selectedIds = rowsSelected.map((selectedIndex) => rankTrackerDetails?.results?.[selectedIndex]?.id);
      const ItemIds = rowsSelected.map((selectedIndex) => rankTrackerDetails?.results?.[selectedIndex]?.item_id);
      setSelectedArray(selectedIds);
      SetSelectedItemsId(ItemIds);
      return null;
    },
    textLabels: {
      body: {
        noMatch: showSelectAdviser ? (
          tableLoading ? (
            <Loader />
          ) : (
            'Sorry, there is no matching data to display'
          )
        ) : (
          'Please Select At List One Advertiser '
        )
      }
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      const request = {
        page: 0,
        rowPerPage: row,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        PerformanceFilter: performanceRanges,
        tableFilter: filterForSearch
      };
      getRankItemListWithPagination(request);
    },
    onChangePage: (page) => {
      setPage(page);

      const request = {
        page,
        rowPerPage,
        changedColumn: appliedSortTing?.changedColumnSort,
        direction: appliedSortTing?.directionSort,
        commonAdvertiserList: commonAdvertiser,
        PerformanceFilter: performanceRanges,
        tableFilter: filterForSearch
      };
      getRankItemListWithPagination(request);
    },
    // onSearchChange: (searchText) => {
    //   console.log(searchText);
    // },
    // onSearchClose: () => {
    // },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);

      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      const request = {
        page: 0,
        rowPerPage,
        changedColumn,
        direction,
        commonAdvertiserList: commonAdvertiser,
        PerformanceFilter: performanceRanges,
        tableFilter: filterForSearch
      };
      getRankItemListWithPagination(request);
    },
    customTableBodyFooterRender: (opts) => {
      const optsColumns = opts?.columns;
      const reorderedColumns = columnOrder.map((index) => optsColumns[index]);
      return (
        <>
          {commonAdvertiser?.length > 0 && (
            <TableFooter>
              <TableRow className="table-footer-total" sx={{ position: 'sticky', bottom: -1, background: 'white', zIndex: 999 }}>
                {/* <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  left: 1,
                  // minWidth: '180px',
                  background: 'white',
                  zIndex: 100
                }}
              ></TableCell> */}
                {reorderedColumns?.map((col, index) => {
                  if (col && col?.display === 'true') {
                    return (
                      <TableCell
                        className={`${col?.sticky ? 'column-sticky' : 'column-sticky-1'} ${
                          alignRightColumn(col?.name) && 'table-align-right'
                        }`}
                        sx={
                          col?.sticky
                            ? {
                                whiteSpace: 'nowrap',
                                position: 'sticky',
                                left: col?.leftTotal,
                                fontSize: '14px',
                                // fontSize: col?.FontSize?.length > 0 ? col?.FontSize : '',
                                minWidth: '180px',
                                background: 'white',
                                zIndex: 100,
                                color: colors?.primaryTextColor,
                                fontWeight: 'bold'
                              }
                            : {}
                        }
                        key={index}
                      >
                        {col?.name == 'details' && 'Total'}
                        {col?.name == 'search_volume_rank' && (
                          <Typography
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              fontWeight: 700,
                              fontFamily: 'Inter',
                              color: colors?.primaryTextColor
                            }}
                          >
                            {formateValue('totalSearchRank', totalSearchRank)}
                          </Typography>
                        )}
                        {col?.name == 'total_search_results' && (
                          <Typography
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              fontWeight: 700,
                              fontFamily: 'Inter',
                              color: colors?.primaryTextColor
                            }}
                          >
                            {totalSearchResults}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableFooter>
          )}
        </>
      );
    }
  };

  //Open Bulk Options Dropdown
  useEffect(() => {
    if (selectedArray) {
      if (selectedArray.length >= 2) {
        SetShowDropDown(true);
      } else {
        SetShowDropDown(false);
      }
    }
  }, [selectedArray]);

  //Download CSV

  // const handleDownloadCsv = () => {
  // const request = {
  //   page,
  //   rowPerPage,
  //   changedColumn: appliedSortTing?.changedColumnSort,
  //   direction: appliedSortTing?.directionSort,
  //   commonAdvertiserList: commonAdvertiser,
  //   PerformanceFilter: PerformanceFilterData,
  //   tableFilter,
  //   is_csv: true,
  // };
  // getRankItemListWithPagination(request);
  // };

  // adgroup, campaign, aditem, keyword
  // const group_by = [
  //   { id: 1, name: 'Items', value: 'aditem' },
  //   { id: 2, name: 'Ad Group', value: 'adgroup' },
  //   { id: 3, name: 'Campaign', value: 'campaign' }
  // ];

  //While changing Performance Filters min - max

  //Min and Max input change
  const handleInputChange = (performanceId, valueType, value, showError) => {
    setErrorShow((ErrorShow) => ({
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    }));

    let a = {
      ...ErrorShow,
      [performanceId]: {
        ...ErrorShow[performanceId],
        error: showError
      }
    };
    let output = Object.values(a).map((item) => {
      if (typeof item === 'object' && item !== null && 'error' in item) {
        return item.error;
      } else {
        return item;
      }
    });
    let result = [...new Set(output)];

    let finalOutPut = result.length == 1 && result[0] == false ? false : true;
    setIsDisableSearch(finalOutPut);

    setPerformanceRanges((prevRanges) => ({
      ...prevRanges,
      [performanceId]: {
        ...prevRanges[performanceId],
        [valueType]: value
      }
    }));
  };

  //Update Multilie Input Value to State
  const handleUpdateMultilineValue = (value, key) => {
    setTableFilter((prevState) => ({
      ...prevState,
      [key]: value
    }));
  };

  //Refresh list once after keyword created
  const refreshListOnceUploaded = () => {
    const request = {
      page,
      rowPerPage,
      changedColumn: appliedSortTing?.changedColumnSort,
      direction: appliedSortTing?.directionSort,
      commonAdvertiserList: commonAdvertiser,
      PerformanceFilter: performanceRanges,
      tableFilter: filterForSearch
    };
    getRankItemListWithPagination(request);
  };

  return (
    <MainCard title="Rank Tracker">
      {' '}
      <Box display={'flex'} justifyContent={'space-between'} alignContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'}>
          <CustomFilters filterType={'rank_tracker_filter'} defaultFilterKey={params?.id ? 'campaign' : null} />
          {filterDataList?.Common?.find((item) => item?.value === 'rank_type') && (
            <Box ml={2} minWidth={showDropdown ? '60%' : '100%'}>
              <Autocomplete
                disableClearable
                limitTags={2}
                id="checkboxes-tags-demo"
                name="rank_type"
                className="custom-auto"
                options={allRankTypes || []}
                value={selectedRankType}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                getOptionLabel={(option) => option?.name || ''}
                onChange={(e, value) => {
                  setSelectedRankType(value);
                }}
                renderOption={(props, option) => {
                  return <li {...props}>{option?.name}</li>;
                }}
                renderInput={(params) => <TextField color="secondary" sx={{ widh: '250px' }} {...params} label="Rank Type" />}
              />
            </Box>
          )}
          {/* <Box minWidth={showDropdown ? '60%' : '100%'}>
            <Autocomplete
              limitTags={2}
              id="checkboxes-tags-demo"
              name="Group by"
              className="custom-auto custom-grp"
              options={group_by || []}
              value={groupBy?.value}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name}
              onChange={(e, value) => {
                setGroupBy(value);
              }}
              renderOption={(props, option) => {
                return <li {...props}>{option?.name}</li>;
              }}
              renderInput={(params) => <TextField color="secondary" sx={{ widh: '250px' }} {...params} label="Group by" />}
            />
          </Box> */}
          {showDropdown && itemsUpdate && (
            <Box className="Custom-bulk-name" minWidth={'45%'}>
              <NestedDropdown
                className="Custom-nested-dropdown"
                menuItemsData={menuItemsData}
                MenuProps={{ elevation: 1, className: 'Custom-menu-bulk' }}
                ButtonProps={{
                  variant: 'contained',
                  className: 'Custom-dropDown-bulk',
                  startIcon: <img src={MultiSelectIcon} alt="multiselect icon" style={{ width: '24px', height: '24px' }} />, // Adding the start icon here
                  endIcon: null // Remove any end icon including a dropdown arrow
                }}
                onClick={() => console.log('Clicked')}
              />
            </Box>
          )}
        </Box>
        <Box display={'flex'}>
          <Box display={'flex'} fontFamily={'Inter'} alignItems={'center'} gap={'5px'} mr={3}>
            <img src={groupIcon} alt="group icon" style={{ width: '24px', height: '24px' }} />
            <Typography
              sx={{ fontFamily: 'Inter' }}
              color={colors?.primaryTextColor}
            >{`${usedKeywords} / ${totalKeywords}`}</Typography>
            <Typography sx={{ fontFamily: 'Inter' }}>Keywords Used</Typography>
          </Box>
          {rankTrackerCreate && (
            <Button
              sx={{
                borderRadius: '8px',
                py: '12px'
                // padding: '12px 14px 12px 10px'
              }}
              startIcon={<Add />}
              onClick={() => setIsModalAddItem(!isModalAddItem)}
              size=""
              variant="contained"
            >
              Add Items
            </Button>
          )}
          <Box display={'flex'} ml={1}>
            <Button sx={{ minWidth: '50px', justifyContent: 'flex-end' }} onClick={handleColumnSelectorOpen}>
              <Tooltip arrow title="Select/Reorder Columns">
                <img src={viewcolumnsIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
              </Tooltip>
            </Button>
            {/* <Button
              id="basic-button-up"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{ minWidth: '50px', justifyContent: 'flex-end' }}
              onClick={handleClickUp}
            >
              <img src={uploadIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElUp}
              open={openUp}
              onClose={handleCloseUp}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{ borderColor: colors?.grey100, borderRadius: '6px' }}
            >
              <MenuItem sx={{ fontFamily: 'Inter' }} onClick={() => setIsModalUploadItem(!isModalUploadItem)}>
                Item - Search Term
              </MenuItem>
              <MenuItem sx={{ fontFamily: 'Inter' }} onClick={() => setIsModalUploadItem(!isModalUploadItem)}>
                History
              </MenuItem>
            </Menu>
            <Button
              id="basic-button-dwn"
              aria-controls={openDwn ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openDwn ? 'true' : undefined}
              sx={{ minWidth: '50px', justifyContent: 'flex-end' }}
              onClick={handleClickDwn}
            >
              <img src={downloadIcon} alt="filter icon" style={{ width: '24px', height: '24px' }} />
            </Button>
            <Menu
              id="basic-menu-dwn"
              anchorEl={anchorElDwn}
              open={openDwn}
              onClose={handleCloseDwn}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
              sx={{ borderColor: colors?.grey100, borderRadius: '6px' }}
            >
              <MenuItem sx={{ fontFamily: 'Inter' }} onClick={handleDownloadCsv}>
                Item - Search Term
              </MenuItem>
              <MenuItem sx={{ fontFamily: 'Inter' }} onClick={handleDownloadCsv}>
                History
              </MenuItem>
            </Menu> */}
          </Box>
        </Box>
        {/* search button here */}
      </Box>
      <Box display={'flex'}>
        <Grid container sx={{ px: 0, py: 3, flexWrap: 'nowrap' }} spacing={2}>
          {filterDataList?.Common?.find((item) => item?.value === 'details') && (
            <Grid item md={3}>
              <MultilineInputPopover
                label={'Item'}
                deaultValue={tableFilter?.item__in}
                handleUpdateMultilineValue={(value) => handleUpdateMultilineValue(value, 'item__in')}
              />
            </Grid>
          )}

          {filterDataList?.Common?.find((item) => item?.value === 'search_term') && (
            <Grid item md={3}>
              <MultilineInputPopover
                label={'Search Term'}
                deaultValue={tableFilter?.search_term__in}
                handleUpdateMultilineValue={(value) => handleUpdateMultilineValue(value, 'search_term__in')}
              />
            </Grid>
          )}
          {filterDataList?.Common?.filter((item) => item?.value === 'search_volume_rank' || item?.value === 'current_rank')?.map(
            (performanceItem) => (
              <Grid item md={3} key={performanceItem.id}>
                <FilterPerformance performanceItem={performanceItem} handleInputChange={handleInputChange} />
              </Grid>
            )
          )}
          <Grid item md={3}>
            <Button
              sx={{
                borderRadius: '8px',
                py: '12px',
                minWidth: '135px'
              }}
              disabled={isDisableSearch}
              startIcon={
                <img src={searchIcon} alt="filter icon" style={{ width: '18px', height: '18px', fontFamily: 'Inter' }} />
              }
              onClick={handleSearchClick}
              size=""
              variant="contained"
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        // className={'custom-item-table'}
        // className={'custom-item-2-table'}
        className={'custom-budget-table-no-checkbox'}
      >
        {internalTableLoading ? (
          <Box display="flex" justifyContent="center" minHeight="200">
            <TableSkeleton />
          </Box>
        ) : (
          <MUIDataTable
            actions={[
              {
                icon: 'save'
              }
            ]}
            data={tableLoading ? [] : showSelectAdviser ? newData : []}
            columns={getUpdatedColumns(columns, selectedColumns)}
            options={options}
            className="pnl-by-item"
          />
        )}
      </Box>
      {viewColumnsSelector && (
        <CustomColumnSelector
          open={viewColumnsSelector}
          handleClose={handleColumnSelectorClose}
          tableName="rank_tracker_column"
          defaultSelected={!__.isEmpty(selectedColumns) ? selectedColumns : baseColumns}
          columns={getUpdatedColumns(baseColumns, selectedColumns)}
          setColumnOrder={(columnOrder) => {
            const dateColumnsLength = dateColumns?.length;
            const maxValue = Math.max(...columnOrder);
            for (let i = 1; i <= dateColumnsLength; i++) {
              columnOrder.push(maxValue + i);
            }
            setColumnOrder(columnOrder);
          }}
          columnOrder={columnOrder.slice(0, baseColumns?.length)}
        />
      )}
      {isModalAddItem && (
        <>
          <MultipleItemAdd
            open={isModalAddItem}
            OnClose={() => setIsModalAddItem(!isModalAddItem)}
            refreshListOnceUploaded={() => refreshListOnceUploaded()}
          />
        </>
      )}
      {isModalUploadItem && 0 && (
        <>
          <UploadItemModal open={isModalUploadItem} OnClose={() => setIsModalUploadItem(!isModalUploadItem)} />
        </>
      )}
      {isModalChart && (
        <SearchRankGraph itemInfo={selectedRowDataForChart} open={isModalChart} handleClose={() => setIsModalChart(false)} />
      )}
      {openDelete && (
        <DeleteModalDialog
          item="Keyword Item"
          cancelCallback={() => setOpenDelete(!openDelete)}
          deleteCallback={confirmDelete}
          open={openDelete}
        />
      )}
    </MainCard>
  );
};

export default RankTracker;
