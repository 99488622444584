import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import { updateCampaignModal } from 'store/thunk/userThunk';

export default function EditPlateformModal({ plateformEditModal, onClose, onGetCampaignData, setInlineLoader }) {
  // platform_bid_app platform_bid_desktop platform_bid_mobile
  const [
    loading
    // setLoading
  ] = useState();
  const [campaign, setCampaign] = useState({
    platform_bid_app: plateformEditModal?.data?.platform_bid_app,
    platform_bid_desktop: plateformEditModal?.data?.platform_bid_desktop,
    platform_bid_mobile: plateformEditModal?.data?.platform_bid_mobile
  });
  const dispatch = useAppDispatch();

  const handledPercentageChange = (e) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 900) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [e.target.name]: inputValue
      }));
    }
  };

  const handleClick = () => {
    let latestData = {};
    Object.keys(campaign).forEach((key) => {
      // if (campaign[key]) {
      latestData = { ...latestData, [key]: Number(campaign[key]) };
      // }
    });
    const _request = { ...latestData, advertiser: plateformEditModal?.data?.advertiser };
    const existingCampaignId = plateformEditModal?.data?.id;
    setInlineLoader({ platform: existingCampaignId });
    onClose();
    dispatch(updateCampaignModal({ _request, existingCampaignId })).then((res) => {
      if (res?.payload?.status == 201 || res?.payload?.status == 200) {
        onGetCampaignData(existingCampaignId, campaign);
      }
    });
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={1.5} sx={{ padding: '25px 0px', minWidth: '600px' }}>
        {
          <Grid item sm={4}>
            {renderLabel('App')}
            <TextField
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              placeholder="0-900"
              inputProps={{
                min: 0,
                max: 900
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="platform_bid_app"
              value={campaign?.platform_bid_app}
              onChange={handledPercentageChange}
            />
          </Grid>
        }

        {
          <Grid item sm={4}>
            {renderLabel('Desktop')}
            <TextField
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              inputProps={{
                min: 0,
                max: 900
              }}
              placeholder="0-900"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="platform_bid_desktop"
              value={campaign?.platform_bid_desktop}
              onChange={handledPercentageChange}
            />
          </Grid>
        }
        {/* // platform_bid_mobile */}
        {
          <Grid item sm={4}>
            {renderLabel('Mobile')}
            <TextField
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              inputProps={{
                min: 0,
                max: 900
              }}
              placeholder="0-900"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="platform_bid_mobile"
              value={campaign?.platform_bid_mobile}
              onChange={handledPercentageChange}
            />
          </Grid>
        }
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" variant="outlined" sx={{ mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={handleClick} disabled={loading}>
          {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
          Confirm
        </Button>
      </Box>
    </>
  );
}
