// import { useAppSelector } from 'store';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useParams, useLocation } from 'react-router-dom';

// material-ui
import { Grid, Typography, Button, IconButton, Box, Container, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import DashnaLogo from 'assets/images/dashna/dashnaIco.svg';
import SuccessIcon from 'assets/images/icons/success.svg';

const SuccessExternalFlow = () => {
  const { sellerType } = useParams(); // Get the 3p or 1p from the URL
  const query = new URLSearchParams(useLocation().search);

  // Extract the query parameters
  const code = query.get('code');
  const type = query.get('type');
  const clientId = query.get('clientId');
  const sellerId = query.get('sellerId');
  const state = query.get('state');

  console.log('sellerType ', sellerType);
  console.log('code', code);
  console.log('type', type);
  console.log('clientId', clientId);
  console.log('sellerId', sellerId);
  console.log('state', state);

  // const { selectedStoreData } = useAppSelector((state) => state?.userDetailsSlice);
  const message =
    'Your Supplier One store has been successfully connected. You can  close this tab and continue where you left off.';
  // : 'Your Walmart store has been successfully connected. We’ll now begin syncing your data to ensure smooth management and optimization of your store. You may now close this tab.';

  //Color
  const primaryMain = colors?.primaryMain;
  const primaryTextColor = colors?.primaryTextColor;
  const pageBackground = colors?.pageBackground;
  const paper = colors?.paper;
  const borderColor = colors?.grey300;

  const handleClose = () => {
    setIsOpenModalAddStore(false);
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} minHeight={'100vh'} backgroundColor={pageBackground}>
        <Container maxWidth="sm" sx={{ maxWidth: '700px !important' }}>
          <Grid item xs={12} md={12}>
            <Box backgroundColor={paper} borderRadius={'10px'} border={`1px solid ${borderColor}`}>
              <Grid item xs={12}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} px={2} py={0.5}>
                  <Typography className={'ffInter fs20 fw600'} color={primaryMain}>
                    Success!
                  </Typography>
                  <IconButton>
                    <img src={DashnaLogo} alt="Dashna Logo" />
                  </IconButton>
                  <IconButton
                    // aria-label="close"
                    onClick={handleClose}
                    sx={{
                      // position: 'absolute',
                      // right: 8,
                      // top: 8,
                      color: (theme) => theme.palette.grey[500]
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider />

                <Box px={4} py={18}>
                  <Box>
                    <img src={SuccessIcon} alt="Success icon" />
                  </Box>
                  <Typography color={primaryTextColor} className="ffInter fs24 fw600" mt={2}>
                    Success!
                  </Typography>
                  <Typography color={primaryTextColor} className="ffInter fs16 fw400" mt={2}>
                    {message}
                  </Typography>
                </Box>

                <Divider />
                <Box display={'flex'} justifyContent={'flex-end'} px={3} py={1.5}>
                  <Button onClick={() => handleClose()} variant="outlined" color="primary">
                    Close
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SuccessExternalFlow;
