import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ForgotPassword')));
// const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication/ResetPassword')));
const AuthCreatePassword = Loadable(lazy(() => import('views/pages/authentication/authentication/CreatePassword')));

//Verification Routes
const VerifyEmail = Loadable(lazy(() => import('views/pages/authentication/emailVerification/verifyEmail')));
import WalmartRedirectSuccess from 'views/pages/authentication/emailVerification/modals/walmartRedirectSuccess';

import PublicRoute from 'PublicRoute';
import VerifyOtp from 'views/pages/authentication/authentication/VerifyOtp';
import DashboardOverview from 'views/Overview';
import SingUp from 'views/signUp';
import SignIn from 'views/signIn';
import ResetPassword from 'views/reset-passwrod';
import PasswordConfirm from 'views/forgotpassword';
import ConnectToWalmartExternal from 'views/pages/authentication/authentication/externalFlow/ConnectToWalmartExternal';
import SuccessExternalFlow from 'views/pages/authentication/authentication/externalFlow/SuccessExternalFlow';
// import SuccessExternal from 'views/pages/authentication/authentication/externalFlow/SuccessExternal';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: (
        <PublicRoute>
          {/* <AuthLogin /> */}
          <SignIn />
        </PublicRoute>
      )
    },
    {
      path: '/forget-password',
      element: (
        <PublicRoute>
          <ResetPassword />
        </PublicRoute>
      )
    },
    {
      path: '/signup',
      element: (
        <PublicRoute>
          <SingUp />
        </PublicRoute>
      )
    },
    {
      path: '/verify-user',
      element: (
        <PublicRoute>
          <VerifyOtp />
        </PublicRoute>
      )
    },
    {
      path: '/register',
      element: (
        <PublicRoute>
          {/* <AuthRegister /> */}
          <SingUp />
        </PublicRoute>
      )
    },
    {
      path: '/forgot-password',
      element: (
        <PublicRoute>
          <AuthForgotPassword />
        </PublicRoute>
      )
    },
    {
      path: '/reset-password',
      element: (
        <PublicRoute>
          <PasswordConfirm />
        </PublicRoute>
      )
    },
    {
      path: '/create-password',
      element: (
        <PublicRoute>
          <AuthCreatePassword />
        </PublicRoute>
      )
    },
    {
      path: '/dashboard-overview/:token',
      element: (
        <PublicRoute>
          <DashboardOverview />
        </PublicRoute>
      )
    },
    {
      path: '/verify-email/:useridb64/:otpb64',
      element: (
        <PublicRoute>
          <VerifyEmail />
        </PublicRoute>
      )
    },
    {
      path: '/external-store-creation/:token',
      element: (
        <PublicRoute>
          <ConnectToWalmartExternal />
        </PublicRoute>
      )
    },
    {
      path: '/external-success',
      element: (
        <PublicRoute>
          <SuccessExternalFlow />
        </PublicRoute>
      )
    },

    {
      path: '/resource/:sellerType/applanding',
      element: (
        <PublicRoute>
          <WalmartRedirectSuccess />
        </PublicRoute>
      )
    }

    // {
    //   path: '/external-success',
    //   element: (
    //     <PublicRoute>
    //       <SuccessExternal />
    //     </PublicRoute>
    //   )
    // }
  ]
};

export default AuthenticationRoutes;
