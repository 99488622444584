import MUIDataTable from 'mui-datatables';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { formatData, useFormattedDataOnce } from 'utils/utilsFunction';
import CustomTableHead from 'ui-component/customTableHead';

const WeeklyPerformance = ({ weeklyReport }) => {
  // console.log("weeklyReport ", weeklyReport);
  // const [displayData, setDisplayData] = useState([]);
  // useEffect(() => {
  //   if (weeklyReport) {
  //     let tempData = [];
  //     const tempArray = Object.keys(weeklyReport);
  //     tempArray?.map((item, index) => {
  //       tempData.push({ ...weeklyReport[item], calendar_week: tempArray[index] })
  //     })
  //     setDisplayData(tempData);
  //   }
  // }, [weeklyReport])
  // const displayData = [
  //   {
  //     calendar_week: 1,
  //     impressions: 1,
  //     clicks: 1,
  //     ctr: 1,
  //     units_sold: 1,
  //     cpc: 1,
  //     conversion_rate: 1,
  //     ad_spend: 1,
  //     ad_sales: 1,
  //     roas: 1,
  //   },
  //   {
  //     calendar_week: 2,
  //     impressions: 1,
  //     clicks: 1,
  //     ctr: 1,
  //     units_sold: 1,
  //     cpc: 1,
  //     conversion_rate: 1,
  //     ad_spend: 1,
  //     ad_sales: 1,
  //     roas: 1,
  //   },
  //   {
  //     calendar_week: 3,
  //     impressions: 1,
  //     clicks: 1,
  //     ctr: 1,
  //     units_sold: 1,
  //     cpc: 1,
  //     conversion_rate: 1,
  //     ad_spend: 1,
  //     ad_sales: 1,
  //     roas: 1,
  //   },
  // ];

  const displayData = useFormattedDataOnce(weeklyReport, formatData);

  const columns = [
    {
      name: 'calendar_week',
      label: 'Calendar Week',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Calendar Week'
          });
        }
      }
    },
    {
      name: 'ad_spend',
      label: 'Spend',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Spend'
          });
        }
      }
    },
    {
      name: 'ad_sales',
      label: 'Sales',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Sales'
          });
        }
      }
    },
    {
      name: 'unitsold',
      label: 'Units',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Units'
          });
        }
      }
    },
    {
      name: 'asp',
      label: 'ASP (Sales/Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'ASP (Sales/Units)'
          });
        }
      }
    },
    {
      name: 'impressions',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Impressions'
          });
        }
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Clicks'
          });
        }
      }
    },
    {
      name: '0',
      label: 'Orders',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Orders'
          });
        }
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CTR'
          });
        }
      }
    },

    {
      name: 'cvr_orders',
      label: 'CVR (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CVR (Orders)'
          });
        }
      }
    },
    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'RoAS'
          });
        }
      }
    },
    {
      name: 'acos',
      label: 'ACoS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'ACoS'
          });
        }
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    // selectableRows: 'none',
    // page: 1,
    // count: displayData?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: false,
    serverSide: false,
    selectableRows: 'none',
    search: false,
    viewColumns: false,
    draggableColumns: {
      enabled: true
    },
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      console.log('New Column Order: ', newColumnOrder);
      console.log('columnIndex ', columnIndex);
      console.log('newPosition ', newPosition);
    }
    // rowsPerPage: rowPerPage,
    // rowsPerPageOptions: [10, 20, 50],
  };

  return (
    <>
      <MainCard titleInner="Weekly Performance Trends (WoW)">
        <MUIDataTable
          actions={[
            {
              icon: 'save'
            }
          ]}
          data={displayData}
          columns={columns}
          options={options}
          className="pnl-by-item"
        />
      </MainCard>
    </>
  );
};

export default WeeklyPerformance;
