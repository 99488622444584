import { Box, Typography } from '@mui/material';
import { CustomSwitch } from 'ui-component/customSwitch';

const AutomationSetting = (props) => {
  const { data, setData } = props;

  const handleOverspendSwitch = (value) => {
    setData({ ...data, stopOverSpend: value, autoReEnable: !value ? false : data?.autoReEnable });
  };

  return (
    <Box>
      <Box mt={2}>
        <Box className="bg-grey-100 border-rounded-10" pl={2} pr={2} pt={1} pb={1}>
          <Box display="flex" alignItems="center">
            <Typography mr={2}>Stop Overspend</Typography>
            <CustomSwitch
              disabled
              checked={data?.stopOverSpend}
              onChange={() => handleOverspendSwitch(!data?.stopOverSpend)}
              value="checked"
            />
          </Box>
          <Typography className="placeholder-text" mt={1}>
            Pause all profile campaigns when the monthly budget has run out. <br />
            If Split Budget Evenly is enabled, the campaigns will pause when the daily allocated budget runs out.
          </Typography>
        </Box>
      </Box>
      <Box mt={2}>
        <Box className="bg-grey-100 border-rounded-10" pl={2} pr={2} pt={1} pb={1}>
          <Box display="flex" alignItems="center">
            <Typography mr={2}>Auto Enable</Typography>
            <CustomSwitch
              disabled
              // disabled={!data?.stopOverSpend}
              checked={data.autoReEnable}
              onChange={() => setData({ ...data, autoReEnable: !data?.autoReEnable })}
              value="checked"
            />
          </Box>
          <Typography className="placeholder-text" mt={1}>
            Automatically enable the campaigns paused by Stop Overspend when the monthly budget is supplemented or at the start of
            the next month. <br />
            If Split Budget Evenly is enabled, campaigns will reenable when the monthly budget is supplemented or at the start of
            the next day.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AutomationSetting;
