import { useState, useEffect } from 'react';
import { useAppDispatch } from 'store';
import { GetAdvertisersStoreData } from 'store/thunk/userThunk';
import colors from 'assets/scss/_themes-vars.module.scss';
// material-ui
import {
  Dialog,
  Grid,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Box,
  Link,
  DialogTitle,
  DialogActions
} from '@mui/material';
import { encryptText } from 'helper/commonHelper';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import DashnaLogo from 'assets/images/dashna/dashnaIco.svg';
import SyncAlt from 'assets/images/dashna/syncAlt.svg';
import WalmartIco from 'assets/images/dashna/walmartIco.svg';
import CustomLoader from 'ui-component/custom-loader';

const ConnectWalmartLogin = (props) => {
  const { walmartLoginUniqueStateIdInfo, setIsConnectingWalmart, setGoNextToAddAdvertiser, addNewStoreExternal, otherProps } =
    props;
  const [open, setOpen] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    localStorage.setItem('isProcessingStoreCreation', 'start');

    const uniqueStateKey = Object.keys(walmartLoginUniqueStateIdInfo)?.[0];
    const uniqueStateValue = Object.values(walmartLoginUniqueStateIdInfo)?.[0];

    localStorage.setItem(uniqueStateKey, encryptText(JSON.stringify(uniqueStateValue)));

    const handleStorageChange = (event) => {
      if (event.key === 'isProcessingStoreCreation') {
        //When store creation of connect to walmart login is success
        if (event.newValue === 'true') {
          setIsConnectingWalmart(false);
          setGoNextToAddAdvertiser(true);
          dispatch(GetAdvertisersStoreData(otherProps?.pageProps));
        } else {
          //When store creation of connect to walmart login has error
          clickToBack();
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      localStorage.removeItem('isProcessingStoreCreation');
      localStorage.removeItem(uniqueStateKey);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  //Color
  const primaryTextColor = colors?.primaryTextColor;

  const handleClose = () => {
    setOpen(false);
    setIsOpenModalAddStore(false);
    setIsOpenModalEditStore(false);
  };

  const clickToBack = () => {
    setIsConnectingWalmart(false);
  };

  return (
    <>
      <Dialog
        className={addNewStoreExternal ? 'externalDialogBackClr' : ''}
        maxWidth={'md'}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { width: '700px', maxWidth: '700px' } }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex' }} alignItems={'center'} gap={2}>
            <Box>
              <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'}>
                Connect to Walmart
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              <Link to="#">
                <img src={DashnaLogo} alt="Dashna Logo" />
              </Link>
              <Link to="#">
                <img src={SyncAlt} alt="Sync Logo" />
              </Link>
              <Link to="#">
                <img src={WalmartIco} alt="Marketplace Logo" />
              </Link>
            </Box>
          </Box>
          {!addNewStoreExternal && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} pt={5} pl={2} pb={1}>
              <Typography className={'fontInter'} color={primaryTextColor} fontSize={'20px'} fontWeight={700} pb={1.5}>
                Connect to Walmart Marketplace
              </Typography>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} py={15}>
                <CustomLoader />
                <Typography className={'fontInter'} color={primaryTextColor} fontSize={'16px'} fontWeight={400} py={2}>
                  Waiting for Walmart Authentication on the other tab.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={'actionBtnOutline'}
            sx={{ minWidth: '100px' }}
            onClick={() => clickToBack()}
            variant="outlined"
            color="primary"
          >
            Back
          </Button>
        </DialogActions>{' '}
      </Dialog>
    </>
  );
};

export default ConnectWalmartLogin;
