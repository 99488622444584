import { useState, useEffect } from 'react';
// import { useTheme } from '@emotion/react';
import { useAppSelector } from 'store';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import BudgetSettingChart from './BudgetSettingChart';
import { CustomSwitch } from 'ui-component/customSwitch';
import colors from 'assets/scss/_themes-vars.module.scss';

const BudgetSetting = (props) => {
  const { data, setData, error } = props;
  // const theme = useTheme();
  const primary200 = colors.metricA;
  const primaryDark = colors.metricB;
  const secondaryMain = colors.metricC;
  const { BudgetPerformanceData } = useAppSelector((state) => state?.userDetailsSlice);

  const [selectedField, setSelectedField] = useState([
    { index: 0, color: primary200, display_field: 'Spend', name: 'ad_spend' },
    { index: 1, color: primaryDark, display_field: 'Sales', name: 'attributed_sales' },
    { index: 2, color: secondaryMain, display_field: 'Orders', name: 'attributed_orders' }
  ]);

  useEffect(() => {
    if (BudgetPerformanceData) {
      let tempArray = [];

      tempArray = selectedField?.map((item) => {
        return {
          ...item,
          value: BudgetPerformanceData[item?.name]
        };
      });
      setSelectedField(tempArray);
    }
  }, [BudgetPerformanceData]);

  return (
    <Box height="200" width="300">
      <BudgetSettingChart selectedField={selectedField} />
      <Box mt={2}>
        <TextField
          value={data?.monthlyBudget || null}
          onChange={(e) =>
            setData({
              ...data,

              monthlyBudget: Number(e.target.value)
            })
          }
          name="monthly_budget"
          label="Monthly Budget"
          type="number"
          error={error}
          helperText={error?.message || ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ cursor: 'pointer' }}>
                $
              </InputAdornment>
            ),
            inputProps: { min: 100 }
          }}
        />
      </Box>
      <Box mt={2}>
        <Box className="bg-grey-100 border-rounded-10" pl={2} pr={2} pt={1} pb={1}>
          <Box display="flex" alignItems="center">
            <Typography mr={2}>Split Budget Evenly</Typography>
            <CustomSwitch
              disabled
              checked={data?.budgetEvenly}
              onChange={() => setData({ ...data, budgetEvenly: !data?.budgetEvenly })}
              value="checked"
            />
          </Box>
          <Typography className="placeholder-text" mt={1}>
            Split the allocated monthly budget to every day evenly.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BudgetSetting;
