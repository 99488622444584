import React, { useState } from 'react';
import { IconButton, debounce, Typography, Tooltip } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import MUIDataTable from 'mui-datatables';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect } from 'react';
import { deleteUser, getVerifiedUserList, postInviteUser } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import DeleteModalDialog from 'ui-component/DeleteModal';
import { useCallback } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { getUserType } from 'helper/commonHelper';
import { Loader } from 'rsuite';
import { PageValue } from 'store/constant';
import CustomTableHead from 'ui-component/customTableHead';

const VerifiedUserList = () => {
  const [openDelete, setOpenDelete] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [deleteData, setDeleteData] = useState();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { verifyUserDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    getAdvertiserListWithPagination(page, rowPerPage);
    if (verifyUserDetails?.results) {
      setLoading(false);
    }
  }, []);

  const getAdvertiserListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    dispatch(getVerifiedUserList({ page, rowPerPage, search, changedColumn, direction }));
  };

  const handleOnclick = (email) => {
    const _request = {
      email: email
    };
    dispatch(postInviteUser({ _request }));
  };

  const deleteSellerInfo = () => {
    setOpenDelete(true);
    const data = verifyUserDetails?.results?.find((item) => item?.id == deleteData);
    let user_id = data?.id;
    dispatch(deleteUser({ user_id })).then(() => {
      setOpenDelete(false);
      getAdvertiserListWithPagination(page, rowPerPage, search);
    });
  };

  const toggleDeleteModal = (data) => {
    setOpenDelete(!openDelete);
    setDeleteData(data);
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const columns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Email'
          });
        }
      }
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'First Name'
          });
        }
      }
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Last Name'
          });
        }
      }
    },

    {
      name: 'user_type',
      label: 'User Type',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <Typography>{getUserType(value)}</Typography>;
        },
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'User Type'
          });
        }
      }
    },
    {
      name: 'is_active',
      label: 'Active',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Active'
          });
        },
        customBodyRender: (value) => <Typography>{value ? 'Active' : 'Inactive'}</Typography>
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Actions'
          });
        },
        customBodyRender: (value) => {
          const filter = verifyUserDetails?.results?.find((item) => item?.id == value);
          return (
            <>
              {filter?.is_invite && (
                <Tooltip title="Resend Mail">
                  <IconButton onClick={() => handleOnclick(filter?.email)}>
                    <SendIcon color="primary" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    selectableRows: 'none',
    page: page,
    jumpToPage: false,
    count: verifyUserDetails?.count || 0,
    download: false,
    pagination: true,
    serverSide: true,
    rowsPerPageOptions: PageValue,
    rowsPerPage: rowPerPage,
    draggableColumns: {
      enabled: true
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdvertiserListWithPagination(page, row, search);
    },
    onChangePage: (page) => {
      setPage(page);
      getAdvertiserListWithPagination(page, rowPerPage, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
    },
    onSearchClose: () => {
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '', changedColumn, direction);
    },
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      console.log('New Column Order: ', newColumnOrder);
      console.log('columnIndex ', columnIndex);
      console.log('newPosition ', newPosition);
    },
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    }
  };

  return (
    <MainCard title="User Invite">
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={verifyUserDetails?.results}
        columns={columns}
        options={options}
        className="custom-budget-table-no-checkbox pnl-by-item"
      />

      {openDelete && (
        <DeleteModalDialog item="user" cancelCallback={toggleDeleteModal} deleteCallback={deleteSellerInfo} open={openDelete} />
      )}
    </MainCard>
  );
};

export default VerifiedUserList;
