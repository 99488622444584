import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';
import { useAppSelector } from 'store';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, FormControl, IconButton, MenuItem, Select, Tooltip } from '@mui/material';

// third-party
import ApexCharts from 'apexcharts';
import Chart from 'react-apexcharts';

// project imports
import { formatThousandNumber, formateValue } from 'helper/commonHelper';
import { chartTypes } from 'store/constant';

//images
import DownloadIcon from 'assets/images/download-icon.svg';
import FullScreenIcon from 'assets/images/full-screen-icon.svg';

// ==============================|| DASHBOARD DEFAULT - TOTAL GROWTH BAR CHART ||============================== //

const DashboardMetricsChart = ({
  allChartMetrics,
  chartData,
  isLoading,
  chartType,
  setChartType,
  openAdPerformanceChart,
  setOpenAdPerformanceChart,
  showToolbar,
  downloadClass
}) => {
  const theme = useTheme();
  const containerRef = useRef();
  const primary200 = theme.palette.primary[200];
  const primaryDark = theme.palette.primary.dark;
  const secondaryMain = theme.palette.secondary.main;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [chartHeight, setChartHeight] = useState('300px');
  const [chartWidth, setChartWidth] = useState();
  const { userData } = useAppSelector((state) => state.authorization);

  const dashboardPermission = userData?.permission?.dashboard || {};
  const { download } = dashboardPermission;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //Setting Chart Width as per Sidebar menu toggle
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        setChartWidth(width);
        // Handle width change as needed
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  //Set the chart height dynamically
  useEffect(() => {
    if (windowWidth > 2559) {
      setChartHeight('450px');
    } else if (windowWidth > 2159) {
      setChartHeight('400px');
    } else if (windowWidth > 1919) {
      setChartHeight('350px');
    } else if (windowWidth > 1536) {
      setChartHeight('320px');
    } else {
      setChartHeight('225px');
    }
  }, [windowWidth]);

  const formateYaxis = (value) => {
    let formattedValue = formatThousandNumber(value.toFixed(2));
    return formattedValue;
  };

  const formateXaxis = (value) => {
    // let formattedValue = formatThousandNumber(value);
    return <span className="dashboard-xaxis-label">{value}</span>;
  };

  const lineChartData = {
    width: chartWidth,
    height: chartHeight,
    type: 'line',
    options: {
      colors: allChartMetrics?.map((item) => {
        return item?.color;
      }),
      chart: {
        stacked: false,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: openAdPerformanceChart ? false : `<img src="download-icon.svg" class="${downloadClass}" width="20">`
          }
        }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'left'
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        type: 'datetime',
        categories: chartData?.date,
        formatter: (value) => formateXaxis(value)
      },
      markers: {
        size: 3
      },
      stroke: {
        width: 2,
        curve: 'smooth'
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        followCursor: true,
        style: {
          markerSize: 10
        },
        y: allChartMetrics?.map((item) => {
          return {
            // formatter: function (value) {
            //   return formateValue(item.name, value);
            // }
            formatter: function (value) {
              return '<div class="arrow_box">' + '<span>' + formateValue(item.name, value) + '</span>' + '</div>';
            }
          };
        })
      },
      yaxis: allChartMetrics?.map((item, index) => {
        return {
          labels: {
            style: {
              colors: [item?.color] // Color for "Ad Sales" and "Ad Spends" axis labels
            },
            formatter: (value) => formateYaxis(value) // Dollar sign for "Ad Sales" and "Ad Spends" axis
          },
          title: {
            style: {
              color: item?.color
            }
          },
          opposite: index == 0 ? false : true
        };
      })
    },
    series: allChartMetrics?.map((item) => {
      return {
        name: item?.display_field,
        data: chartData?.[item?.name],
        yAxisIndex: 0
      };
    })
  };

  // ===========================|| DASHBOARD - TOTAL ORDER YEAR CHART ||=========================== //
  useEffect(() => {
    const newChartData = {
      ...lineChartData.options
    };

    // do not load chart when loading
    if (!isLoading) {
      ApexCharts.exec(`line-chart`, 'updateOptions', newChartData);
    }
  }, [primary200, primaryDark, secondaryMain, isLoading]);

  const handleDownloadIcon = () => {
    const downloadButton = document.querySelector(`.${downloadClass}`);
    if (downloadButton) {
      downloadButton.click();
    }
  };
  const handleFullScreenIcon = () => {
    setOpenAdPerformanceChart(true);
  };

  return (
    <Box className="dashboard-chart" ref={containerRef} sx={{ overflow: 'hidden' }}>
      {showToolbar && (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControl className="filter-select">
            <Select
              value={chartType}
              onChange={(e) => setChartType(e.target.value)}
              size="small"
              className="ad-performance-select"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {chartTypes &&
                chartTypes.map((type, key) => (
                  <MenuItem key={key} className="top-product-value-dropdown" value={type?.name}>
                    {type?.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {download && (
            <IconButton onClick={handleDownloadIcon} className="chart-icon">
              <Tooltip arrow title="Download">
                {' '}
                <img alt="download-icon" src={DownloadIcon} width="20" />
              </Tooltip>
            </IconButton>
          )}
          <IconButton onClick={handleFullScreenIcon} className="chart-icon">
            <img alt="full-screen-icon" src={FullScreenIcon} />
          </IconButton>
        </Box>
      )}
      <Chart classname="dashboard-chart" {...lineChartData} />
    </Box>
  );
};

DashboardMetricsChart.propTypes = {
  isLoading: PropTypes.bool
};

export default DashboardMetricsChart;
