import React, { useState, useEffect } from 'react';
import {
  MenuItem,
  Select,
  Button,
  TextField,
  Grid,
  Typography,
  Collapse,
  Box,
  Card,
  FormControl,
  InputLabel
} from '@mui/material';
import { useAppSelector, useAppDispatch } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import colors from 'assets/scss/_themes-vars.module.scss';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { DeleteOutline } from '@mui/icons-material';
import { setCreateRule } from 'store/slices/userDetailsSlice';

// Reusable Dropdown component
const Dropdown = ({ value, onChange, options, label }) => (
  <FormControl variant="outlined" sx={{ width: '200px' }}>
    <InputLabel id="select-label">{label}</InputLabel>
    <Select value={value} onChange={onChange} displayEmpty variant="outlined" label={label}>
      {options?.length > 0 &&
        options.map((option, index) => (
          <MenuItem key={index} value={option.name}>
            {option.label}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);

const getValueField = (condition, onChange, variableOptions) => {
  let fiieldType = '';
  let fieldOptions = [];
  if (variableOptions?.length > 0) {
    fiieldType = variableOptions.find((obj) => obj.name === condition.conditionType)?.field_type;
    fieldOptions = variableOptions.find((obj) => obj.name === condition.conditionType)?.options;
  }
  if (fiieldType === 'date') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          variant="outlined"
          value={new Date(condition.value)}
          onChange={(newValue) => onChange('value', moment(newValue).format('MM-DD-YYYY'))}
          sx={{ color: 'primary' }}
          label="Date"
          renderInput={(params) => <TextField {...params} size="small" />}
        />
      </LocalizationProvider>
    );
  } else if (fiieldType === 'time') {
    return (
      <FormControl variant="outlined" sx={{ width: '150px' }}>
        <InputLabel id="select-label">Hours</InputLabel>
        <Select
          value={condition.value ? condition.value : '00'}
          onChange={(e) => onChange('value', e.target.value)}
          displayEmpty
          variant="outlined"
          label="Hours"
          fullWidth
        >
          {Array.from({ length: 25 }, (_, i) => (
            <MenuItem key={i} value={`${i < 10 ? `0${i}` : i}`}>
              {i < 10 ? `0${i}` : i} hours
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (fiieldType === 'select' && fieldOptions?.length > 0) {
    return (
      <FormControl variant="outlined" sx={{ width: '150px' }}>
        <InputLabel id="select-label">{condition.conditionType}</InputLabel>
        <Select
          value={condition.value ? condition.value : fieldOptions[0]}
          onChange={(e) => onChange('value', e.target.value)}
          displayEmpty
          variant="outlined"
          label={condition.conditionType}
        >
          {fieldOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else if (fiieldType === 'numeric') {
    return (
      <TextField
        variant="outlined"
        type="number"
        min={0}
        value={condition.value}
        onChange={(e) => {
          const newValue = e.target.value;
          // Allow only empty input or positive numbers
          if (newValue === '' || (Number(newValue) >= 0 && !isNaN(newValue))) {
            onChange('value', newValue);
          }
        }}
      />
    );
  } else {
    return <TextField variant="outlined" value={condition.value} onChange={(e) => onChange('value', e.target.value)} />;
  }
};

// Condition Row Component
const ConditionRow = ({ condition, onChange, onRemove, subConditionComponent, variableOptions, operatorOptions, showDelete }) => {
  return (
    <Box className="conditions-wrapper">
      <Box className="condition-fields">
        <Box>
          <Dropdown
            value={condition.conditionType}
            onChange={(e) => onChange('conditionType', e.target.value)}
            options={variableOptions}
            label="Condition Type"
          />
        </Box>

        <Box>
          <Dropdown
            value={condition.operator}
            onChange={(e) => onChange('operator', e.target.value)}
            options={operatorOptions}
            label="Operator"
          />
        </Box>

        <Box>{getValueField(condition, onChange, variableOptions)}</Box>

        {showDelete && (
          <Box display="flex" alignItems="center">
            <DeleteOutline sx={{ color: colors?.errorDark, cursor: 'pointer' }} onClick={onRemove} />
          </Box>
        )}
      </Box>
      {subConditionComponent}
    </Box>
  );
};

// SubCondition Row Component
const SubConditionRow = ({ subCondition, onChange, onRemove, variableOptions, operatorOptions, showDelete }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" gap="10px" style={{ marginBottom: '10px' }}>
      <Box>
        <Dropdown
          value={subCondition.conditionType}
          onChange={(e) => onChange('conditionType', e.target.value)}
          options={variableOptions}
          label="Sub-Condition Type"
        />
      </Box>
      <Box>
        <Dropdown
          value={subCondition.operator}
          onChange={(e) => onChange('operator', e.target.value)}
          options={operatorOptions}
          label="Operator"
        />
      </Box>

      <Box>{getValueField(subCondition, onChange, variableOptions)}</Box>

      <Box>
        {showDelete && (
          <Box display="flex" alignItems="center">
            <DeleteOutline sx={{ color: colors?.errorDark, cursor: 'pointer' }} onClick={onRemove} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

// Action Row Component
const ActionRow = ({ action, onChange, showDelete, onRemove, actionOptions }) => (
  <Grid container spacing={2} alignItems="center" style={{ marginBottom: '10px' }}>
    <Grid item>
      <Dropdown
        value={action.actionType}
        onChange={(e) => onChange('actionType', e.target.value)}
        options={actionOptions}
        label="Action Type"
      />
    </Grid>

    {showDelete && (
      <Grid item>
        <DeleteOutline sx={{ color: colors?.errorDark, cursor: 'pointer' }} onClick={onRemove} />
      </Grid>
    )}
  </Grid>
);

const ConditionsActionsDemo = (props) => {
  const dispatch = useAppDispatch();
  const { setFinalObject, errors, setErrors } = props;
  const { ruleVariables } = useAppSelector((state) => state?.userDetailsSlice);
  const [data, setData] = useState({});
  const [siblingConditions, setSiblingConditions] = useState([]);
  const [conditions, setConditions] = useState([
    {
      conditionType: data?.variables?.length > 0 ? data.variables[0].name : '',
      operator: data?.variable_type_operators?.numeric?.length > 0 ? data.variable_type_operators.numeric[0].name : '',
      value: '',
      logicType: 'All',
      subConditions: []
    }
  ]);
  const [actions, setActions] = useState([
    {
      actionType: data?.actions?.length > 0 ? data.actions[0].name : ''
    }
  ]);
  const [logicType, setLogicType] = useState('All');

  useEffect(() => {
    setData(ruleVariables);
    if (ruleVariables?.variables?.length > 0) {
      // Update conditions and actions with the correct default values after data is fetched
      setConditions([
        {
          conditionType: ruleVariables?.variables.length > 0 ? ruleVariables?.variables[0].name : '',
          operator:
            ruleVariables?.variable_type_operators.numeric.length > 0
              ? ruleVariables?.variable_type_operators.numeric[0].name
              : '',
          value: '',
          logicType: 'All',
          subConditions: []
        }
      ]);
      setActions([
        {
          actionType: ruleVariables?.actions.length > 0 ? ruleVariables?.actions[0].name : ''
        }
      ]);
    }
  }, [ruleVariables]);

  const handleAddCondition = () => {
    setConditions([
      ...conditions,
      {
        conditionType: data?.variables?.length > 0 ? data.variables[0].name : '',
        operator: data?.variable_type_operators?.numeric?.length > 0 ? data.variable_type_operators.numeric[0].name : '',
        value: '',
        logicType: 'All',
        subConditions: []
      }
    ]);
  };

  const handleAddSiblingCondition = () => {
    const newSibling = {
      logicType: 'All', // Default to 'All' logic
      conditions: [
        {
          conditionType: data?.variables?.length > 0 ? data.variables[0].name : '',
          operator: data?.variable_type_operators?.numeric?.length > 0 ? data.variable_type_operators.numeric[0].name : '',
          value: '',
          logicType: 'All',
          subConditions: []
        }
      ]
    };

    setSiblingConditions([...siblingConditions, newSibling]);
  };

  const handleAddConditionToSibling = (index) => {
    const newSiblingConditions = [...siblingConditions];
    newSiblingConditions[index].conditions.push({
      conditionType: data?.variables?.length > 0 ? data.variables[0].name : '',
      operator: data?.variable_type_operators?.numeric?.length > 0 ? data.variable_type_operators.numeric[0].name : '',
      value: '',
      subConditions: []
    });

    setSiblingConditions(newSiblingConditions);
  };

  const handleConditionChange = (index, field, value) => {
    const newConditions = [...conditions];
    newConditions[index][field] = value;
    if (field === 'conditionType') {
      const fieldType = data?.variables.find((Obj) => Obj.name === value)?.field_type;
      //Set Initial Value for Days and Time dropdown
      const initialValue = value === 'time' ? '00' : value === 'days' ? 'Monday' : '';
      newConditions[index]['value'] = initialValue;
      newConditions[index]['operator'] = data?.variable_type_operators?.[fieldType]?.[0]?.name;
    }
    setConditions(newConditions);
  };

  const handleSubConditionChange = (conditionIndex, subConditionIndex, field, value) => {
    const newConditions = [...conditions];
    newConditions[conditionIndex].subConditions[subConditionIndex][field] = value;

    if (field === 'conditionType') {
      const fieldType = data?.variables.find((Obj) => Obj.name === value)?.field_type;

      newConditions[conditionIndex].subConditions[subConditionIndex]['value'] = '';
      newConditions[conditionIndex].subConditions[subConditionIndex]['operator'] =
        data?.variable_type_operators?.[fieldType]?.[0]?.name;
    }
    setConditions(newConditions);
  };

  // const handleAddSubCondition = (index) => {
  //   const newConditions = [...conditions];

  //   newConditions[index].subConditions.push({
  //     conditionType: data.variables.length > 0 ? data.variables[0].name : '',
  //     operator: data.variable_type_operators.numeric.length > 0 ? data.variable_type_operators.numeric[0].name : '',
  //     value: '',
  //     logicType: 'All',
  //     subConditions: []
  //   });
  //   setConditions(newConditions);
  // };

  const handleRemoveCondition = (index) => {
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const handleRemoveSubCondition = (conditionIndex, subConditionIndex) => {
    const newConditions = [...conditions];
    newConditions[conditionIndex].subConditions.splice(subConditionIndex, 1);
    setConditions(newConditions);
  };

  const handleAddAction = () => {
    setActions([...actions, { actionType: data.actions.length > 0 ? data.actions[0].name : '' }]);
  };

  const handleActionChange = (index, field, value) => {
    const newActions = [...actions];
    newActions[index][field] = value;
    setActions(newActions);
  };

  const handleRemoveAction = (index) => {
    setActions(actions.filter((_, i) => i !== index));
  };

  const getFormatedValue = (condition) => {
    let fiieldType = '';
    if (data?.variables?.length > 0) {
      fiieldType = data?.variables.find((obj) => obj.name === condition.conditionType)?.field_type;
    }

    if (fiieldType === 'numeric') {
      return parseFloat(condition.value);
    } else {
      return condition.value;
    }
  };

  function validateConditions(conditions) {
    setErrors(false);
    conditions.forEach((condition) => {
      let fiieldType = null;
      if (data?.variables?.length > 0) {
        fiieldType = data?.variables.find((obj) => obj.name === condition.name)?.field_type;
      }
      if (fiieldType === 'numeric') {
        // Check if 'value' is null, empty, or NaN
        if (condition.value === null || condition.value === '' || Number.isNaN(condition.value)) {
          setErrors(true);
          throw new Error(`Condition value is missing, empty, or NaN. Condition: ${JSON.stringify(condition)}`);
        }
      } else {
        // Check if 'value' is null, empty, or NaN
        if (condition.value === null || condition.value === '') {
          setErrors(true);
          throw new Error(`Condition value is missing, empty, or NaN. Condition: ${JSON.stringify(condition)}`);
        }
      }

      // If the condition has a parent block ('all' or 'any'), validate its children recursively
      if (condition.all || condition.any) {
        // Recursively validate the nested 'all' and 'any' blocks
        if (condition.all) validateConditions(condition.all); // Check the 'all' conditions
        if (condition.any) validateConditions(condition.any); // Check the 'any' conditions
      }
    });
  }

  const buildFinalObject = () => {
    const processConditions = (conds) => {
      return conds.flatMap((condition) => {
        const mainConditionObject = {
          name: condition.conditionType,
          operator: condition.operator,
          value: getFormatedValue(condition)
        };

        if (condition.subConditions.length > 0) {
          const subConditionObject = {
            [condition.logicType.toLowerCase()]: processConditions(condition.subConditions)
          };
          return [mainConditionObject, subConditionObject];
        }
        return [mainConditionObject];
      });
    };

    let conditionsArray = processConditions(conditions);

    const siblingConditionsArray = siblingConditions.map((siblingCondition) => ({
      [siblingCondition.logicType.toLowerCase()]: processConditions(siblingCondition.conditions)
    }));
    conditionsArray = conditionsArray.concat(siblingConditionsArray);

    try {
      validateConditions(Object.values(conditionsArray)); // Get the first (and only) property of the root ('all' or 'any')
      console.log('Validation passed! All conditions are valid.');
    } catch (error) {
      console.error('Validation failed:', error.message);
    }

    const actionsArray = actions.map((action) => ({
      name: action.actionType,
      params: action?.params
    }));
    const newObject = {
      conditions: { [logicType.toLowerCase()]: conditionsArray },
      actions: actionsArray
    };
    setFinalObject(newObject);
  };

  useEffect(() => {
    if (conditions) {
      buildFinalObject();
      dispatch(setCreateRule({}));
    }
  }, [conditions, siblingConditions, actions]);

  const handleRemoveSiblingCondition = (siblingIndex) => {
    const newSiblingConditions = [...siblingConditions];
    newSiblingConditions.splice(siblingIndex, 1); // Remove the sibling condition
    setSiblingConditions(newSiblingConditions);
  };

  return (
    <MainCard>
      <Box mt={2} className="ppc-rules">
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="caption">
              <strong>Note:</strong> All time-related fields are in the PST timezone.
            </Typography>
          </Grid>
          <Grid ml={2} md={12}>
            {errors && (
              <Typography variant="caption" className="error-text">
                <strong>Error:</strong> Please select related values in your rule conditions.
              </Typography>
            )}
          </Grid>
          <Grid item md={8}>
            <Box className="all-condition-wrapper">
              <Box className="condition-top">
                <Dropdown
                  value={logicType}
                  onChange={(e) => setLogicType(e.target.value)}
                  options={[
                    { name: 'All', label: 'All' },
                    { name: 'Any', label: 'Any' }
                  ]}
                  label="Logic Type"
                />
                <Box className="condition-add-buttons">
                  <Button variant="contained" color="primary" onClick={handleAddCondition}>
                    Add Condition
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => handleAddSiblingCondition()}>
                    Add Sub Condition
                  </Button>
                </Box>
              </Box>
              <Card>
                {conditions.map((condition, index) => (
                  <ConditionRow
                    key={index}
                    condition={condition}
                    onChange={(field, value) => handleConditionChange(index, field, value)}
                    onRemove={() => handleRemoveCondition(index)}
                    // onAddSubCondition={() => handleAddSubCondition(index)}
                    variableOptions={data?.variables}
                    operatorOptions={
                      data?.variable_type_operators &&
                      data?.variables &&
                      data?.variable_type_operators[
                        data?.variables?.find((obj) => obj.name === condition.conditionType)?.field_type
                      ]
                    }
                    showDelete={conditions?.length + siblingConditions?.length > 1}
                    subConditionComponent={
                      condition.subConditions.length > 0 && (
                        <Collapse in={true}>
                          <Box style={{ marginLeft: '30px', marginTop: '10px' }}>
                            <Box style={{ marginBottom: '8px' }}>
                              <Dropdown
                                value={condition.logicType}
                                onChange={(e) => handleConditionChange(index, 'logicType', e.target.value)}
                                options={[
                                  { name: 'All', label: 'All' },
                                  { name: 'Any', label: 'Any' }
                                ]}
                                label="Logic Type"
                              />
                            </Box>

                            {condition.subConditions.map((subCondition, subIndex) => (
                              <SubConditionRow
                                key={subIndex}
                                subCondition={subCondition}
                                onChange={(field, value) => handleSubConditionChange(index, subIndex, field, value)}
                                onRemove={() => handleRemoveSubCondition(index, subIndex)}
                                variableOptions={data?.variables}
                                // operatorOptions={data?.variable_type_operators?.numeric}
                                operatorOptions={
                                  data?.variable_type_operators[
                                    data?.variables.find((obj) => obj.name === subCondition.conditionType)?.field_type
                                  ]
                                }
                                showDelete={condition?.subConditions?.length > 1}
                              />
                            ))}
                          </Box>
                        </Collapse>
                      )
                    }
                  />
                ))}

                {/* Render Sibling Conditions */}
                {siblingConditions.map((siblingCondition, siblingIndex) => (
                  <Card className="sub-condition-wrapper" key={siblingIndex}>
                    <Box className="sub-condition-top">
                      {/* Logic type dropdown */}
                      <Dropdown
                        value={siblingCondition.logicType}
                        onChange={(e) => {
                          const newSiblingConditions = [...siblingConditions];
                          newSiblingConditions[siblingIndex].logicType = e.target.value;
                          setSiblingConditions(newSiblingConditions);
                        }}
                        options={[
                          { name: 'All', label: 'All' },
                          { name: 'Any', label: 'Any' }
                        ]}
                        label="Logic Type"
                      />

                      <Box className="sub-condition-add-delete-buttons">
                        <Button variant="contained" color="primary" onClick={() => handleAddConditionToSibling(siblingIndex)}>
                          Add Condition
                        </Button>
                        {conditions?.length + siblingConditions?.length > 1 && (
                          <DeleteOutline
                            sx={{ color: colors?.errorDark, cursor: 'pointer' }}
                            onClick={() => handleRemoveSiblingCondition(siblingIndex)}
                          />
                        )}
                      </Box>
                    </Box>

                    {/* Render child conditions inside each sibling condition */}
                    {siblingCondition.conditions.map((condition, conditionIndex) => (
                      <ConditionRow
                        key={conditionIndex}
                        condition={condition}
                        onChange={(field, value) => {
                          const newSiblingConditions = [...siblingConditions];
                          newSiblingConditions[siblingIndex].conditions[conditionIndex][field] = value;

                          if (field === 'conditionType') {
                            const fieldType = data?.variables.find((Obj) => Obj.name === value)?.field_type;
                            //Set Initial Value for Days and Time dropdown
                            const initialValue = value === 'time' ? '00' : value === 'days' ? 'Monday' : '';
                            newSiblingConditions[siblingIndex].conditions[conditionIndex]['value'] = initialValue;
                            newSiblingConditions[siblingIndex].conditions[conditionIndex]['operator'] =
                              data?.variable_type_operators?.[fieldType]?.[0]?.name;
                          }
                          setSiblingConditions(newSiblingConditions);
                        }}
                        onRemove={() => {
                          const newSiblingConditions = [...siblingConditions];
                          newSiblingConditions[siblingIndex].conditions.splice(conditionIndex, 1);
                          setSiblingConditions(newSiblingConditions);
                        }}
                        variableOptions={data?.variables}
                        operatorOptions={
                          data?.variable_type_operators &&
                          data?.variables &&
                          data?.variable_type_operators[
                            data?.variables?.find((obj) => obj.name === condition.conditionType)?.field_type
                          ]
                        }
                        showDelete={siblingCondition?.conditions?.length > 1}
                      />
                    ))}
                  </Card>
                ))}
              </Card>
            </Box>
            <Typography variant="h3" mt={3} mb={3}>
              Then perform these actions...
            </Typography>
            <Box className="all-actions-wrapper">
              {actions.map((action, index) => (
                <ActionRow
                  key={index}
                  action={action}
                  showDelete={actions?.length > 1}
                  onChange={(field, value) => handleActionChange(index, field, value)}
                  onRemove={() => handleRemoveAction(index)}
                  actionOptions={data?.actions}
                />
              ))}

              <Button variant="contained" color="primary" onClick={handleAddAction}>
                Add Action
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
};

export default ConditionsActionsDemo;
