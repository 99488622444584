import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from 'store/slices/dataLoadingSlice';
import { notificationSuccess, notificationFail } from 'store/slices/notificationSlice';
import { setUserData } from 'store/slices/authSlice';
import Messages from '../../utils/messages';
import apiClient from '../../utils/apiClient';

//UN-USED OLD LOGIN SCREEN API
export const loginUser = createAsyncThunk('loginUser', async (_request, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const { email, password, navigate } = _request;
    const response = await apiClient().post(`/accounts/login/`, { email, password });
    dispatch(setLoading(false));
    if (response?.data?.data && response?.data?.data?.tokens) {
      // navigate('/list');
      navigate('/dashboard');

      const getUserPermission = response?.data?.data?.permissions;

      //Prepare User Info object with user permission
      const userInfo = {
        ...response?.data?.data?.tokens,
        user_type: response?.data?.data?.user_type,
        email: response?.data?.data?.email,
        permission: getUserPermission,
        first_name: response?.data?.data?.first_name,
        last_name: response?.data?.data?.last_name,
        tenant_id: response?.data?.data?.company_id,
        is_first_time_login: response?.data?.data?.is_first_time_login, //My code
        display_module_fe: response?.data?.data?.display_module_fe //My code
      };

      localStorage.setItem('user_data', JSON.stringify(userInfo));
      localStorage.setItem('user_profile', JSON.stringify(response?.data?.data?.profile));
      localStorage.setItem('isSuperAdmin', response?.data?.data.is_belongs_to_sa_section);

      dispatch(setUserData(userInfo));
      dispatch(notificationSuccess(Messages.SUCCESS.LOGIN));
    } else {
      dispatch(notificationFail('Failed to login using this credentials' || Messages.ERROR.LOGIN));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.LOGIN));
  }
});

// NEW LOG IN API FOR BOTH SIGN IN SCREEN AND VERIFY USER EMAIL SCREEN
export const loginApi = createAsyncThunk('loginUser', async (_request, { dispatch }) => {
  const { email, password, otpb64, useridb64, display_module_fe, navigate, setLoading, SetBeError } = _request;
  try {
    localStorage.clear();
    setLoading(true);
    const requestBody = {};
    if (email) {
      requestBody.email = email;
    }
    if (password) {
      requestBody.password = password;
    }
    if (otpb64) {
      requestBody.otpb64 = otpb64;
    }
    if (useridb64) {
      requestBody.useridb64 = useridb64;
    }
    if (display_module_fe) {
      requestBody.display_module_fe = display_module_fe;
    }
    const response = await apiClient().post(`/accounts/login/`, requestBody);
    setLoading(false);
    if (response?.data?.data && response?.data?.data?.tokens) {
      // navigate('/list');
      navigate('/dashboard');
      SetBeError('');
      const getUserPermission = response?.data?.data?.permissions;

      //Prepare User Info object with user permission
      const userInfo = {
        ...response?.data?.data?.tokens,
        user_type: response?.data?.data?.user_type,
        email: response?.data?.data?.email,
        permission: getUserPermission,
        first_name: response?.data?.data?.first_name,
        last_name: response?.data?.data?.last_name,
        company_name: response?.data?.data?.company_name,
        tenant_id: response?.data?.data?.company_id,
        store_id: response?.data?.data?.store_id?.[0] || '', //For creating Advertiser at Verification process
        is_first_time_login: response?.data?.data?.is_first_time_login, //My code
        display_module_fe: response?.data?.data?.display_module_fe, //My code for redirect after login
        last_accessed_advertiser: response?.data?.data?.last_accessed_advertiser //For auto select advertiser after login
      };

      localStorage.setItem('user_data', JSON.stringify(userInfo));
      localStorage.setItem('user_profile', JSON.stringify(response?.data?.data?.profile));
      localStorage.setItem('isSuperAdmin', response?.data?.data.is_belongs_to_sa_section);
      localStorage.setItem('selectedProfiles', response?.data?.data?.last_accessed_advertiser);

      dispatch(setUserData(userInfo));
      // dispatch(notificationSuccess(Messages.SUCCESS.LOGIN));
    } else {
      // dispatch(notificationFail('Failed to login using this credentials' || Messages.ERROR.LOGIN));
      SetBeError('Failed to login using this credentials' || Messages.ERROR.LOGIN);
    }
  } catch (error) {
    setLoading(false);
    // dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.LOGIN));
    SetBeError(error?.response?.data?.message || Messages.ERROR.LOGIN);
  }
});

export const registerUser = createAsyncThunk('registerUser', async (_request) => {
  const { email, company_name, company_email, password, first_name, last_name, setLoading, setShowNext, SetBeError } = _request;
  try {
    setLoading(true);
    const response = await apiClient().post(`/accounts/register/`, {
      first_name: first_name,
      last_name: last_name,
      email: email,
      company_name: company_name,
      company_email: company_email,
      password: password
    });
    setLoading(false);
    if (response?.data) {
      setShowNext(false);
      SetBeError('');
    } else {
      // dispatch(notificationFail(response?.data?.message || Messages.ERROR.REGISTER));
      SetBeError(response?.data?.message || Messages.ERROR.REGISTER);
    }
  } catch (error) {
    setLoading(false);
    // dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.REGISTER));
    SetBeError(error?.response?.data?.message || Messages.ERROR.REGISTER);
  }
});

export const verifyUser = createAsyncThunk('verifyUser', async (_request, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const { useridb64, otpb64, navigate } = _request;

    const response = await apiClient().post(`accounts/verify-email/`, {
      useridb64: useridb64,
      otpb64: otpb64
    });

    dispatch(setLoading(false));
    navigate('/login');
    if (response?.data?.data && response?.data?.data?.tokens) {
      dispatch(notificationSuccess(response?.data?.message));
    } else {
      dispatch(notificationFail('Failed to login using this credentials' || Messages.ERROR.LOGIN));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.REGISTER));
  }
});

export const forgetPassword = createAsyncThunk('forgetPassword', async (_request) => {
  const { setLoading, SetBeError, setShowNext } = _request;

  try {
    setLoading(true);

    const response = await apiClient().post(`accounts/forget-password/`, { email: _request.email });
    setLoading(false);
    if (response?.data) {
      // dispatch(notificationSuccess(response?.data?.message));
      SetBeError('');
      setShowNext(false);
      // navigate('/login');
    } else {
      // dispatch(notificationFail(Messages.ERROR.RESETFAIL));
      SetBeError(Messages.ERROR.RESETFAIL);
    }
  } catch (error) {
    setLoading(false);
    // dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.DEFAULT));
    SetBeError(error?.response?.data?.message || Messages.ERROR.DEFAULT);
  }
});

export const accountPasswordUpdate = createAsyncThunk('accountPasswordUpdate', async ({ _request, navigate }, { dispatch }) => {
  try {
    dispatch(setLoading(true));

    const response = await apiClient().post(`accounts/reset-forgot-password/`, _request);
    dispatch(setLoading(false));
    if (response?.data) {
      dispatch(notificationSuccess(response?.data?.message));
      navigate('/login');
    } else {
      dispatch(notificationFail(Messages.ERROR.DEFAULT));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.DEFAULT));
  }
});

export const accountPasswordUpdateNew = createAsyncThunk(
  'accountPasswordUpdate',
  async ({ requestData, navigate, setLoading, SetBeError }) => {
    console.log(' _request, navigate, setLoading, SetBeError ', requestData, navigate, setLoading, SetBeError);
    try {
      setLoading(true);
      const response = await apiClient().post(`accounts/reset-forgot-password/`, requestData);
      setLoading(false);
      if (response?.data) {
        SetBeError('');
        navigate('/login');
      } else {
        SetBeError(Messages.ERROR.DEFAULT);
      }
    } catch (error) {
      setLoading(false);
      SetBeError(error?.response?.data?.message || Messages.ERROR.DEFAULT);
    }
  }
);

export const accountPasswordCreate = createAsyncThunk('accountPasswordCreate', async ({ _request, navigate }, { dispatch }) => {
  try {
    dispatch(setLoading(true));

    const response = await apiClient().post(`accounts/create-password/`, _request);
    dispatch(setLoading(false));
    if (response?.data) {
      dispatch(notificationSuccess(response?.data?.message));
      navigate('/login');
    } else {
      dispatch(notificationFail(Messages.ERROR.DEFAULT));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(notificationFail(error?.response?.data?.message || Messages.ERROR.DEFAULT));
  }
});
