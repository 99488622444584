import { Navigate, useMatch } from 'react-router-dom';
import { useAppSelector } from 'store';

function PublicRoute(props) {
  const { children } = props;
  const userAuthData = useAppSelector((state) => state.authorization);
  const match = useMatch('/dashboard-overview/:token')?.pattern?.path || '';
  const match1 = useMatch('/verify-email/:useridb64/:otpb64')?.pattern?.path || '';

  //Redirect to specific screen If any part of Verification Process is remaining

  return userAuthData?.userData?.access && match === '' && match1 === '' ? <Navigate to="/dashboard" /> : children;
}

export default PublicRoute;
