import { useMemo } from 'react';
import { useAppSelector, useAppDispatch } from 'store';
import { Box, Typography } from '@mui/material';
import { GetBIDashboard } from 'store/thunk/userThunk';

const BIReport = () => {
  const dispatch = useAppDispatch();
  const { commonAdvertiser, commonDateRange, BIDashboard } = useAppSelector((state) => state?.userDetailsSlice);

  useMemo(() => {
    if (commonAdvertiser?.length > 0 && commonDateRange?.startDate && commonDateRange?.endDate) {
      const params = {
        client: commonAdvertiser,
        date_range: `${commonDateRange?.startDate}~${commonDateRange?.endDate}`
      };
      dispatch(GetBIDashboard({ _request: params }));
    }
  }, [commonAdvertiser, commonDateRange]);

  return (
    <Box className="BI-dashboard-card">
      <Typography variant="h2" className="Overview-title">
        BI Dashboard
      </Typography>
      {BIDashboard && (
        <iframe
          title="BI Dashboard"
          src={BIDashboard}
          style={{ border: 'none', height: 'calc(100vh - 200px)', width: '100%' }}
        ></iframe>
      )}
    </Box>
  );
};

export default BIReport;
