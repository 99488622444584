import { useEffect, useState } from 'react';
// import { useAppSelector } from "store";
import { useDispatch } from 'react-redux';
import { getSelectedFilterList, postSelectedFilterDataCampaign } from 'store/thunk/userThunk';
import { Dialog, DialogTitle, DialogContent, FormControlLabel, Button, Checkbox, DialogActions, Box } from '@mui/material';
import { setCommonLoader } from 'store/slices/userDetailsSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const CustomColumnSelector = (props) => {
  const {
    open,
    handleClose,
    tableName,
    columns,
    defaultSelected,
    actionColumnName = '',
    setColumnOrder = () => {},
    columnOrder = []
  } = props;
  const dispatch = useDispatch();
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [totalStickyColumns, setTotalStickyColumns] = useState(0);
  const [reorderColumnIndex, setReorderColumnIndex] = useState([]);

  useEffect(() => {
    if (defaultSelected) {
      let tempColumns = JSON.parse(JSON.stringify(defaultSelected));
      tempColumns = defaultSelected?.map((column, index) => {
        return {
          id: index.toString(), //for drag and drop, i use unique id
          name: column?.name,
          display: column?.options?.display ?? true,
          label: column?.label || column?.name,
          default: column?.default || false,
          isDragDisabled: column?.default || false //For restrict drag and drop
        };
      });
      tempColumns = tempColumns.filter((obj) => obj.name !== actionColumnName);

      //Reorder Columns as per columnOrder Arr
      if (columnOrder?.length) {
        tempColumns = columnOrder.map((index) => tempColumns[index]);
      }

      const totalStickyColumns = tempColumns?.filter((l) => l?.default === true)?.length;
      setTotalStickyColumns(totalStickyColumns); //user must not drag and drop before sticky column
      setSelectedColumns(tempColumns);
    }
  }, [defaultSelected]);

  //Show/Hide Columns
  const handleCheckboxChange = (index) => {
    setSelectedColumns((prevColumns) => {
      let updatedColumns = [...prevColumns];
      updatedColumns[index] = { ...updatedColumns[index], display: !updatedColumns[index]?.display };
      return updatedColumns;
    });
  };

  const handleSave = () => {
    const tempSaveColumns = [...columns];
    selectedColumns?.map((col) => {
      const selectedIndex = tempSaveColumns.findIndex((obj) => obj.name === col.name);
      tempSaveColumns[selectedIndex] = { ...tempSaveColumns[selectedIndex], display: col?.display };
    });

    const _request = {
      [tableName]: tempSaveColumns
    };
    dispatch(setCommonLoader(true));
    dispatch(postSelectedFilterDataCampaign(_request)).then(() => {
      dispatch(getSelectedFilterList()).then(() => {
        dispatch(setCommonLoader(false));
        handleClose();

        //if user change order of columns then update it in the table
        if (reorderColumnIndex?.length) {
          setColumnOrder(reorderColumnIndex);
        }
      });
    });
  };

  //Handle Drag and Drop Column
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;
    if (destination?.index < totalStickyColumns) return; //column must not be dragged and dropped before sticky column

    const reorderedItems = Array.from(selectedColumns);
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);
    setSelectedColumns(reorderedItems);

    const reorderedIndexArray = reorderedItems.map((col) => Number(col.id));
    setReorderColumnIndex(reorderedIndexArray); //Update Column Order
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select/Reorder Columns</DialogTitle>
      <DialogContent>
        {/* <Box display="flex">
          <Box display="flex" flexDirection="column">
            {selectedColumns.slice(0, Math.ceil(selectedColumns.length / 2)).map((column, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    disabled={column?.default || false}
                    checked={column?.display || false}
                    onChange={() => handleCheckboxChange(index)}
                    color="primary"
                  />
                }
                label={column.label}
                disabled={column?.default || false}
              />
            ))}
          </Box>
          <Box display="flex" flexDirection="column">
            {selectedColumns.slice(Math.ceil(selectedColumns.length / 2)).map((column, index) => (
              <FormControlLabel
                key={Math.ceil(selectedColumns.length / 2) + index}
                control={
                  <Checkbox
                    disabled={column?.default || false}
                    checked={column?.display || false}
                    onChange={() => handleCheckboxChange(Math.ceil(selectedColumns.length / 2) + index)}
                    color="primary"
                  />
                }
                label={column.label}
                disabled={column?.default || false}
              />
            ))}
          </Box>
        </Box> */}
        <Box display="flex">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="droppable-checkboxes">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {selectedColumns.map((column, index) => (
                    <Draggable
                      key={column?.id}
                      draggableId={column?.id}
                      index={index}
                      isDragDisabled={column?.isDragDisabled} // Disable drag conditionally
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            padding: '8px',
                            margin: '4px 0',
                            backgroundColor: '#f0f0f0',
                            ...provided.draggableProps.style
                          }}
                        >
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                disabled={column?.default || false}
                                checked={column?.display || false}
                                onChange={() => handleCheckboxChange(index)}
                                color="primary"
                              />
                            }
                            label={column?.label}
                            disabled={column?.default || false}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomColumnSelector;
