import MUIDataTable from 'mui-datatables';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
// import { Loader } from 'rsuite';
import MainCard from 'ui-component/cards/MainCard';
import { formatData, useFormattedDataOnce } from 'utils/utilsFunction';
import CustomTableHead from 'ui-component/customTableHead';

const LifeTimePerformance = (props) => {
  const { lifeTimeReport } = props;
  const [dataTable, setData] = useState([]);
  useEffect(() => {
    if (lifeTimeReport) {
      setData([lifeTimeReport]);
    }
  }, [lifeTimeReport]);

  // const arrayOfValues = Object.values(lifeTimeReport);

  // console.log(lifeTimeReport,"ffff")
  // const displayDataMain = React.useMemo(() => [{
  //   impressions: lifeTimeReport?.impressions,
  //   clicks: lifeTimeReport?.clicks,
  //   ctr: lifeTimeReport?.ctr,
  //   unitsold: lifeTimeReport?.unitsold,
  //   cvr_orders: lifeTimeReport?.cvr_orders,
  //   average_cpc: lifeTimeReport?.average_cpc,
  //   ad_spend: lifeTimeReport?.ad_spend,
  //   ad_sales: lifeTimeReport?.ad_sales,
  //   roas: lifeTimeReport?.roas,
  //   acos: lifeTimeReport?.acos,
  // }], [lifeTimeReport]);

  const displayData = useFormattedDataOnce(dataTable, formatData);

  //   columnMeta,
  //   handleToggleColumn,
  //   sortOrder,
  //   columnName,
  //   columnLabel_1,
  //   columnLabel_2 = '',
  //   customCss = {},
  //   isLabelAlignRight = false,
  //   isParentLabelAlignRight = false
  // }) => {
  //   return (
  //     <TableCell
  //       sx={{
  //         position: 'sticky',
  //         top: '0px',
  //         zIndex: 1,
  //         cursor: columnMeta?.sort ? 'pointer' : 'text',
  //         ...customCss
  //       }}
  //       onClick={columnMeta?.sort ? () => handleToggleColumn(columnMeta?.index) : undefined}
  //       key={columnMeta?.index}
  //     >
  //       <Box display="flex" alignItems="center" justifyContent={isParentLabelAlignRight ? 'flex-end' : 'flex-start'} gap={'5px'}>
  //         <Box
  //           display="flex"
  //           alignItems={isLabelAlignRight ? 'flex-end' : 'flex-start'}
  //           flexDirection="column"
  //           justifyContent="flex-start"
  //         >
  //           <Box className="clr3 fw700 ffInter">{columnLabel_1}</Box>
  //           {columnLabel_2 && <Box className="clr3 fw700 ffInter">{columnLabel_2}</Box>}
  //         </Box>
  //         {!__.isEmpty(sortOrder) && (
  //           <Box>
  //             {sortOrder?.direction === 'asc' && sortOrder?.name === columnName ? <img src={upIcon} alt="icon" /> : null}
  //             {sortOrder?.direction === 'desc' && sortOrder?.name === columnName ? <img src={downIcon} alt="icon" /> : null}
  //           </Box>
  //         )}
  //       </Box>
  //     </TableCell>
  //   );
  // };

  const columns = [
    {
      name: 'ad_spend',
      label: 'Spend',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Spend'
          });
        }
      }
    },
    {
      name: 'ad_sales',
      label: 'Sales',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Sales'
          });
        }
      }
    },
    {
      name: 'unitsold',
      label: 'Units',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Units'
          });
        }
      }
    },
    {
      name: 'asp',
      label: 'ASP (Sales/Units)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'ASP (Sales/Units)'
          });
        }
      }
    },
    {
      name: 'impressions',
      label: 'Impressions',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Impressions'
          });
        }
      }
    },
    {
      name: 'clicks',
      label: 'Clicks',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Clicks'
          });
        }
      }
    },
    {
      name: '0',
      label: 'Orders',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Orders'
          });
        }
      }
    },
    {
      name: 'ctr',
      label: 'CTR',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CTR'
          });
        }
      }
    },

    {
      name: 'cvr_orders',
      label: 'CVR (Orders)',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'CVR (Orders)'
          });
        }
      }
    },
    {
      name: 'roas',
      label: 'RoAS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'RoAS'
          });
        }
      }
    },
    {
      name: 'acos',
      label: 'ACoS',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'ACoS'
          });
        }
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    // selectableRows: 'none',
    // page: 1,
    // count: displayData?.count || 0,
    jumpToPage: false,
    download: false,
    pagination: false,
    serverSide: false,
    selectableRows: 'none',
    search: false,
    viewColumns: false,
    draggableColumns: {
      enabled: true
    },
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      console.log('New Column Order: ', newColumnOrder);
      console.log('columnIndex ', columnIndex);
      console.log('newPosition ', newPosition);
    }
    // textLabels: {
    //   // body: {
    //   //   noMatch: props.lifeTimeReport === "" ?
    //   //     <Loader /> :
    //   //     'Sorry, there is no matching data to display',
    //   // },
    // },
    // rowsPerPage: rowPerPage,
    // rowsPerPageOptions: [10, 20, 50],
  };

  return (
    <MainCard titleInner="Life Time Performance">
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={displayData}
        columns={columns}
        options={options}
        className="pnl-by-item"
      />
    </MainCard>
  );
};

export default LifeTimePerformance;
