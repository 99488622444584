import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { useState, useEffect } from 'react';
import { drawerWidth } from 'store/constant';
import { useLocation } from 'react-router';

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const collapsedWidth = 74;
  const [sidebarWidth, setSidebarWidth] = useState(drawerWidth);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (drawerOpen || isHovered) {
      setSidebarWidth(drawerWidth);
    } else {
      setSidebarWidth(collapsedWidth);
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (drawerOpen) {
      setSidebarWidth(drawerWidth);
    } else {
      if (isHovered) {
        setSidebarWidth(drawerWidth);
      } else {
        setSidebarWidth(collapsedWidth);
      }
    }
  }, [isHovered]);

  const handleHover = () => {
    if (!drawerOpen) {
      setIsHovered(true);
    }
  };

  useEffect(() => {
    setIsHovered(false);
  }, [location.pathname]);

  const handleMouseOut = () => {
    if (!drawerOpen) {
      setIsHovered(false);
    }
  };

  const drawer = (
    <Box
      className={drawerOpen ? '' : isHovered ? 'sidebarHover' : 'sidebarCollapsed'}
      onMouseOver={() => handleHover()}
      onMouseOut={() => handleMouseOut()}
      boxShadow={2}
    >
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
          <LogoSection />
        </Box>
      </Box>
      <BrowserView>
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px'
          }}
        >
          <MenuList />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
        </Box>
      </MobileView>
    </Box>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? sidebarWidth : 'auto' }} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: sidebarWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px',
              transform: 'translateX(0px)!important',
              visibility: 'visible!important'
            }
          }
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object
};

export default Sidebar;
