import { Box, Button, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from 'store';
import { updateCampaignModal } from 'store/thunk/userThunk';

export default function EditPlacementModal({ placementEditModal, onClose, onGetCampaignData, setInlineLoader }) {
  const [
    loading
    // setLoading
  ] = useState();
  const [campaign, setCampaign] = useState({
    placement_bid_search_ingrid: placementEditModal?.data?.placement_bid_search_ingrid,
    placement_bid_item_buybox: placementEditModal?.data?.placement_bid_item_buybox,
    placement_bid_home_page: placementEditModal?.data?.placement_bid_home_page,
    placement_bid_stock_up: placementEditModal?.data?.placement_bid_stock_up
  });
  const dispatch = useAppDispatch();

  const handledPercentageChange = (e) => {
    const inputValue = Number(e.target.value);
    if (inputValue >= 0 && inputValue <= 900) {
      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        [e.target.name]: inputValue
      }));
    }
  };

  const handleClick = () => {
    let latestData = {};
    Object.keys(campaign).forEach((key) => {
      // if (campaign[key]) {
      latestData = { ...latestData, [key]: Number(campaign[key]) };
      // }
    });
    const _request = { ...latestData, advertiser: placementEditModal?.data?.advertiser };
    const existingCampaignId = placementEditModal?.data?.id;
    setInlineLoader({ placement: existingCampaignId });
    onClose();
    dispatch(updateCampaignModal({ _request, existingCampaignId })).then((res) => {
      if (res?.payload?.status == 201 || res?.payload?.status == 200) {
        onGetCampaignData(existingCampaignId, campaign);
      }
    });
  };

  const renderLabel = (name, required) => {
    return (
      <Box>
        <Typography sx={{ padding: 0.5, display: 'flex' }}>
          {required && <Box sx={{ color: 'red', mr: 0.5 }}>*</Box>} {name}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={1.5} sx={{ padding: '25px 0px', minWidth: '600px' }}>
        {
          <Grid item sm={4}>
            {renderLabel('Search Ingrid')}
            <TextField
              fullWidth
              type="number"
              onWheel={(e) => e.target.blur()}
              placeholder="0-900"
              inputProps={{
                min: 0,
                max: 900
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              name="placement_bid_search_ingrid"
              value={campaign?.placement_bid_search_ingrid}
              onChange={handledPercentageChange}
            />
          </Grid>
        }

        <Grid item sm={4}>
          {renderLabel('Item Buybox')}
          <TextField
            fullWidth
            type="number"
            onWheel={(e) => e.target.blur()}
            inputProps={{
              min: 0,
              max: 900
            }}
            placeholder="0-900"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            name="placement_bid_item_buybox"
            value={campaign?.placement_bid_item_buybox}
            onChange={handledPercentageChange}
          />
        </Grid>
        {placementEditModal?.data?.targeting_type == 'auto' && (
          <>
            <Grid item sm={4}>
              {renderLabel('Home Page')}
              <TextField
                fullWidth
                type="number"
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  min: 0,
                  max: 900
                }}
                placeholder="0-900"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="placement_bid_home_page"
                value={campaign?.placement_bid_home_page}
                onChange={handledPercentageChange}
              />
            </Grid>

            <Grid item sm={4}>
              {renderLabel('Stock Up')}
              <TextField
                fullWidth
                type="number"
                placeholder="0-900"
                onWheel={(e) => e.target.blur()}
                inputProps={{
                  min: 0,
                  max: 900
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                name="placement_bid_stock_up"
                value={campaign?.placement_bid_stock_up}
                onChange={handledPercentageChange}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button color="secondary" variant="outlined" sx={{ mr: 2 }} onClick={onClose}>
          Cancel
        </Button>
        <Button color="secondary" variant="contained" onClick={handleClick} disabled={loading}>
          {loading && <CircularProgress sx={{ color: '#0000001f', mr: '10px' }} size="10px" />}
          Confirm
        </Button>
      </Box>
    </>
  );
}
