import { useState } from 'react';

import colors from 'assets/scss/_themes-vars.module.scss';

// material-ui
import { Grid, Typography, Button, IconButton, Box, Link, DialogActions, Container, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// project imports
import WalmartMarketplace from 'assets/images/dashna/walmartIco.svg';
import WalmartConnect from 'assets/images/dashna/connect.svg';
import DashnaLogo from 'assets/images/dashna/dashnaIco.svg';
import Group_image from '../../../../../assets/images/dashna/group_29.svg';
import Arrow from '../../../../../assets/images/dashna/Arrow.png';
import ConnectWalmartSeller from '../../emailVerification/modals/connectWalmartSeller';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useAppDispatch } from 'store';
import { VerifyStoreLinkExternal } from 'store/thunk/userThunk';
import MainCard from 'ui-component/cards/MainCard';
import { setCommonLoader } from 'store/slices/userDetailsSlice';

const ConnectToWalmartExternal = (props) => {
  const { addNewStore = false, setIsOpenModalAddStore } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isPageSeller, setIsPageSeller] = useState(false);
  const [response, setResponce] = useState();

  //Color
  const borderColor = colors?.borderColor;
  const primaryTextColor = colors?.primaryTextColor;
  const linkColor = colors?.linkColor;
  const grey900 = colors?.grey900;

  const handleClose = () => {
    setOpen(false);
    setIsOpenModalAddStore(false);
  };

  const clickToNext = () => {
    setIsPageSeller(true);
  };

  useEffect(() => {
    const route = location?.pathname;
    if (route) {
      const arrSplitURL = route.split('/');
      const secretKey = arrSplitURL[2];

      const request = {
        sKey: secretKey,
        setResponce: setResponce
      };

      dispatch(VerifyStoreLinkExternal(request));
    }
  }, []);

  useEffect(() => {
    if (typeof response === 'undefined') {
      dispatch(setCommonLoader(true)); //Enable loader
    } else {
      dispatch(setCommonLoader(false)); //Enable loader
    }
  }, [response]);

  return (
    <>
      {typeof response !== 'undefined' && response ? (
        <MainCard className="invalid-link-main-card">
          <MainCard className="invalid-link-main-card">
            <Box className="invalid-link-content">
              <img src="/broken-link.svg" alt="broken-link" />
              <Typography className="link-title">Link Invalid !</Typography>
              <Typography className="link-description">{`The link is invalid or expired, You can't continue to view the data.`}</Typography>
            </Box>
          </MainCard>
        </MainCard>
      ) : isPageSeller ? (
        <ConnectWalmartSeller addNewStoreExternal={true} otherProps={{}} />
      ) : (
        typeof response !== 'undefined' &&
        !response && (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            minHeight={'100vh'}
            backgroundColor={colors?.pageBackground}
            borderRadius={'10px'}
          >
            <Container maxWidth="sm" sx={{ maxWidth: '850px !important' }}>
              <Box my={3} display={'flex'} justifyContent={'center'} flexDirection={'row'} alignItems={'center'}>
                <Box>
                  <img width={'75px'} height={'75px'} src={Group_image} alt="icon" />
                </Box>
                <Box mx={2}>
                  <img width={'50px'} height={'50px'} src={Arrow} alt="icon" />
                </Box>
                <Box>
                  <img width={'75px'} height={'75px'} src={DashnaLogo} alt="icon" />
                </Box>
                <Box mx={2}>
                  <img width={'50px'} height={'50px'} src={Arrow} alt="icon" />
                </Box>
                <Box>
                  <img width={'75px'} height={'75px'} src={WalmartMarketplace} alt="icon" />
                </Box>
              </Box>

              <Grid item xs={12} md={12}>
                <Box backgroundColor={colors?.paper} borderRadius={'10px'} border={`1px solid ${borderColor}`} px={1}>
                  <Typography p={2} className={'fontInter'} color={colors?.primaryMain} variant={'h2'}>
                    Connect to Walmart
                  </Typography>
                  {addNewStore && (
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Divider />
                  <Box px={3} pt={3} pb={2}>
                    <Grid container>
                      <Grid item xs={12} pb={3.5}>
                        <Typography className={'fontInter'} color={primaryTextColor} fontSize={'16px'}>
                          {`You've been invited by SellCord to provide access to your Walmart account through our software, Dashna.`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} pb={1.5}>
                        <Typography className={'fontInter'} color={primaryTextColor} fontSize={'20px'} fontWeight={700}>
                          SellCord requires access to:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} p={2.5} mb={2.5} border={`1px solid ${borderColor}`} borderRadius={'10px'}>
                        <Box display={'flex'} alignItems={'center'} gap={'12px'} mb={2}>
                          <Box>
                            <Link to="#">
                              <img src={WalmartMarketplace} alt="Walmart Marketplace" />
                            </Link>
                          </Box>
                          <Box>
                            <Typography className={'fontInter clr2'} fontSize={'18px'} fontWeight={700}>
                              Walmart Marketplace/Supplier One
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              className={'fontInter'}
                              px={1}
                              py={'1px'}
                              border={`1px solid ${linkColor}`}
                              borderRadius={'4px'}
                              fontSize={'10px'}
                              color={linkColor}
                            >
                              Required
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography className={'fontInter'} fontSize={'16px'} color={grey900}>
                            We use this connection to pull your Marketplace data and allow you to view insights and analytics in
                            Dashna.
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} p={2.5} mb={1.5} border={`1px solid ${borderColor}`} borderRadius={'10px'}>
                        <Box display={'flex'} alignItems={'center'} gap={'12px'} mb={2}>
                          <Box>
                            <Link to="#">
                              <img src={WalmartConnect} alt="Walmart Connect" />
                            </Link>
                          </Box>
                          <Box>
                            <Typography className={'fontInter clr2'} fontSize={'18px'} fontWeight={700}>
                              Walmart Connect
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              className={'fontInter'}
                              px={1}
                              py={'1px'}
                              border={`1px solid ${linkColor}`}
                              borderRadius={'4px'}
                              fontSize={'10px'}
                              color={linkColor}
                            >
                              Required
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Typography className={'fontInter'} fontSize={'16px'} color={grey900}>
                            We use this connection to pull your Connect data and allow us to manage your advertising from Dashna.
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} color={colors?.primaryTextColor} borderRadius={'10px'}>
                        <Typography
                          className={'fontInter'}
                          color={primaryTextColor}
                          fontSize={'16px'}
                          display={'flex'}
                          alignItems={'center'}
                          gap={'12px'}
                          pt={1}
                          pb={2}
                        >
                          {`Rest assured, your information is fully encrypted and will be used strictly for managing and optimizing your store. `}
                        </Typography>
                        <Typography className={'fontInter'} color={primaryTextColor} fontSize={'16px'}>
                          {`When you're ready, click "Get Started" to begin the process. If you have any questions, feel free to reach out to your SellCord account manager or point of contact.`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider />
                  <DialogActions sx={{ marginBottom: '30px' }}>
                    <Button
                      className={'actionBtn'}
                      sx={{ minWidth: '100px', marginTop: '10px' }}
                      onClick={() => clickToNext()}
                      variant="contained"
                      color="primary"
                    >
                      Get Started
                    </Button>
                  </DialogActions>
                </Box>
              </Grid>
            </Container>
          </Box>
        )
      )}
    </>
  );
};

export default ConnectToWalmartExternal;
