import { useState, useEffect } from 'react';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppDispatch, useAppSelector } from 'store';
import { createWalmartStore, updateWalmartStore } from 'store/thunk/userThunk';
import CreateStore from './createStore';
import ConnectAdvertiser from './connectAdvertiser';
import ConnectWalmartLogin from './connectWalmartLogin';
// material-ui
import {
  FormControl,
  Dialog,
  Grid,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Box,
  Link,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  DialogTitle,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
// project imports
import __ from 'underscore';
import { useNavigate } from 'react-router-dom';
import DashnaLogo from 'assets/images/dashna/dashnaIco.svg';
import SyncAlt from 'assets/images/dashna/syncAlt.svg';
import WalmartIco from 'assets/images/dashna/walmartIco.svg';
import Warning from 'assets/images/dashna/warning.svg';
import { useRef } from 'react';
import { useLocation } from 'react-router';
import { CLIENT_ID_3P, CLIENT_ID_1P, DEV_APP_URL, LIVE_APP_URL } from 'store/constant';
import { generateRandomString } from 'helper/commonHelper';
import ConnectToWalmartExternal from 'views/pages/authentication/authentication/externalFlow/ConnectToWalmartExternal';

const ConnectWalmartSeller = (props) => {
  const {
    addNewStoreExternal = false,
    addNewStore = false,
    setIsOpenModalAddStore = () => {},
    editStore = false,
    setIsOpenModalEditStore = () => {},
    otherProps,
    fixDisconnectedStore = false,
    setFixDisconnectedStore = () => {}
  } = props;

  const location = useLocation();
  const nextRef = useRef();
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { selectedStoreData } = useAppSelector((state) => state?.userDetailsSlice);
  const userAuthData = useAppSelector((state) => state.authorization);
  const [open, setOpen] = useState(true);
  const [isDisableNext, setIsDisableNext] = useState(true);
  const [isGetClientIdSecretAutomatic, setIsGetClientIdSecretAutomatic] = useState(true);
  const [loading, setLoading] = useState(false);
  const [beError, setBeError] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [formValues, setFormValues] = useState({
    is_created_by_auth: false,
    is_client_id_and_secret: false,
    storeNickname: '',
    clientId: '',
    clientSecret: '',
    sellerType: '3p',
    sellerId: '',
    supplierId: '',
    distributorIds: []
  });
  const [error, setError] = useState({});
  const [walmartLoginUniqueStateIdInfo, setWalmartLoginUniqueStateIdInfo] = useState({});
  const [goNextToAddAdvertiser, setGoNextToAddAdvertiser] = useState(false);
  const [goBackToCreateStore, setGoBackToCreateStore] = useState(false);
  const [isConnectingWalmart, setIsConnectingWalmart] = useState(false);

  //Color

  const primaryTextColor = colors?.primaryTextColor;
  const linkColor = colors?.linkColor;
  const errorBackground = colors?.errorBackground;

  const firstTime = userAuthData?.userData?.is_first_time_login;

  useEffect(() => {
    if (__.isEmpty(selectedStoreData)) {
      return;
    }

    const {
      nick_name,
      client_id,
      client_secret,
      seller_type,
      seller_id,
      supplier_id,
      distributor_id,
      is_created_by_auth,
      is_client_id_and_secret
    } = selectedStoreData;
    if (addNewStore || editStore || firstTime || addNewStoreExternal) {
      setFormValues({
        ...formValues,
        is_created_by_auth: is_created_by_auth,
        is_client_id_and_secret: is_client_id_and_secret,
        storeNickname: nick_name,
        clientId: client_id,
        clientSecret: client_secret,
        sellerType: seller_type,
        ...(seller_type === '3p' && { sellerId: seller_id }),
        ...(seller_type === '1p' && { supplierId: supplier_id, distributorIds: distributor_id })
      });
      setIsGetClientIdSecretAutomatic(is_created_by_auth);
    }

    if (fixDisconnectedStore) {
      setTimeout(() => {
        nextRef.current?.click();
      }, 300);
    }
  }, [selectedStoreData]);

  //Next button disable status
  useEffect(() => {
    setIsDisableNext(checkIfDisabled());
  }, [formValues]);

  //Set Secret Key for External Store Creation API Params
  useEffect(() => {
    const route = location?.pathname;
    if (route && addNewStoreExternal) {
      const arrSplitURL = route.split('/');
      const secretKey = arrSplitURL[2];
      setSecretKey(secretKey);
    }
  }, []);

  //When clicking on Connect with Supplier One Login or Enter API Credentials Manually link then check validation again
  useEffect(() => {
    setIsDisableNext(checkIfDisabled());
  }, [isGetClientIdSecretAutomatic]);

  //Next button disable status
  const checkIfDisabled = () => {
    const { storeNickname, clientId, clientSecret, sellerType, sellerId, supplierId, distributorIds } = formValues;

    if (!storeNickname || !sellerType) {
      return true;
    }

    if (sellerType === '3p' && !sellerId) {
      return true;
    }

    if (sellerType === '1p' && clientId && !clientSecret) {
      return true;
    }
    if (sellerType === '1p' && !clientId && clientSecret) {
      return true;
    }

    if (sellerType === '3p' && clientId && !clientSecret) {
      return true;
    }
    if (sellerType === '3p' && !clientId && clientSecret) {
      return true;
    }

    if (sellerType === '1p') {
      if (!supplierId) {
        return true;
      }

      if (distributorIds.length) {
        let isDistributorEmpty = false;
        distributorIds.map((l) => {
          if (!l) {
            isDistributorEmpty = true;
          }
        });
        return isDistributorEmpty;
      }
    }

    return false;
  };

  //Close the Disalog Modal
  const handleClose = () => {
    setOpen(false);
    setIsOpenModalAddStore(false);
    setIsOpenModalEditStore(false);
  };

  //Go to Back Screen
  const clickToBack = () => {
    setGoBackToCreateStore(true);
    // navigate('/create-store');
  };

  //While input values
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
      ...(name === 'sellerType' && value === '3p' && { supplierId: '', distributorIds: [] }),
      ...(name === 'sellerType' && value === '1p' && { sellerId: '' })
    });

    setError((prevState) => {
      const newState = { ...prevState };
      delete newState[name];
      return newState;
    });

    setBeError(''); //Empty Backend Error
  };

  const validateErrors = () => {
    //Error checking
    let errs = {};
    const { storeNickname, clientId, clientSecret, sellerType, sellerId, supplierId, distributorIds } = formValues;
    if (!storeNickname) {
      errs.storeNickname = true;
    }

    if (sellerType === '1p' && clientId && !clientSecret) {
      errs.clientSecret = true;
    }
    if (sellerType === '1p' && !clientId && clientSecret) {
      errs.clientId = true;
    }

    if (sellerType === '3p' && clientId && !clientSecret) {
      errs.clientSecret = true;
    }
    if (sellerType === '3p' && !clientId && clientSecret) {
      errs.clientId = true;
    }

    if (!sellerType) {
      errs.sellerType = true;
    }
    if (sellerType === '3p' && !sellerId) {
      errs.sellerId = true;
    }
    if (sellerType === '1p' && !supplierId) {
      errs.supplierId = true;
    }
    if (sellerType === '1p') {
      distributorIds.map((l, i) => {
        if (!l) {
          errs[`distributorIds_${i}`] = true;
        }
      });
      // const hasEmptyStringInDistributorIds = distributorIds.some(id => id === "");
      // if(hasEmptyStringInDistributorIds) {
      //   errs.distributorIds = true;
      // }
    }
    setError(errs);
    return errs;
  };

  //Form Submit Create new store Manually
  const handleSubmit = (e) => {
    e.preventDefault();

    const errs = validateErrors();
    if (!__.isEmpty(errs)) {
      return;
    }

    setLoading(true); //Invoke Loading.....
    setBeError(''); //Empty Backend Error

    //API Calling Start
    const request = {
      client_id: formValues?.clientId,
      client_secret: formValues?.clientSecret,
      seller_type: formValues?.sellerType,
      nick_name: formValues?.storeNickname,
      ...(formValues?.sellerType === '3p' && { seller_id: formValues?.sellerId.trim() }),
      ...(formValues?.sellerType === '1p' && { supplier_id: formValues?.supplierId.trim() }),
      ...(formValues?.sellerType === '1p' && { distributor_id: formValues?.distributorIds }),
      partner_id: formValues?.sellerType === '3p' ? formValues?.sellerId.trim() : formValues?.supplierId.trim(),
      display_module_fe: 'add-advertiser', //Next Screen to be display
      navigate: navigate,
      addNewStore: addNewStore,
      addNewStoreExternal: addNewStoreExternal,
      secretKey: secretKey,
      otherProps: otherProps,
      setGoNextToAddAdvertiser: setGoNextToAddAdvertiser,
      setLoading: setLoading,
      setBeError: setBeError
    };

    appDispatch(createWalmartStore(request));
  };

  //Form Submit while Update Selected Store
  const handleSubmitUpdateStore = (e) => {
    e.preventDefault();

    const errs = validateErrors();
    if (!__.isEmpty(errs)) {
      return;
    }

    setLoading(true); //Invoke Loading.....
    setBeError(''); //Empty Backend Error

    //API Calling Start
    const request = {
      storeId: selectedStoreData?.id,
      nick_name: formValues?.storeNickname,
      client_id: formValues?.clientId,
      client_secret: formValues?.clientSecret,
      seller_type: formValues?.sellerType,
      ...(formValues?.sellerType === '3p' && { seller_id: formValues?.sellerId.trim() }),
      ...(formValues?.sellerType === '1p' && { supplier_id: formValues?.supplierId.trim() }),
      ...(formValues?.sellerType === '1p' && { distributor_id: formValues?.distributorIds }),
      partner_id: formValues?.sellerType === '3p' ? formValues?.sellerId.trim() : formValues?.supplierId.trim(),
      display_module_fe: 'add-advertiser', //Next Screen to be display
      setIsOpenModalEditStore: setIsOpenModalEditStore,
      addNewStore: addNewStore,
      addNewStoreExternal: addNewStoreExternal,
      editStore: editStore,
      firstTime: firstTime,
      otherProps: otherProps,
      setGoNextToAddAdvertiser: setGoNextToAddAdvertiser,
      setLoading: setLoading,
      setBeError: setBeError
    };

    appDispatch(updateWalmartStore(request));

    setFixDisconnectedStore(false);
  };

  //While Add New Distributor ID
  const addDistributorId = () => {
    setFormValues((prevState) => ({
      ...prevState,
      distributorIds: [...prevState.distributorIds, '']
    }));
  };

  //While entering Distributor ID
  const handleDistributorIdChange = (e, index) => {
    const newdistributorIds = [...formValues.distributorIds];
    newdistributorIds[index] = e.target.value;

    setFormValues((prevState) => ({
      ...prevState,
      distributorIds: newdistributorIds
    }));

    setError((prevState) => {
      const newState = { ...prevState };
      delete newState[`distributorIds_${index}`];
      return newState;
    });
  };

  //While remove Distributor
  const handleRemoveDistributor = (index) => {
    setFormValues((prevState) => ({
      ...prevState,
      distributorIds: prevState.distributorIds.filter((_, i) => i !== index)
    }));
  };

  const handleToggleClientIdSecretInput = () => {
    setBeError(''); //Empty Backend Error
    // setFormValues({
    //   ...formValues,
    //   clientId: '',
    //   clientSecret: ''
    // });

    setIsGetClientIdSecretAutomatic(!isGetClientIdSecretAutomatic);
  };

  //Create Dynamic Walmart Login URL for 3P or 1P
  const connectToWalmartLogin = () => {
    const sellerType = formValues?.sellerType;
    const responseType = 'code';
    const clientId = sellerType === '3p' ? CLIENT_ID_3P : CLIENT_ID_1P;
    const clientType = sellerType === '3p' ? 'seller' : 'supplier';
    const nonce = generateRandomString(8);
    const state = generateRandomString(10);
    const hostURL =
      window.location.hostname.includes('dev') || window.location.hostname.includes('localhost') ? DEV_APP_URL : LIVE_APP_URL;
    const redirectUri = `${hostURL}/resource/${sellerType}/applanding`;
    const loginURL = `https://login.account.wal-mart.com/authorize?responseType=${responseType}&clientId=${clientId}&clientType=${clientType}&redirectUri=${redirectUri}&state=${state}&nonce=${nonce}`;

    //Prepare Data For Success Page API Request in Unique state key
    const uniqueStateInfo = {
      [state]: {
        storeNickname: formValues?.storeNickname,
        sellerType: formValues?.sellerType,
        sellerId: formValues?.sellerId,
        supplierId: formValues?.supplierId,
        distributor_id: formValues?.distributorIds,
        secretKey: secretKey,
        addNewStoreExternal: addNewStoreExternal,
        editStore: editStore,
        is_client_id_and_secret: formValues?.is_client_id_and_secret,
        storeId: selectedStoreData?.id || ''
      }
    };
    setWalmartLoginUniqueStateIdInfo(uniqueStateInfo);

    window.open(loginURL, '_blank');
    setIsConnectingWalmart(true);
  };

  return (
    <>
      {isConnectingWalmart ? (
        <ConnectWalmartLogin
          walmartLoginUniqueStateIdInfo={walmartLoginUniqueStateIdInfo}
          addNewStoreExternal={addNewStoreExternal}
          setIsConnectingWalmart={setIsConnectingWalmart}
          setGoNextToAddAdvertiser={setGoNextToAddAdvertiser}
          otherProps={otherProps}
        />
      ) : goBackToCreateStore ? (
        addNewStoreExternal ? (
          <ConnectToWalmartExternal />
        ) : (
          <CreateStore addNewStore={addNewStore} setIsOpenModalAddStore={setIsOpenModalAddStore} />
        )
      ) : goNextToAddAdvertiser ? (
        <ConnectAdvertiser
          addNewStoreExternal={addNewStoreExternal}
          addNewStore={addNewStore}
          setIsOpenModalAddStore={setIsOpenModalAddStore}
          editStore={editStore}
          setIsOpenModalEditStore={setIsOpenModalEditStore}
        />
      ) : (
        <Dialog
          className={addNewStoreExternal ? 'externalDialogBackClr' : ''}
          maxWidth={'md'}
          open={open}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{ sx: { width: '700px', maxWidth: '700px' } }}
        >
          <DialogTitle>
            <Box sx={{ display: 'flex' }} alignItems={'center'} gap={2}>
              <Box>
                <Typography className={'fontInter'} color={primaryTextColor} variant={'h2'}>
                  Connect to Walmart
                </Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={'10px'}>
                <Link to="#">
                  <img src={DashnaLogo} alt="Dashna Logo" />
                </Link>
                <Link to="#">
                  <img src={SyncAlt} alt="Sync Logo" />
                </Link>
                <Link to="#">
                  <img src={WalmartIco} alt="Marketplace Logo" />
                </Link>
              </Box>
            </Box>
            {(addNewStore || editStore) && (
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500]
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </DialogTitle>
          <form
            onSubmit={
              (addNewStore || editStore || firstTime || addNewStoreExternal) && !__.isEmpty(selectedStoreData)
                ? handleSubmitUpdateStore
                : handleSubmit
            }
          >
            <DialogContent dividers>
              <Grid container>
                <Grid item xs={12} pb={1}>
                  <Typography className={'fontInter'} color={primaryTextColor} fontSize={'20px'} fontWeight={700} pb={1.5}>
                    Connect to Walmart Marketplace
                  </Typography>
                  <Link
                    className={'fontInter'}
                    color={linkColor}
                    fontSize={'16px'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      window.open(
                        `https://support.dashna.com/portal/en/kb/articles/connect-walmart-marketplace-supplier-one`,
                        '_blank'
                      )
                    }
                  >
                    Click here to view the documentation
                  </Link>
                </Grid>

                <Grid container rowSpacing={0} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                  <Grid item xs={12}>
                    <TextField
                      label="Store Nickname"
                      name="storeNickname"
                      error={error?.storeNickname}
                      value={formValues?.storeNickname}
                      onChange={handleInputChange}
                      margin="normal"
                      fullWidth
                      helperText={error?.storeNickname ? 'Please add store nickname' : ''}
                    />
                  </Grid>
                  <Grid item xs={12} pt={2} pb={1}>
                    <FormControl>
                      <FormLabel
                        className={'fontInter'}
                        id="sellerTypeId"
                        sx={{ color: primaryTextColor, fontSize: '16px', fontWeight: 700, marginBottom: '8px' }}
                      >
                        Seller Type:
                      </FormLabel>
                      <RadioGroup
                        className={'fontInter'}
                        sx={{ gap: '16px' }}
                        onChange={handleInputChange}
                        row
                        name="sellerType"
                        value={formValues?.sellerType}
                      >
                        <FormControlLabel
                          disabled={editStore}
                          className={'fontInter'}
                          sx={{
                            fontSize: '16px',
                            border: '1px solid #AFAFFF',
                            borderRadius: '10px',
                            ml: 0,
                            paddingRight: '50px'
                          }}
                          value="3p"
                          control={<Radio />}
                          label="3P Seller"
                        />
                        <FormControlLabel
                          disabled={editStore}
                          className={'fontInter'}
                          sx={{ fontSize: '16px', border: '1px solid #AFAFFF', borderRadius: '10px', paddingRight: '10px' }}
                          value="1p"
                          control={<Radio />}
                          label="1P Vendor/DSV"
                        />
                      </RadioGroup>
                      <FormHelperText error={true}>{error?.sellerType ? 'Please select seller type' : ''}</FormHelperText>
                    </FormControl>
                  </Grid>
                  {formValues?.sellerType === '3p' && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={
                          (formValues?.is_created_by_auth || formValues?.is_client_id_and_secret) &&
                          (selectedStoreData?.seller_id || selectedStoreData?.supplier_id)
                        }
                        label="Seller ID"
                        name="sellerId"
                        error={error?.sellerId}
                        value={formValues?.sellerId}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        helperText={error?.sellerId ? 'Please add seller id' : ''}
                      />
                    </Grid>
                  )}
                  {formValues?.sellerType === '1p' && (
                    <Grid item xs={12}>
                      <TextField
                        disabled={
                          (formValues?.is_created_by_auth || formValues?.is_client_id_and_secret) &&
                          (selectedStoreData?.seller_id || selectedStoreData?.supplier_id)
                        }
                        label="Supplier ID"
                        name="supplierId"
                        error={error?.supplierId}
                        value={formValues?.supplierId}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        helperText={error?.supplierId ? 'Please add supplier id' : ''}
                      />
                    </Grid>
                  )}

                  {formValues?.sellerType === '1p' &&
                    formValues?.distributorIds?.map((distributorId, index) => (
                      <>
                        <Grid item xs={11} key={index}>
                          <TextField
                            label={`Distributor ID ${index + 1}`}
                            error={error?.[`distributorIds_${index}`]}
                            value={distributorId}
                            onChange={(e) => handleDistributorIdChange(e, index)}
                            margin="normal"
                            fullWidth
                            helperText={error?.[`distributorIds_${index}`] ? `Please add distributor id ${index + 1}` : ''}
                          />
                        </Grid>
                        <Grid item xs={1} display={'flex'} alignItems={'center'} key={`remove_${index}`}>
                          <IconButton
                            aria-label="close"
                            onClick={() => handleRemoveDistributor(index)}
                            sx={{
                              color: (theme) => theme.palette.grey[500]
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Grid>
                      </>
                    ))}

                  {formValues?.sellerType === '1p' && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end" marginBottom="24px">
                        <Link pt={1} onClick={() => addDistributorId()} sx={{ cursor: 'pointer', textDecoration: 'none' }} to="#">
                          +Add Distributor ID
                        </Link>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                {!isGetClientIdSecretAutomatic && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label="Client ID"
                        name="clientId"
                        error={error?.clientId}
                        value={formValues?.clientId}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        helperText={error?.clientId ? 'Please add client id' : ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Client Secret"
                        name="clientSecret"
                        error={error?.clientSecret}
                        value={formValues?.clientSecret}
                        onChange={handleInputChange}
                        margin="normal"
                        fullWidth
                        helperText={error?.clientSecret ? 'Please add client secret' : ''}
                      />
                    </Grid>
                  </>
                )}

                {beError?.length > 0 && (
                  <Grid item xs={12} pt={2} pb={2}>
                    <Box
                      display={'flex'}
                      gap={2}
                      alignItems={'center'}
                      p={2}
                      borderRadius={'10px'}
                      backgroundColor={errorBackground}
                    >
                      <Box>
                        <img src={Warning} alt="Warning" />
                      </Box>
                      <Box>
                        <Typography>{beError}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}

                {isGetClientIdSecretAutomatic && (
                  <Grid item xs={12} pt={isGetClientIdSecretAutomatic ? 13 : 1}>
                    <Link
                      underline="none"
                      className={'fontInter'}
                      color={linkColor}
                      fontSize={'14px'}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleToggleClientIdSecretInput()}
                    >
                      Enter API Credentials Manually
                    </Link>
                  </Grid>
                )}
                {!isGetClientIdSecretAutomatic && (
                  <Grid item xs={12} pt={1}>
                    <Link
                      underline="none"
                      className={'fontInter'}
                      color={linkColor}
                      fontSize={'14px'}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleToggleClientIdSecretInput()}
                    >
                      {formValues?.sellerType === '3p' ? 'Connect with Marketplace Login' : 'Connect with Supplier One Login'}
                    </Link>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              {!editStore && (
                <Button
                  className={'actionBtnOutline'}
                  sx={{ minWidth: '100px' }}
                  onClick={() => clickToBack()}
                  variant="outlined"
                  color="primary"
                >
                  Back
                </Button>
              )}

              {isGetClientIdSecretAutomatic ? (
                <Button
                  disabled={isDisableNext}
                  className={'actionBtnOutline'}
                  sx={{ minWidth: '100px' }}
                  onClick={() => connectToWalmartLogin()}
                  variant="contained"
                  color="primary"
                >
                  {formValues?.sellerType === '3p' ? 'Connect with Marketplace' : 'Connect with Supplier One'}
                </Button>
              ) : (
                <LoadingButton
                  disabled={isDisableNext}
                  className={'actionBtn'}
                  sx={{ minWidth: '100px' }}
                  loading={loading}
                  color="primary"
                  variant="contained"
                  type="submit"
                  ref={nextRef}
                >
                  Connect
                </LoadingButton>
              )}
            </DialogActions>{' '}
          </form>
        </Dialog>
      )}
    </>
  );
};

export default ConnectWalmartSeller;
