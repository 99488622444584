import { Button, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import MUIDataTable from 'mui-datatables';
import React, { useEffect } from 'react';
import { useState } from 'react';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'rsuite/dist/rsuite.css';
import MainCard from 'ui-component/cards/MainCard';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import colors from 'assets/scss/_themes-vars.module.scss';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import { useCallback } from 'react';
import { debounce } from 'lodash';
import { getSelectFromItemsFromId } from 'store/thunk/userThunk';
import { PageValue } from 'store/constant';
import { Loader } from 'rsuite';

const SelectFromTable = (props) => {
  const { onAddAllClick, setSearchableData, selectedData, setSelectedData, advertiserId } = props;
  const [rowPerPage, setRowPerPage] = useState(25);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const { selectdItemData } = useAppSelector((state) => state?.userDetailsSlice);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    getAdvertiserListWithPagination(page, rowPerPage);
  }, []);

  const getAdvertiserListWithPagination = (page, rowPerPage, search = '', changedColumn, direction) => {
    if (advertiserId) {
      dispatch(getSelectFromItemsFromId({ advertiserId, page, rowPerPage, search, changedColumn, direction })).then(() => {
        setLoading(false);
      });
    }
  };

  const handleSearch = useCallback(
    debounce((data) => {
      setPage(0);
      getAdvertiserListWithPagination(0, rowPerPage, data);
    }, 500),
    []
  );

  const columns = [
    {
      name: 'product_name',
      label: 'Item',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const data = selectdItemData[tableMeta?.rowIndex];
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component={'img'} sx={{ padding: 1, height: '40px', width: '40px' }} src={data?.images} />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {/* <Typography sx={{}}>{data?.product_name}</Typography> */}

                  {data?.product_name?.length > 20 ? (
                    <>
                      <Tooltip
                        placement="top"
                        sx={{ fontSize: '10px' }}
                        title={
                          <Typography variant="body1" sx={{ fontSize: 16 }}>
                            {data?.product_name}
                          </Typography>
                        }
                        arrow
                      >
                        {data?.product_name?.substring(0, 25)}...
                      </Tooltip>
                    </>
                  ) : (
                    data?.product_name
                  )}

                  <Link
                    sx={{ cursor: 'pointer' }}
                    onClick={() => window.open(`https://www.walmart.com/ip/${data?.item_id}`, '_blank')}
                  >
                    <Typography sx={{ color: colors.primaryMain }}>{data?.item_id ? data?.item_id : '123456789'}</Typography>
                  </Link>
                </Box>
              </Box>
            </>
          );
        }
      }
    },
    {
      name: 'brand',
      label: 'Brand',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'item_id',
      label: 'Action',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const data = selectdItemData?.find((item) => item?.item_id == value);
          const disable = selectedData?.find((item) => item?.item_id == value);
          return (
            <>
              <IconButton
                sx={{ color: colors.secondaryMain }}
                disabled={disable ? true : false}
                onClick={() => {
                  setSelectedData([...selectedData, { ...data, bid_amount: '0.20' }]);
                }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          );
        }
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    page: page,
    jumpToPage: false,
    responsive: 'standard',
    fixedHeader: true,
    fixedSelectColumn: false,
    selectToolbarPlacement: 'none',
    // tableBodyHeight: '350px',
    download: false,
    pagination: true,
    serverSide: false,
    rowsPerPage: rowPerPage,
    rowsPerPageOptions: PageValue,
    selectableRows: 'none',
    search: true,
    searchPlaceholder: 'Input item id / title',
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getAdvertiserListWithPagination(page, row, search);
    },
    onSearchChange: (searchText) => {
      setSearch(searchText);
      handleSearch(searchText);
      const filteredItems = selectdItemData?.filter(
        (item) =>
          item?.brand?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          item?.product_name?.toLowerCase()?.includes(searchText?.toLowerCase())
      );
      setSearchableData(filteredItems);
    },
    customToolbar: () => (
      <>
        <Box sx={{ display: 'flex', float: 'inline-start', alignItems: 'center', marginTop: 1 }}>
          <Button disabled={!selectdItemData?.length} variant="outlined" color="secondary" size="small" onClick={onAddAllClick}>
            Add All
          </Button>
        </Box>
      </>
    ),
    onSearchClose: () => {
      setSearch('');
      setSearchableData(selectdItemData);
      getAdvertiserListWithPagination(0, rowPerPage, '');
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      setSearch('');
      getAdvertiserListWithPagination(0, rowPerPage, '', changedColumn, direction);
    }
  };

  return (
    <>
      {loading ? (
        <Box display={'flex'} justifyContent={'center'} p={5}>
          <Loader />
        </Box>
      ) : (
        <Box>
          <MainCard title="Search Item">
            <MUIDataTable
              actions={[
                {
                  icon: 'save'
                }
              ]}
              sx={{ background: 'red', padding: 0 }}
              data={selectdItemData}
              columns={columns}
              options={options}
              className="custom-table-scroll pnl-by-item"
            />
          </MainCard>
        </Box>
      )}
    </>
  );
};

export default SelectFromTable;
