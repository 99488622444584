import { TextField } from '@mui/material';
import OutlinedDiv from './Outline';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useEffect } from 'react';
import colors from 'assets/scss/_themes-vars.module.scss';
import { CURRENT_RANK_MINIMUM_VALUE } from 'store/constant';
import { useMemo } from 'react';
// import { useEffect } from 'react';

const FilterPerformance = (props) => {
  const [showErrorCurrentRank, setShowErrorCurrentRank] = useState(false);
  const { performanceItem, handleInputChange, isReset } = props;

  const [min, setMin] = useState();
  const [max, setMax] = useState();

  const handleKeyPress = (e) => {
    // Prevent the input of '-' character
    if (e.key === '-' || e.key === 'e') {
      e.preventDefault();
    }
  };

  //Reset Performance Related All filters when we change Profile
  useMemo(() => {
    if (!isReset) {
      setMin(null);
      setMax(null);
    }
  }, [isReset]);

  const checkCurrentRankMinValue = (minVal) => {
    const input = performanceItem?.value;
    if (input === 'current_rank' && minVal > CURRENT_RANK_MINIMUM_VALUE) {
      return true;
    } else {
      return false;
    }
  };

  //Min value of Current Rank Input should not be > 201 of Rank Tracker page
  useEffect(() => {
    const status = checkCurrentRankMinValue(min);
    setShowErrorCurrentRank(status);
  }, [min]);

  return (
    <div key={performanceItem?.id} className="performance-wrapper">
      <OutlinedDiv
        showErrorCurrentRank={showErrorCurrentRank}
        showError={min?.length > 0 && max?.length > 0 && Number(min) > Number(max)}
        label={performanceItem?.name}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            type="number"
            sx={{
              '& fieldset': { border: 'none' },
              '& input::placeholder': {
                color: colors?.grey500,
                opacity: 1 // For Firefox
              },
              '& input::-ms-input-placeholder': {
                color: colors?.grey500 // For Edge
              }
              // padding: '0px !important',
            }}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            value={min || ''}
            id="minPrice"
            placeholder="Min"
            className="ddd"
            size="small"
            onKeyPress={handleKeyPress}
            disableFocusRipple
            onChange={(e) => {
              handleInputChange(
                performanceItem.value,
                'min',
                e.target.value || '',
                (e.target.value?.length > 0 && max?.length > 0 && Number(e.target.value) > Number(max)) ||
                  checkCurrentRankMinValue(e.target.value)
              );
              setMin(e.target.value);
            }}
            style={{ width: '45%', border: 'none', borderRadius: '5px', padding: '0px', outline: 'none', fontSize: '12px' }}
          />
          ~
          <TextField
            type="number"
            id="maxPrice"
            value={max || ''}
            sx={{
              '& fieldset': { border: 'none' },
              '& input::placeholder': {
                color: colors?.grey500,
                opacity: 1 // For Firefox
              },
              '& input::-ms-input-placeholder': {
                color: colors?.grey500 // For Edge
              },
              padding: '2.5px 4px 2.5px 8px !important'
            }}
            size="small"
            className="ddd"
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*' // Allow only digits
            }}
            disableFocusRipple
            onKeyPress={handleKeyPress}
            onChange={(e) => {
              handleInputChange(
                performanceItem.value,
                'max',
                e.target.value || '',
                min?.length > 0 && e.target.value?.length > 0 && Number(min) > Number(e.target.value)
              );
              setMax(e.target.value);
            }}
            placeholder="Max"
            style={{ width: '45%', border: 'none', borderRadius: '5px', padding: '2px', outline: 'none', fontSize: '12px' }}
          />
        </Box>
      </OutlinedDiv>
    </div>
  );
};

export default FilterPerformance;
