import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { setUserData } from '../../../../store/slices/authSlice';
import { updateLastSelectedAdvertiser } from 'store/thunk/userThunk';
// material-ui
import { useTheme } from '@mui/material/styles';
// import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSettings, IconUsers, IconUserCircle, IconBasket } from '@tabler/icons';
import { useAppDispatch } from 'store';
import { setReset, setCommonLoader } from 'store/slices/userDetailsSlice';
import { setSuperAdmin } from 'store/slices/superUserSlice';
// import Person2Icon from '@mui/icons-material/Person2';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const appDispatch = useAppDispatch();
  const customization = useSelector((state) => state.customization);
  const { userData } = useSelector((state) => state.authorization);
  const user_profile = localStorage.getItem('user_profile');
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [greeting, setGreeting] = useState('');
  // const superAdmin = JSON.parse(localStorage.getItem('isSuperAdmin'));

  //Permission checking
  const user = userData?.permission?.user || {};
  const advertiser = userData?.permission?.advertiser || {};
  const userAccess = user?.access;
  const advertiserAccess = advertiser?.access;
  const store = userData?.permission?.store || {};
  const storeAccess = store?.access;

  useEffect(() => {
    // Function to get the current time and set the greeting based on the time of day
    const updateGreeting = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 5 && currentHour < 12) {
        setGreeting('Good Morning');
      } else if (currentHour >= 12 && currentHour < 17) {
        setGreeting('Good Afternoon');
      } else if (currentHour >= 17 && currentHour < 21) {
        setGreeting('Good Evening');
      } else {
        setGreeting('Good Night');
      }
    };
    // Update the greeting initially and then every minute to account for time changes
    updateGreeting();
    const intervalId = setInterval(updateGreeting, 60000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleLogout = async () => {
    appDispatch(setCommonLoader(true)); //Enable loader

    const selectedProfiles = localStorage.getItem('selectedProfiles')?.split(',').map(Number) || [];
    const request = {
      last_accessed_advertiser: selectedProfiles
    };
    appDispatch(updateLastSelectedAdvertiser(request)).then(() => {
      appDispatch(setCommonLoader(false)); //Disable loader

      // appDispatch(logout());
      localStorage.removeItem('user_data');
      localStorage.removeItem('isSuperAdmin');
      localStorage.removeItem('user_profile');
      localStorage.removeItem('selectedProfiles');
      localStorage.removeItem('selectedDates');
      localStorage.removeItem('isCompareDates');
      localStorage.removeItem('compareRangeType');
      localStorage.removeItem('DashboardIsCompareDates');
      localStorage.removeItem('DashboardCompareRangeType');
      localStorage.removeItem('DashboardSelectedDates');
      localStorage.clear();
      appDispatch(setUserData(null));
      appDispatch(setReset());
      appDispatch(setSuperAdmin(null));
      window.location.href = '/login';
      // navigate('/login');
    });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    // Update the selectedIndex based on the current path
    switch (location.pathname) {
      case '/advertiser':
        setSelectedIndex(0);
        break;
      case '/user-management':
        setSelectedIndex(3);
        break;
      case '/store':
        setSelectedIndex(6);
        break;
      case '/profile':
        setSelectedIndex(5);
        break;
      default:
        setSelectedIndex(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        icon={
          <>
            <Avatar
              src={user_profile ? JSON.parse(user_profile) : User1}
              sx={{
                ...theme.typography.mediumAvatar,
                margin: '8px 0 8px 8px !important',
                cursor: 'pointer'
              }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          </>
        }
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">{greeting},</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {/* Johnson Dew */}
                          {userData?.first_name} {userData?.last_name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Box>
                  <Divider />
                  <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 2, pt: 0 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        {advertiserAccess && (
                          <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0, '/advertiser')}
                          >
                            <ListItemIcon>
                              <IconSettings stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Manage Advertiser</Typography>} />
                          </ListItemButton>
                        )}

                        {userAccess && (
                          <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 3}
                            onClick={(event) => handleListItemClick(event, 3, '/user-management')}
                          >
                            <ListItemIcon>
                              <IconUsers stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">User Management</Typography>} />
                          </ListItemButton>
                        )}
                        {storeAccess && (
                          <ListItemButton
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            selected={selectedIndex === 6}
                            onClick={(event) => handleListItemClick(event, 6, '/store')}
                          >
                            <ListItemIcon>
                              <IconBasket stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Store Management</Typography>} />
                          </ListItemButton>
                        )}

                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 5}
                          onClick={(event) => handleListItemClick(event, 5, '/profile')}
                        >
                          <ListItemIcon>
                            <IconUserCircle stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Profile</Typography>} />
                        </ListItemButton>
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
