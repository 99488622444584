import React, { useState } from 'react';
import { Grid, Box, Typography, Button, TextField, IconButton, InputAdornment, LinearProgress } from '@mui/material';
import Warning from 'assets/images/dashna/warning.svg';
import SuccessIcon from '../../assets/images/icons/success.svg';
import colors from 'assets/scss/_themes-vars.module.scss';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAppDispatch } from 'store';
import { registerUser } from 'store/thunk/authThunk';
import CustomLoader from 'ui-component/custom-loader';
import SignUPFlowLeftSide from 'ui-component/sign-up-flow-left-side';
import { Link } from 'react-router-dom';

const SignUpNewDesign = () => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    businessName: '',
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const appDispatch = useAppDispatch();
  const [formErrors, setFormErrors] = useState({});
  const errorBackground = colors?.errorBackground;
  const [passwordStrength, setPasswordStrength] = useState({
    message: '',
    color: '',
    progress: 0,
    isWeak: false
  });
  const [BeError, SetBeError] = useState('');
  const [showNext, setShowNext] = useState(true);

  const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: formErrors?.length > 0 ? colors.errorMain : colors.darkPrimaryMain
      }
    },
    '& .MuiInputLabel-root': {
      '&.Mui-focused': {
        color: formErrors?.length > 0 ? colors.errorMain : colors.darkPrimaryMain
      }
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: value
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [id]: ''
    }));
    SetBeError('');
    if (id === 'password') {
      evaluatePasswordStrength(value);
    }
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    Object.keys(formValues).forEach((key) => {
      if (!formValues[key]) {
        const formattedKey = key.replace(/([A-Z])/g, ' $1').toLowerCase();
        const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
        errors[key] = `${capitalizedKey} is required!`;
      } else if (key === 'email' && !emailRegex.test(formValues[key])) {
        errors[key] = 'Email is not valid!';
      }
    });
    if (formValues?.password && formValues?.password?.length < 8) {
      errors.password = 'Password must be at least 8 characters!';
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      const requestData = {
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        email: formValues.email,
        company_name: formValues.businessName,
        password: formValues.password,
        setLoading: setLoading,
        setShowNext: setShowNext,
        SetBeError: SetBeError
      };
      if (!passwordStrength?.isWeak) {
        appDispatch(registerUser(requestData));
      }
    }
  };

  const evaluatePasswordStrength = (password) => {
    const messages = [];
    const hasLength = password.trim().length >= 8;
    const hasNumbers = /\d/.test(password);
    const hasSymbols = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    if (!hasLength) messages.push('Password must be at least 8 characters long.');
    if (!hasNumbers) messages.push('Password must include at least one number.');
    if (!hasSymbols) messages.push('Password must include at least one special character.');
    if (!hasUppercase) messages.push('Password must include at least one uppercase letter.');
    if (!hasLowercase) messages.push('Password must include at least one lowercase letter.');

    const conditionsMet = [hasLength, hasNumbers, hasSymbols, hasUppercase, hasLowercase].filter(Boolean).length;
    let strength = {
      message: 'Your password is weak.',
      color: colors.errorMain,
      progress: 20,
      isWeak: true,
      validationMessages: messages
    };

    if (conditionsMet >= 4) {
      strength = {
        message: 'Your password is good.',
        color: colors.warningMain,
        progress: 66,
        isWeak: true,
        validationMessages: messages
      };
    }

    if (hasLength && hasNumbers && hasSymbols && hasUppercase && hasLowercase) {
      strength = {
        message: 'Your password is strong. Great job!',
        color: colors.successMain,
        progress: 100,
        isWeak: false,
        validationMessages: []
      };
    }

    setPasswordStrength(strength);
  };

  return (
    <Box>
      <Grid container className="sign-main" columns={12}>
        <Grid sx={{ bgcolor: colors.darkPrimaryMain }} xs={5} className="sign-in-left-side">
          <SignUPFlowLeftSide />
        </Grid>
        <Grid xs={7} className="sign-in-right-side">
          <Box className="right-side-content-wrapper">
            <Box className="sign-in-right-form-container">
              <Typography className="sign-up-title">{`Let's Get Started`}</Typography>
              <Typography className="sign-up-sub-title" mt={1} mb={5}>
                Sign up for your account
              </Typography>
              {showNext ? (
                !loading ? (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          sx={textFieldStyles}
                          id="firstName"
                          variant="outlined"
                          fullWidth
                          autoFocus="true"
                          placeholder="First Name"
                          label="First Name"
                          value={formValues.firstName ? formValues.firstName : ''}
                          onChange={handleInputChange}
                          error={!!formErrors.firstName}
                          helperText={formErrors.firstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          sx={textFieldStyles}
                          id="lastName"
                          variant="outlined"
                          fullWidth
                          placeholder="Last Name"
                          label="Last Name"
                          value={formValues?.lastName ? formValues.lastName : ''}
                          onChange={handleInputChange}
                          error={!!formErrors.lastName}
                          helperText={formErrors.lastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={textFieldStyles}
                          id="businessName"
                          variant="outlined"
                          fullWidth
                          placeholder="Business Name"
                          label="Business Name"
                          value={formValues.businessName ? formValues.businessName : ''}
                          onChange={handleInputChange}
                          error={!!formErrors.businessName}
                          helperText={formErrors.businessName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={textFieldStyles}
                          id="email"
                          variant="outlined"
                          fullWidth
                          placeholder="Email Address"
                          label="Email Address"
                          value={formValues.email ? formValues.email : ''}
                          onChange={handleInputChange}
                          error={!!formErrors.email}
                          helperText={formErrors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          sx={textFieldStyles}
                          id="password"
                          variant="outlined"
                          fullWidth
                          placeholder="Password"
                          label="Password"
                          type={showPassword ? 'text' : 'password'}
                          value={formValues.password ? formValues.password : ''}
                          onChange={handleInputChange}
                          error={!!formErrors.password}
                          helperText={formErrors.password}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                        {formValues?.password?.length > 0 && (
                          <>
                            <LinearProgress
                              variant="determinate"
                              value={passwordStrength.progress}
                              sx={{
                                mt: 1,
                                height: 5,
                                borderRadius: 2,
                                bgcolor: colors.grey300,
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor: passwordStrength.color
                                }
                              }}
                            />
                            <Typography sx={{ mt: 1, color: passwordStrength.color }}>{passwordStrength.message}</Typography>
                            {passwordStrength?.validationMessages?.length > 0 && (
                              <Box mt={1}>
                                {passwordStrength?.validationMessages?.map((msg, index) => (
                                  <Typography key={index} variant="body2" sx={{ mt: 1, color: colors.grey600 }}>
                                    {`• ${msg}`}
                                  </Typography>
                                ))}
                              </Box>
                            )}
                          </>
                        )}
                      </Grid>
                      {BeError?.length > 0 && (
                        <Grid item xs={12} pt={2} pb={1}>
                          <Box
                            display={'flex'}
                            gap={2}
                            alignItems={'center'}
                            p={2}
                            borderRadius={'10px'}
                            backgroundColor={errorBackground}
                          >
                            <Box>
                              <Link to="#">
                                <img src={Warning} alt="Warning" />
                              </Link>
                            </Box>
                            <Box>
                              <Typography>{BeError}</Typography>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Box mt={2}>
                          <Button
                            fullWidth
                            type="submit"
                            className="button-for-sign-up"
                            variant="contained"
                            sx={{ bgcolor: colors.darkPrimaryMain, color: colors.paper, padding: '11px 9px' }}
                          >
                            Sign Up
                          </Button>
                        </Box>
                      </Grid>
                      {/* <div className="loader-custom"></div> */}

                      <Grid item xs={12}>
                        <Box mt={2}>
                          <Typography className="sign-up-sub-title" variant="body2" textAlign={'center'}>
                            Already have an account? <Link to="/login">Sign in</Link>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <CustomLoader />
                )
              ) : (
                <Box>
                  <Box mt={5}>
                    <img src={SuccessIcon} alt="Success icon" />
                  </Box>
                  <Typography className="sign-up-success" mt={2}>
                    Thanks for signing up for Dashna.
                  </Typography>
                  <Typography className="success-note-title" mt={2}>
                    Please check your email for a link to complete your account creation.
                  </Typography>
                </Box>
              )}
            </Box>
            <Box>
              <Typography className="sign-up-sub-title">
                By continuing, you agree to Dashna’s Terms of Service and Privacy Policy.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignUpNewDesign;
