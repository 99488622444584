import { Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { useAppDispatch } from 'store';
import { notificationFail } from 'store/slices/notificationSlice';
import { IconUpload } from '@tabler/icons';

export default function UploadCaptionFile({ setValue, setSelectedCaptionFile }) {
  const dispatch = useAppDispatch();

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles?.length > 0) {
      // Process accepted files
      setValue('caption', URL.createObjectURL(acceptedFiles[0]));
      setSelectedCaptionFile(acceptedFiles[0]);
    }
    if (rejectedFiles?.length > 0) {
      // Handle rejected files (e.g., display an error message)
      dispatch(notificationFail('Caption file format should be .srt or .vtt .'));
    }
  }, []);

  const { getRootProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 522752000,
    accept: {
      text: ['.srt', '.vvt']
    },
    //   accept: ['.srt', '.vtt'], // Only accept .srt and .vtt files
    onDrop,

    onDropRejected: () => {
      dispatch(notificationFail('Caption file format should be .srt or .vtt .'));
    }
  });
  return (
    <>
      <Button variant="contained" {...getRootProps({ className: 'dropzone' })}>
        <IconUpload sx={{ cursor: 'pointer' }} />
        Upload
      </Button>

      {/* <Box sx={{ display: 'flex', mt: 1, alignItems: 'center' }}><Typography>{selectedFile?.name}</Typography></Box> */}
      {/* {errors.video && <FormHelperText error sx={{ margin: "10px 0px" }}>{errors.video.message}</FormHelperText>} */}
    </>
  );
}
