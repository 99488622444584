import React from 'react';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { capitalizeFirstChar, formateValue } from 'helper/commonHelper';
import colors from 'assets/scss/_themes-vars.module.scss';
import { notificationFail, notificationSuccess } from 'store/slices/notificationSlice';
import {
  EditSharedLinkDate,
  EditSharedLinkDescription,
  GetSharedDashboardData,
  GetCreatorData,
  deleteSharedLinkData
} from 'store/thunk/userThunk';
import { useAppSelector, useAppDispatch } from 'store';
import MainCard from 'ui-component/cards/MainCard';
import MenuItemTable from 'ui-component/menuItem/MenuItemTable';
import DeleteModalDialog from 'ui-component/DeleteModal';
import EditProfileModal from 'ui-component/menuItem/EditProfileModal';
import ExpirationDateModal from 'ui-component/menuItem/SetDateModal';
import { NestedDropdown } from 'mui-nested-menu';
import { Box, Typography, IconButton, Tooltip, Button, Badge, Grid } from '@mui/material';
import { DeleteOutline, Link, RemoveRedEyeOutlined, Circle, PlaylistAdd } from '@mui/icons-material';
import CustomModalDescription from 'ui-component/modalDescription';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';
import { PageValue } from 'store/constant';
import CustomTableHead from 'ui-component/customTableHead';
import editIcon from 'assets/images/dashna/edit.svg';
import MultiSelectIcon from 'assets/images/dashna/multiselect.svg';
import arrowDownIcon from 'assets/images/dashna/ArrowDown.svg';
// import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';

const SharedDashboards = () => {
  const navigate = useNavigate();
  const [newData, setNewData] = useState();
  const [appliedSortTing, setAppliedSortTing] = useState();
  const [showDropdown, SetShowDropDown] = useState(false);
  const [selectedArray, setSelectedArray] = useState([]);
  const [selectedObj, setSelectedObj] = useState([]);
  const { SharedLinkData, allUserDetails, creatorData } = useAppSelector((state) => state?.userDetailsSlice);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const dispatch = useAppDispatch();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [existingPopup, setExistingPopup] = useState(false);
  const [existingPopupDate, setExistingPopupDate] = useState(false);
  const [existingPopupDescription, setExistingPopupDescription] = useState(false);
  const [menuEditData, setMenuEditData] = useState(false);
  const [EditDated, setEditedDate] = useState(null);
  const [EditDescription, setEditDescription] = useState([]);
  const [isDropdownOpenCamSt, setIsDropdownOpenCamSt] = useState(false);
  const [isDropdownOpenCamStProfile, setIsDropdownOpenCamStProfile] = useState(false);
  const [isDropdownOpenCamStCreator, setIsDropdownOpenCamStCreator] = useState(false);
  const [filterValue, setFilterValue] = useState({ profile: [], status: [], creator: [] });
  const [tableFilter, setTableFilter] = useState({
    profile: '',
    status: '',
    creator: ''
  });
  const [filterForSearch, setFilterForSearch] = useState({});
  //For Select All Checkbox
  const [isSelectAll, setIsSelectAll] = useState({
    status: [],
    profile: [],
    creator: []
  });
  const [rowPerPage, setRowPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [inlineEdit, setInlineEdit] = useState({ date: false, description: false, delete: false });
  const [selectedRows, setSelectedRows] = useState([]);
  const { userData } = useAppSelector((state) => state.authorization);

  //Permission checking
  const sharedDashboardPermission = userData?.permission?.shared_dashboard || {};
  const { create, update } = sharedDashboardPermission;
  const IsDeleteDashboard = sharedDashboardPermission?.delete;

  const shared_status = [
    { id: 1, name: 'Active', value: 'active' },
    { id: 2, name: 'Expired', value: 'expired' }
  ];
  const [searchText, setSearchText] = useState({ status: null, profile: null, creator: null });

  /* ================================ Autocomplete Filters Start ================================*/

  //Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteOnChange = (newValue, filterKey) => {
    let paramKey = '';
    if (filterKey === 'profile' || filterKey === 'creator') {
      paramKey = 'id';
    } else if (filterKey === 'status') {
      paramKey = 'value';
    }

    const idArray = newValue?.map((item) => item?.[paramKey]);
    const uniqueIdArray = [...new Set(idArray)];
    let idString = uniqueIdArray?.join(',') || '';

    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'status') {
      baseArray = [...shared_status];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
      tableStateFilterKey = 'profile';
    } else if (filterKey === 'creator') {
      baseArray = [...creatorData];
      tableStateFilterKey = 'creator';
    }

    let targetString = '';
    if (filterKey === 'campaign_type') {
      const targetArray = newValue?.map((item) => item?.type);
      targetString = targetArray.filter((item) => item !== undefined).join(',');
    }

    //Check if Select All checkbox is slected
    if (newValue.find((option) => option.all)) {
      const searchTerm = searchText?.[filterKey]?.toLowerCase() || ''; //Change as per selected filter
      const filteredCampaigns = searchTerm
        ? baseArray.filter((item) => item?.name?.toLowerCase().includes(searchTerm))
        : baseArray;
      const allIdsCam = filteredCampaigns.map((obj) => obj.id);

      setIsSelectAll((prevState) => ({
        ...prevState,
        [filterKey]: filteredCampaigns
      }));
      idString =
        newValue.length === 1 || newValue.length <= filteredCampaigns.length
          ? [...new Set(filteredCampaigns.map((item) => item?.[paramKey]))].join(',')
          : '';
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: '' }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue.length - 1 === allIdsCam.length ? [] : filteredCampaigns
      }));
    } else {
      setTableFilter((prevState) => ({
        ...prevState,
        ...(filterKey === 'campaign_type' && { targeting: targetString }),
        [tableStateFilterKey]: idString
      }));
      setFilterValue((prevState) => ({
        ...prevState,
        [tableStateFilterKey]: newValue
      }));
    }
  };

  //For setting Select All checkbox for Filter Campaign, Campaign Status and Campaign Type Dropdown
  const handleAutoCompleteSelectAll = (filterKey) => {
    let baseArray = [];
    let tableStateFilterKey = '';

    if (filterKey === 'status') {
      baseArray = [...shared_status];
      tableStateFilterKey = 'status';
    } else if (filterKey === 'profile') {
      baseArray = [...allUserDetails];
      tableStateFilterKey = 'profile';
    } else if (filterKey === 'creator') {
      baseArray = [...creatorData];
      tableStateFilterKey = 'creator';
    }

    const status =
      filterValue?.[tableStateFilterKey]?.length && isSelectAll?.[filterKey]?.length
        ? !!(filterValue?.[tableStateFilterKey]?.length == baseArray?.length) ||
          !!(filterValue?.[tableStateFilterKey]?.length == isSelectAll?.[filterKey]?.length)
        : false;

    return status;
  };

  /* ================================ Autocomplete Filters End ================================*/

  useEffect(() => {
    if (selectedArray.length >= 1) {
      SetShowDropDown(true);
    } else {
      SetShowDropDown(false);
    }
  }, [selectedArray]);

  // const bulkDelete = (value) => {
  //   if (value == 7) {
  //     setOpenDelete(!openDelete);
  //     setDeleteData(selectedArray);
  //   }
  // };

  const toggleDeleteModal = (data) => {
    const recordId = data.replace(/,/g, ''); //Remove comma from Id
    setOpenDelete(!openDelete);
    setDeleteData(recordId);
    setInlineEdit({ delete: true });
  };

  const downloadExisting = (data) => {
    setMenuEditData(data);
    setExistingPopup(true);
  };

  const ExpirationDataModal = async (data) => {
    await setEditedDate(data);
    setExistingPopupDate(true);
    setInlineEdit({ date: true });
  };

  const DescriptionDataModal = async (data) => {
    await setEditDescription(data);
    setExistingPopupDescription(true);
    setInlineEdit({ description: true });
  };

  const ExpirationDataModalClose = () => {
    setExistingPopupDate(false);
  };

  const confirmDelete = async () => {
    const request = {
      is_bulk: selectedArray.length > 0 && !inlineEdit?.delete ? true : false
    };
    await dispatch(deleteSharedLinkData({ deleteData, request })).then((res) => {
      if (res.payload.status == 204 || res.payload.status == 200) {
        getSharedDashboardLinksWithPagination(
          page,
          rowPerPage,
          appliedSortTing?.changedColumnSort,
          appliedSortTing?.directionSort,
          filterForSearch
        );
      }
      setSelectedArray([]);
      setSelectedObj([]);
      setSelectedRows([]);
      setOpenDelete(!openDelete);
    });
  };

  const getSharedDashboardLinksWithPagination = (
    page,
    rowPerPage,
    changedColumn = '',
    direction = '',
    tableFilter = { profile: '', status: '' }
  ) => {
    {
      dispatch(
        GetSharedDashboardData({
          page,
          rowPerPage,
          changedColumn,
          direction,
          tableFilter
        })
      );
    }
  };

  useEffect(() => {
    getSharedDashboardLinksWithPagination(page, rowPerPage, '', '');
    dispatch(GetCreatorData([]));
  }, []);

  const confirmationToEditedId = () => {
    getSharedDashboardLinksWithPagination(
      page,
      rowPerPage,
      appliedSortTing?.changedColumnSort,
      appliedSortTing?.directionSort,
      filterForSearch
    );
  };

  const columns = [
    {
      name: 'profile_name',
      label: 'Profile',
      options: {
        filter: true,
        sort: false,
        viewColumns: false,
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Profile Name'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = SharedLinkData?.results[rowData?.rowIndex];

          return (
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onMouseEnter={() => setHoveredRow({ profile: data?.id })}
              onMouseLeave={() => setHoveredRow(null)}
            >
              <>
                {value?.length > 40 ? (
                  <Tooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={
                      <Typography variant="body1" sx={{ fontSize: 16 }}>
                        <Typography>{data?.profile_ids?.length > 0 && value ? <Box pr={2}>{value}</Box> : '-'} </Typography>
                      </Typography>
                    }
                    arrow
                  >
                    {value.substring(0, 40)}...{' '}
                    {
                      data?.profile_ids?.length > 1 && (
                        <Badge badgeContent={`+${data?.profile_ids?.length - 1}`}>
                          <Tooltip
                            title={data?.profile_ids?.map((item) => (
                              <Typography key={item.id}>{item.name}</Typography>
                            ))}
                            sx={{ bgcolor: 'white', color: 'black', boxShadow: 1, backgroundColor: 'white !important' }}
                            arrow
                          >
                            {' '}
                            <PlaylistAdd />{' '}
                          </Tooltip>
                        </Badge>
                      )
                      // <Box className="circle-badge">
                      //   {`+${data?.profile_ids?.length - 1}`}
                      // </Box>
                    }
                  </Tooltip>
                ) : (
                  <Typography>
                    {data?.profile_ids?.length > 0 && value ? (
                      <Box pr={1.5}>
                        {value}{' '}
                        {
                          data?.profile_ids?.length > 1 && (
                            <Badge sx={{ marginLeft: '20px' }} color="primary" badgeContent={`${data?.profile_ids?.length - 1}`}>
                              <Tooltip
                                title={
                                  <Box>
                                    {data?.profile_ids?.map((item, index) => (
                                      <Typography key={index}>{item?.name}</Typography>
                                    ))}
                                  </Box>
                                }
                                arrow
                              >
                                {' '}
                                <PlaylistAdd />
                              </Tooltip>
                            </Badge>
                          )
                          // <Box className="circle-badge">
                          //   {' '}
                          //   {`+${data?.profile_ids?.length - 1}`}
                          // </Box>
                        }
                      </Box>
                    ) : (
                      '-'
                    )}{' '}
                  </Typography>
                )}

                {hoveredRow?.profile === data?.id && update ? (
                  <IconButton onClick={() => downloadExisting(data)}>
                    <img src={editIcon} alt="Icon" />
                  </IconButton>
                ) : (
                  <Box sx={{ height: '40px', width: '40px' }}></Box>
                )}
              </>
            </Box>
          );
        },
        setCellProps: () => ({
          className: 'clr3',
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 40,
            minWidth: '380px',
            zIndex: 100
          }
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          },
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 40,
            minWidth: '380px',
            // background: 'white',
            zIndex: 101
          }
        })
      }
    },
    {
      name: 'expiration_date',
      label: 'Expiration Date',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Expiration Date'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = SharedLinkData?.results[rowData?.rowIndex];
          return (
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onMouseEnter={() => setHoveredRow({ stop_over_spend: data?.id })}
              onMouseLeave={() => setHoveredRow(null)}
            >
              <>
                {value?.length > 40 ? (
                  <Tooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={
                      <Typography variant="body1" sx={{ fontSize: 16 }}>
                        {value}
                      </Typography>
                    }
                    arrow
                  >
                    {value.substring(0, 40)}...
                  </Tooltip>
                ) : (
                  value
                )}
                {value == null && update ? (
                  <Button
                    onClick={() => ExpirationDataModal(data)}
                    variant="outlined"
                    color="secondary"
                    sx={{ borderRadius: '12px' }}
                  >
                    {' '}
                    not Set{' '}
                  </Button>
                ) : hoveredRow?.stop_over_spend === data?.id && update ? (
                  <IconButton onClick={() => ExpirationDataModal(data)}>
                    <img src={editIcon} alt="Icon" />
                  </IconButton>
                ) : (
                  <Box sx={{ height: '40px', width: '40px' }}></Box>
                )}
              </>
            </Box>
          );
        }
      }
    },
    {
      name: 'user_name',
      label: 'Creator',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Creator'
          });
        }
      }
    },
    {
      name: 'create_date',
      label: 'Create Time',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Create Time'
          });
        }
      }
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Description'
          });
        },
        customBodyRender: (value, rowData) => {
          const data = SharedLinkData?.results[rowData?.rowIndex];
          return (
            <Box
              sx={{ display: 'flex', alignItems: 'center' }}
              onMouseEnter={() => setHoveredRow({ description: data?.id })}
              onMouseLeave={() => setHoveredRow(null)}
            >
              <>
                {value?.length > 35 ? (
                  <Tooltip
                    placement="top"
                    sx={{ fontSize: '10px' }}
                    title={
                      <Typography variant="body1" sx={{ fontSize: 16 }}>
                        {value}
                      </Typography>
                    }
                    arrow
                  >
                    {value.substring(0, 35)}...
                  </Tooltip>
                ) : (
                  value
                )}
                {(value == null || value == '') && update ? (
                  <Button
                    onClick={() => DescriptionDataModal(data)}
                    variant="outlined"
                    color="secondary"
                    sx={{ borderRadius: '12px' }}
                  >
                    {' '}
                    not Set{' '}
                  </Button>
                ) : hoveredRow?.description === data?.id && update ? (
                  <IconButton onClick={() => DescriptionDataModal(data)}>
                    <img src={editIcon} alt="Icon" />
                  </IconButton>
                ) : (
                  <Box sx={{ height: '40px', width: '40px' }}></Box>
                )}
              </>
            </Box>
          );
        }
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Status'
          });
        },
        customBodyRender: (value) => {
          return (
            <Typography>
              {value != null && value?.length > 0 ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Circle sx={{ fontSize: 'x-small', color: value == 'active' ? '#28c76f' : '#bababb' }} />
                  &nbsp;
                  {capitalizeFirstChar(value)}
                </Box>
              ) : (
                ''
              )}
            </Typography>
          );
        }
      }
    },
    {
      name: 'id',
      label: 'Action',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Action'
          });
        },
        customBodyRender: (value) => {
          return (
            <Box display="flex" alignItems="center" gap={0.5}>
              <Tooltip title="Copy Share Link" arrow>
                <Link sx={{ cursor: 'pointer' }} onClick={() => copySecretKeyToken(value)} />
              </Tooltip>
              <Tooltip title="View Dashboard" arrow>
                <RemoveRedEyeOutlined sx={{ cursor: 'pointer' }} onClick={() => redirectToDashboard(value)} />
              </Tooltip>
              {IsDeleteDashboard && (
                <Tooltip title="Delete" arrow>
                  <DeleteOutline sx={{ color: colors?.errorDark, cursor: 'pointer' }} onClick={() => toggleDeleteModal(value)} />
                </Tooltip>
              )}
            </Box>
          );
        }
      }
    }
  ];

  const formateData = (keyArr) => {
    const formattedData = {};
    keyArr.forEach(([key, value]) => {
      formattedData[key] = formateValue(key, value);
    });

    return formattedData;
  };

  const menuItemsData = {
    label: 'Bulk Edit',
    items: [
      {
        label: 'Edit Expiration Date',
        value: 1,
        callback: async () => {
          await setEditedDate(selectedObj);
          setExistingPopupDate(true);
          setInlineEdit({ date: false });
        }
      },
      {
        label: 'Edit Description',
        value: 7,
        callback: async () => {
          await setEditDescription(selectedObj);
          setExistingPopupDescription(true);
          setInlineEdit({ description: false });
        }
      },
      {
        label: 'Delete',
        value: 7,
        callback: () => {
          setOpenDelete(!openDelete);
          setDeleteData(selectedArray);
          setInlineEdit({ delete: false });
        }
        //  bulkDelete(item.value)
      }
    ]
  };

  useEffect(() => {
    const finalData = SharedLinkData?.results?.map((val) => {
      return {
        ...formateData(Object.entries(val)),
        profile_name: val?.profile_ids[0]?.name
      };
    });
    setNewData(finalData);
  }, [SharedLinkData]);

  const options = {
    search: false,
    filter: false,
    print: false,
    jumpToPage: false,
    responsive: 'standard',
    fixedHeader: true,
    filterType: 'checkbox',
    fixedSelectColumn: true,
    selectToolbarPlacement: 'none',
    tableBodyHeight: '550px',
    download: false,
    serverSide: true,
    page: page,
    count: SharedLinkData?.count || 0,
    viewColumns: false,
    selectableRowsHeader: true,
    selectableRowsHideCheckboxes: false,
    rowsPerPageOptions: PageValue,
    rowsPerPage: rowPerPage,
    pagination: true,
    rowsSelected: selectedRows,
    draggableColumns: {
      enabled: true
    },

    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      const selectedIds = rowsSelected.map((selectedIndex) => SharedLinkData?.results?.[selectedIndex]?.id);
      const selectedObj = rowsSelected.map((selectedIndex) => SharedLinkData?.results?.[selectedIndex]);
      setSelectedObj(selectedObj);
      setSelectedArray(selectedIds);
      setSelectedRows(allRows.map((row) => row.dataIndex));
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      SetShowDropDown(false);
      getSharedDashboardLinksWithPagination(
        0,
        row,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        filterForSearch
      );
    },
    onChangePage: (page) => {
      setPage(page);
      SetShowDropDown(false);
      getSharedDashboardLinksWithPagination(
        page,
        rowPerPage,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        filterForSearch
      );
    },
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      console.log('New Column Order: ', newColumnOrder);
      console.log('columnIndex ', columnIndex);
      console.log('newPosition ', newPosition);
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      SetShowDropDown(false);
      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      getSharedDashboardLinksWithPagination(page, rowPerPage, changedColumn, direction, filterForSearch);
    }
  };

  const handleSelectedDate = (value) => {
    dispatch(EditSharedLinkDate(value)).then((res) => {
      if (res?.payload?.status == 201 || res?.payload?.status == 200) {
        getSharedDashboardLinksWithPagination(
          page,
          rowPerPage,
          appliedSortTing?.changedColumnSort,
          appliedSortTing?.directionSort,
          filterForSearch
        );
      }
      setSelectedArray([]);
      setSelectedObj([]);
      setSelectedRows([]);
      // setOpenDelete(!openDelete);
      setExistingPopupDate(false);
    });
  };

  const handleClose = () => {
    setExistingPopupDescription(false);
  };
  const handleSave = (value) => {
    dispatch(EditSharedLinkDescription(value)).then((res) => {
      if (res?.payload?.status == 201 || res?.payload?.status == 200) {
        getSharedDashboardLinksWithPagination(
          page,
          rowPerPage,
          appliedSortTing?.changedColumnSort,
          appliedSortTing?.directionSort,
          filterForSearch
        );
      }
      setSelectedArray([]);
      setSelectedRows([]);
      setSelectedObj([]);
      // setOpenDelete(!openDelete);
      setExistingPopupDate(false);
    });
    setExistingPopupDescription(false);
  };

  const handleSearchClick = () => {
    setFilterForSearch({ ...tableFilter });
    getSharedDashboardLinksWithPagination(
      0,
      rowPerPage,
      appliedSortTing?.changedColumnSort,
      appliedSortTing?.directionSort,
      tableFilter
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        dispatch(notificationSuccess('Link copied to clipboard'));
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
        dispatch(notificationFail('Error in copying link to clipboard'));
      });
  };

  const getSharedDashboardById = (id) => {
    const dashboardData = newData?.find((l) => l?.id === id);
    return dashboardData;
  };

  const copySecretKeyToken = (id) => {
    const dashboardData = getSharedDashboardById(id);
    const secretKey = dashboardData?.secret_Key;
    const baseURL = window.location.origin;
    const link = `${baseURL}/dashboard-overview/${secretKey}`;
    copyToClipboard(link);
  };

  const redirectToDashboard = (id) => {
    const dashboardData = getSharedDashboardById(id);
    const secretKey = dashboardData?.secret_Key;
    const link = `/dashboard-overview/${secretKey}`;
    window.open(link, '_blank');
  };

  return (
    <MainCard title="Shared Dashboards">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignContent: 'space-between' }}>
        <Box sx={{ display: 'flex' }}>
          {showDropdown && update && (
            <Box className="Custom-bulk-name" sx={{ mr: 2 }}>
              <NestedDropdown
                className="Custom-nested-dropdown"
                menuItemsData={menuItemsData}
                MenuProps={{ elevation: 1, className: 'Custom-menu-bulk' }}
                ButtonProps={{
                  variant: 'contained',
                  className: 'Custom-dropDown-bulk',
                  startIcon: <img src={MultiSelectIcon} alt="multiselect icon" style={{ width: '24px', height: '24px' }} />, // Adding the start icon here
                  endIcon: <img src={arrowDownIcon} alt="multiselect icon" style={{ width: '24px', height: '24px' }} /> // Remove any end icon including a dropdown arrow
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ display: 'flex' }}>
          {create && (
            <Button
              variant="contained"
              onClick={() => {
                navigate('/shared-dashboards/create-link');
              }}
            >
              Create Share Link
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid container sx={{ py: 3 }} spacing={2}>
          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="tags-outlined"
              name="adgroup_name"
              color="secondary"
              label="Status"
              options={shared_status || []}
              value={filterValue?.status || []}
              onOpen={() => setIsDropdownOpenCamSt(true)}
              onClose={() => setIsDropdownOpenCamSt(false)}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'status');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamSt ? searchText?.status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, status: value });
                }
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('status')}
              placeholderCondition={filterValue?.status?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="tags-outlined"
              name="adgroup_name"
              color="secondary"
              label="Profile"
              options={allUserDetails || []}
              value={filterValue?.profile || []}
              onOpen={() => setIsDropdownOpenCamStProfile(true)}
              onClose={() => setIsDropdownOpenCamStProfile(false)}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'profile');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamStProfile ? searchText?.profile : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, profile: value });
                }
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('profile')}
              placeholderCondition={filterValue?.profile?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <CustomAutocomplete
              multiple
              id="tags-outlined"
              name="adgroup_name"
              color="secondary"
              label="Creator"
              options={creatorData || []}
              value={filterValue?.creator || []}
              onOpen={() => setIsDropdownOpenCamStCreator(true)}
              onClose={() => setIsDropdownOpenCamStCreator(false)}
              onChange={(e, value) => {
                handleAutoCompleteOnChange(value, 'creator');
              }}
              optionLabelkey="name"
              optionIdkey="id"
              inputValue={isDropdownOpenCamStCreator ? searchText?.creator : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, creator: value });
                }
              }}
              selectAllCheckboxCondiion={handleAutoCompleteSelectAll('creator')}
              placeholderCondition={filterValue?.creator?.length > 0}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              limitTags={2}
            />
          </Grid>
        </Grid>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" color="secondary" onClick={handleSearchClick}>
            <SearchIcon sx={{ mr: 0.5 }} />
            Search
          </Button>
        </Box>
      </Box>

      <MenuItemTable className="custom-budget-table" data={newData} columns={columns} options={options} />
      <DeleteModalDialog
        item="Profile"
        cancelCallback={() => setOpenDelete(!openDelete)}
        deleteCallback={confirmDelete}
        open={openDelete}
      />
      {existingPopupDate && (
        <ExpirationDateModal
          handleSelectedDate={handleSelectedDate}
          open={existingPopupDate}
          data={EditDated}
          onClose={ExpirationDataModalClose}
          is_Bulk={showDropdown}
          is_inline={!inlineEdit?.date}
        />
      )}
      {existingPopup && (
        <EditProfileModal
          open={existingPopup}
          menuEditData={allUserDetails}
          onClose={() => setExistingPopup(false)}
          advertiserData={[]}
          OnConfirm={confirmationToEditedId}
          allUserDetails={menuEditData}
        />
      )}
      {existingPopupDescription && (
        <CustomModalDescription
          open={existingPopupDescription}
          handleClose={handleClose}
          handleSave={handleSave}
          title="Edit Description"
          description={EditDescription}
          is_Bulk={selectedArray?.length > 0 ? true : false}
          is_inline={!inlineEdit?.description}
        />
      )}
    </MainCard>
  );
};

export default SharedDashboards;
