import React, { useState } from 'react';
import { IconButton, Button, Typography, Box, Grid, TextField } from '@mui/material';
import editIcon from 'assets/images/dashna/edit.svg';
import MUIDataTable from 'mui-datatables';
import EditModal from './editModal';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { deleteUser, getUsermanagementList, postUserData, updateUserData } from 'store/thunk/userThunk';
import { useAppDispatch } from 'store';
import { useAppSelector } from 'store';
import DeleteModalDialog from 'ui-component/DeleteModal';
import { getUserType } from 'helper/commonHelper';
import { Loader } from 'rsuite';
import { PageValue, UserStatusList, userRolesForFilter } from 'store/constant';
import SearchIcon from '@mui/icons-material/Search';
import CustomAutocomplete from 'ui-component/multi-select/CustomAutocomplete';
import { DeleteOutline } from '@mui/icons-material';
import colors from 'assets/scss/_themes-vars.module.scss';
import CustomTableHead from 'ui-component/customTableHead';

const UserManagement = () => {
  const { userData } = useAppSelector((state) => state.authorization);
  const [appliedSortTing, setAppliedSortTing] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [deleteData, setDeleteData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [IsLoadingDelete, setIsLoadingDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [filterValue, setFilterValue] = useState({ status: [], user_type: [], email: [] });
  const [tableFilter, setTableFilter] = useState({ status: '', user_type: '', email: '' });
  const [filterForSearch, setFilterForSearch] = useState({});
  const { userManagementDetails } = useAppSelector((state) => state?.userDetailsSlice);
  const [searchText, setSearchText] = useState({ status: null, user_type: null });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenUserType, setIsDropdownOpenUserType] = useState(false);

  const dispatch = useAppDispatch();

  const tooltipUserType = `
  <div>
    Owner - Highest level of access. Can manage all user.<br/>
    Admin - Can manage all users, except the owner.<br/>
    User - Can view and edit data.<br/>
    Viewer - Can only view data.<br/>
  </div>
`;

  useEffect(() => {
    setLoading(true);
    getUserListWithPagination(page, rowPerPage);
  }, []);

  const getUserListWithPagination = (page, rowPerPage, changedColumn, direction, tableFilter) => {
    dispatch(getUsermanagementList({ page, rowPerPage, changedColumn, direction, tableFilter })).then(() => {
      setLoading(false);
    });
  };

  //While Add or Update User
  const conformAddOrUpdateUser = (data) => {
    let _request = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      user_type: Number(data?.user_type),
      advertiser_access: data?.advertiser_access
    };

    setIsLoading(true);
    //Edit User
    if (data?.id) {
      const user_id = data?.id;
      _request = { ..._request, is_active: data?.is_active };
      dispatch(updateUserData({ _request, user_id })).then((res) => {
        setIsLoading(false);
        if (res?.payload?.status == 200) {
          setOpenEdit(false);
          getUserListWithPagination(
            page,
            rowPerPage,
            appliedSortTing?.changedColumnSort,
            appliedSortTing?.directionSort,
            filterForSearch
          );
        }
      });
    } else {
      //Add User
      dispatch(postUserData({ _request })).then((res) => {
        setIsLoading(false);
        if (res?.payload?.status == 201) {
          setOpenEdit(false);
          getUserListWithPagination(
            page,
            rowPerPage,
            appliedSortTing?.changedColumnSort,
            appliedSortTing?.directionSort,
            filterForSearch
          );
        }
      });
    }
  };

  //Conform Delete Confirmation
  const conformDeleteUser = () => {
    setIsLoadingDelete(true);
    let user_id = deleteData?.user_id;
    dispatch(deleteUser({ user_id })).then(() => {
      setOpenDelete(false);
      getUserListWithPagination(
        page,
        rowPerPage,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        filterForSearch
      );
      setIsLoadingDelete(false);
    });
  };

  //Toggle Delete User modal
  const toggleDeleteModal = (data) => {
    if (data) {
      const getUserInfo = userManagementDetails?.results?.find((l) => l?.id === data);
      const deleteUserName = `user ( ${getUserInfo?.first_name} ${getUserInfo?.last_name} ) `;
      const deleteUserInfo = {
        name: deleteUserName,
        user_id: data
      };
      setDeleteData(deleteUserInfo);
    } else {
      setDeleteData({});
    }
    setOpenDelete(!openDelete);
  };

  //Toggle Edit User modal
  const toggleEditModal = (id = '') => {
    setOpenEdit(!openEdit);
    if (id) {
      const data = userManagementDetails?.results?.find((item) => item?.id == id);
      setEditData(data);
    } else {
      setEditData({});
    }
  };

  const columns = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Email'
          });
        }
      }
    },
    {
      name: 'first_name',
      label: 'First Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'First Name'
          });
        },
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },
    {
      name: 'last_name',
      label: 'Last Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Last Name'
          });
        },
        customBodyRender: (value) => <Typography>{value ? value : '--'}</Typography>
      }
    },

    {
      name: 'user_type',
      label: 'User Type',
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'User Type',
            isLabelTooltip: true,
            tooltipInfo: tooltipUserType
          });
        },
        customBodyRender: (value) => {
          return <Typography>{getUserType(value)}</Typography>;
        }
      }
    },
    {
      name: 'is_active',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Status'
          });
        },
        customBodyRender: (value) => <Typography>{value ? 'Active' : 'Inactive'}</Typography>
      }
    },
    {
      name: 'added_by',
      label: 'Added By',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Added By'
          });
        },
        customBodyRender: (value) => {
          return <Typography>{value?.name}</Typography>;
        }
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          className: 'clr3'
        }),
        setCellHeaderProps: () => ({
          sx: {
            '& button': { padding: '8px !important' },
            '& button:hover': { backgroundColor: 'transparent' }
          }
        }),
        customHeadLabelRender: () => {
          return CustomTableHead({
            columnLabel_1: 'Actions'
          });
        },
        customBodyRender: (value) => {
          const userUpdate = userData?.permission?.user?.update; //Is update permission
          const userDelete = userData?.permission?.user?.delete; //Is delete permission
          const userType = userData?.user_type; // 3 = Admin (Logged In User Type)
          const userInfo = userManagementDetails?.results?.find((item) => item?.id == value);
          const userTypeOfRespectiveUser = userInfo?.user_type; // 2 = Owner ( Respective User Type )
          //Admin can not edit or delete Owner
          if (!(userType === 3 && userTypeOfRespectiveUser === 2)) {
            return (
              <>
                {userUpdate && (
                  <Tooltip title="Edit User">
                    <IconButton onClick={() => toggleEditModal(value)}>
                      <img src={editIcon} alt="Icon" />
                    </IconButton>
                  </Tooltip>
                )}
                {userDelete && (
                  <Tooltip title="Delete User">
                    <IconButton onClick={() => toggleDeleteModal(value)}>
                      <DeleteOutline sx={{ color: colors?.errorDark }} />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            );
          }
        }
      }
    }
  ];

  const options = {
    filter: false,
    print: false,
    selectableRows: 'none',
    page: page,
    jumpToPage: false,
    count: userManagementDetails?.count || 0,
    download: false,
    pagination: true,
    serverSide: true,
    search: false,
    rowsPerPageOptions: PageValue,
    rowsPerPage: rowPerPage,
    draggableColumns: {
      enabled: true
    },
    onChangeRowsPerPage: (row) => {
      setRowPerPage(row);
      getUserListWithPagination(0, row, appliedSortTing?.changedColumnSort, appliedSortTing?.directionSort, filterForSearch);
    },
    onChangePage: (page) => {
      setPage(page);
      getUserListWithPagination(
        page,
        rowPerPage,
        appliedSortTing?.changedColumnSort,
        appliedSortTing?.directionSort,
        filterForSearch
      );
    },
    onColumnSortChange: (changedColumn, direction) => {
      setPage(0);
      const payload = {
        changedColumnSort: changedColumn,
        directionSort: direction
      };
      setAppliedSortTing(payload);
      getUserListWithPagination(0, rowPerPage, changedColumn, direction, filterForSearch);
    },
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      console.log('New Column Order: ', newColumnOrder);
      console.log('columnIndex ', columnIndex);
      console.log('newPosition ', newPosition);
    },
    customToolbar: () => (
      <>
        <Button onClick={() => toggleEditModal()} variant="contained" disabled={false}>
          Add User
        </Button>
      </>
    ),
    textLabels: {
      body: {
        noMatch: loading ? <Loader /> : 'Sorry, there is no matching data to display'
      }
    }
  };
  const handleSearchFilter = () => {
    setFilterForSearch({ ...tableFilter });
    getUserListWithPagination(0, rowPerPage, appliedSortTing?.changedColumnSort, appliedSortTing?.directionSort, tableFilter);
  };

  return (
    <MainCard title="User List">
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Grid container sx={{ p: 3, pl: 0 }} spacing={3}>
          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="Status"
              label="Status"
              className="custom-auto"
              optionLabelkey="name"
              options={UserStatusList || []}
              value={filterValue?.status || []}
              onOpen={() => setIsDropdownOpen(true)}
              onClose={() => setIsDropdownOpen(false)}
              inputValue={isDropdownOpen ? searchText?.status : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, status: value });
                }
              }}
              onChange={(e, newValue) => {
                const idArray = newValue?.map((item) => item.id);
                const uniqueIdArray = [...new Set(idArray)];
                const idString = uniqueIdArray?.join(',');
                if (newValue.find((option) => option.all)) {
                  const allIdsCam = UserStatusList?.map((obj) => obj.id);
                  setTableFilter({ ...tableFilter, status: '' });
                  return setFilterValue({
                    ...filterValue,
                    status: newValue.length - 1 === allIdsCam.length ? [] : UserStatusList
                  });
                } else {
                  setTableFilter({ ...tableFilter, status: idString });
                  setFilterValue({ ...filterValue, status: newValue });
                }
              }}
              selectAllCheckboxCondiion={!!(filterValue?.status?.length == UserStatusList.length)}
              placeholderCondition={filterValue?.status?.length > 0}
            />
          </Grid>

          <Grid item xs={12} md={3.5}>
            <CustomAutocomplete
              multiple
              id="checkboxes-tags-demo"
              name="UserType"
              label="User Type"
              className="custom-auto"
              optionLabelkey="name"
              options={userRolesForFilter || []}
              value={filterValue?.user_type || []}
              onOpen={() => setIsDropdownOpenUserType(true)}
              onClose={() => setIsDropdownOpenUserType(false)}
              inputValue={isDropdownOpenUserType ? searchText?.user_type : ''}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, user_type: value });
                }
              }}
              onChange={(e, newValue) => {
                const idArray = newValue?.map((item) => item.value);
                const uniqueIdArray = [...new Set(idArray)];
                const idString = uniqueIdArray?.join(',');
                if (newValue.find((option) => option.all)) {
                  const allIdsCam = userRolesForFilter?.map((obj) => obj.id);
                  setTableFilter({ ...tableFilter, user_type: '' });
                  return setFilterValue({
                    ...filterValue,
                    user_type: newValue.length - 1 === allIdsCam.length ? [] : userRolesForFilter
                  });
                } else {
                  setTableFilter({ ...tableFilter, user_type: idString });
                  setFilterValue({ ...filterValue, user_type: newValue });
                }
              }}
              selectAllCheckboxCondiion={!!(filterValue?.user_type?.length == userRolesForFilter?.length)}
              placeholderCondition={filterValue?.user_type?.length > 0}
            />
          </Grid>

          <Grid item xs={12} md={3.5}>
            <TextField
              sx={{ width: '-webkit-fill-available' }}
              label="Email"
              variant="outlined"
              id="checkboxes-tags-demo"
              name="Report Type"
              color="secondary"
              // label="Report Type"
              className="custom-auto"
              optionLabelkey="name"
              value={filterValue?.email || []}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setSearchText({ ...searchText, email: value });
                }
              }}
              onChange={(e) => {
                setTableFilter({ ...tableFilter, email: e.target?.value });
                setFilterValue({ ...filterValue, email: e.target?.value });
              }}
            />
          </Grid>
        </Grid>
        <Box>
          <Button variant="contained" onClick={handleSearchFilter} color="secondary">
            <SearchIcon sx={{ mr: 0.5 }} />
            Search
          </Button>
        </Box>
      </Box>
      <MUIDataTable
        actions={[
          {
            icon: 'save'
          }
        ]}
        data={userManagementDetails?.results}
        columns={columns}
        options={options}
        className="custom-budget-table-no-checkbox pnl-by-item"
      />
      {openEdit && (
        <EditModal
          open={openEdit}
          isLoading={isLoading}
          handleClose={() => toggleEditModal()}
          updateSeller={conformAddOrUpdateUser}
          sellerData={editData}
        />
      )}

      {openDelete && (
        <DeleteModalDialog
          item={deleteData?.name}
          cancelCallback={() => toggleDeleteModal('')}
          deleteCallback={conformDeleteUser}
          open={openDelete}
          IsLoadingDelete={IsLoadingDelete}
        />
      )}
    </MainCard>
  );
};

export default UserManagement;
